import { Link, useNavigate } from "react-router-dom";
import { Form, Button, Spinner } from "react-bootstrap";
import "./auth.css";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginSchema } from "../../utils/validationSchema";
import { useContext, useState } from "react";
import UserContext from "../../context/UserContext";
import axios from "axios";
import { serverPath } from "../../config/key";
import { toast } from "react-toastify";
import hideicon from '../../assets/images/hide-icon.svg'
import showicon from '../../assets/images/show-icon.svg'
const SignIn = () => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm({ resolver: yupResolver(LoginSchema) })
  const navigate=useNavigate()
  const { setUser, setToken } = useContext(UserContext)
  const [showPassword,setShowPassword]=useState(false)
  const [loading,setLoading]=useState(false)
  const [logInData, setLogInData] = useState({
    email: '',
    password: '',
    rememberMe: false,
  })

const handleShowPassword=()=>{
  setShowPassword(!showPassword)
}

  const handleChange = (e) => {
    const { name, value, checked } = e.target;
    if (name === "rememberMe") {
      setLogInData({
        ...logInData,
        rememberMe: checked,
      });
    } else
      setLogInData({
        ...logInData,
        [name]: value,
      });
  };

  const logInUser = async (values) => {
    try {
      setLoading(true)
      const response = await axios.post(`${serverPath}/users/login`, {...values,rememberMe:true})
      setLoading(false)
      const { status, token, user } = response.data
      if (status === 200) {
        toast.success("Login Successful", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        localStorage.setItem("token", token);
        localStorage.setItem("user", JSON.stringify(user));
        setUser(user);
        setToken(token);
        if (user.role === "client") navigate('/welcome');
        else if (user.role === "admin") navigate('/admin');
      }
    } catch (error) {
      setLoading(false)
      if (error?.response?.data) {
        if (error.response.data.status === 401) {
          navigate("/verifyUser");
          localStorage.setItem("phone", error.response.data.phone);
        }
        
        toast.error(error.response.data.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    }
  };
  return (
    <div className="auth-form">
      <Form onSubmit={handleSubmit(logInUser)}>
      <Form.Group className="auth-form-group">
                  <Form.Control
                    className={`${errors?.email ? `error-border` : ``}`}
                    type="text"
                    name="email"
                    placeholder="Email Address"
                    onChange={handleChange}
                    {...register("email")}
                    defaultValue={logInData.email}
                  />
                  {errors?.email ? (
                    <span className="error-msg">{errors.email.message}</span>
                  ) : (
                    ""
                  )}
                </Form.Group>

                <Form.Group className="auth-form-group">
                  <div className="pass-show">
                    <span className="pass-icon" onClick={handleShowPassword}>
                      {showPassword ? (
                        <img src={hideicon} alt="" />
                      ) : (
                        <img src={showicon} alt="" />
                      )}
                    </span>
                    <Form.Control
                      className={`${errors?.password ? `error-border` : ``}`}
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      name="password"
                      onChange={handleChange}
                      defaultValue={logInData.password}
                      {...register("password")}
                    />
                  </div>

                  {errors?.password ? (
                    <span className="error-msg">{errors.password.message}</span>
                  ) : (
                    ""
                  )}
                </Form.Group>
        <div className="forgot-text">
          <Link to="/forgotPassword">Forgot Password</Link>
        </div>
        <div className="auth-form-btn">
          <Button type="submit"> {loading&& <Spinner
                  as="span"
                  variant="light"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  animation="border"
                />}&nbsp;Submit</Button>
        </div>
      </Form>
    </div>
  );
};

export default SignIn;
