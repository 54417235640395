/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from "react";
import { Tab, Tabs } from "react-bootstrap";
import PersonalInfo from "./personalInfo";
import "../visaForm/index.css";
import EducationInfo from "./educationInfo";
import WorkExperience from "./workExperience";
import LanguagesSection from "./languageSection";
import Documents from "./document";
import UserContext from "../../context/UserContext";
import { serverPath } from "../../config/key";
import axios from "axios";
import lock from "../../assets/images/visa/lock.svg";
import Loader from "../loader/loader";
import ApplicationLayout from "../myapplication/layout";
import { useNavigate } from "react-router-dom";

const EvaluationVisaForm = () => {
  const navigate = useNavigate();
  const { selectedVisa, setSavePercentage } = useContext(UserContext);
  const [personalInformation, setPersonalInfo] = useState();
  const [educationInfo, setEducationInfo] = useState();
  const [experienceInfo, setExperienceInfo] = useState();
  const [LanguageInfo, setLanguageInfo] = useState();
  const [documentInfo, setDocumentInfo] = useState();
  const [saveCount, setSaveCount] = useState(0);
  const [visaFormData, setVisaFormData] = useState();
  const [activeTabKey, setActiveTabKey] = useState("personalInfo");
  const [loading, setLoading] = useState(false);
  const [activeEditable, setActiveEditable] = useState("");

  const checkProVisaType = async () => {
    setPersonalInfo();
    setExperienceInfo();
    setVisaFormData();
    setSaveCount(0);
    setSavePercentage(0);
    setLoading(true);
    if (
      selectedVisa?.productId &&
      selectedVisa?.invoiceId &&
      selectedVisa?.isEvaluation
    ) {
      const res = await axios.get(
        `${serverPath}/evaluation/checkProductEvaluation`,
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
          params: selectedVisa,
        }
      );
      setLoading(false);
      setVisaFormData(res?.data?.visaData);
    } else {
      navigate("/application/main");
    }
  };
  useEffect(() => {
    checkProVisaType();
  }, [selectedVisa]);

  useEffect(() => {
    if (visaFormData) {
      let count = [];
      if (visaFormData?.education?.isLocked) {
        count.push(2);
      }
      let education =
        visaFormData.education &&
        Object.keys(visaFormData?.education)?.length > 0
          ? visaFormData?.education
          : {};
      setEducationInfo(education);
      if (visaFormData?.personal?.isLocked) {
        count.push(1);
      }
      let personal =
        visaFormData.personal && Object.keys(visaFormData?.personal)?.length > 0
          ? visaFormData?.personal
          : {};
      setPersonalInfo(personal);
      if (visaFormData?.Experience?.isLocked) {
        count.push(3);
      }
      let experience =
        visaFormData?.Experience &&
        Object.keys(visaFormData?.Experience)?.length > 0
          ? visaFormData?.Experience
          : {};
      setExperienceInfo(experience);
      if (visaFormData?.language?.isLocked) {
        count.push(4);
      }
      let language =
        visaFormData?.language &&
        Object.keys(visaFormData?.language)?.length > 0
          ? visaFormData?.language
          : {};
      setLanguageInfo(language);
      if (visaFormData?.document?.isLocked) {
        count.push(5);
      }
      setDocumentInfo(visaFormData?.document);
      setSaveCount(count?.length);
      setActiveTabKey(activeTabKey);
    }
  }, [visaFormData]);
  useEffect(() => {
    if (saveCount !== 0) {
      const per = (Number(saveCount) / 5) * 100;
      setSavePercentage(per);
    }
  }, [
    selectedVisa,
    personalInformation,
    educationInfo,
    experienceInfo,
    LanguageInfo,
    documentInfo,
    saveCount,
  ]);
  const handleFormDisable = (tab) => {
    if (tab !== activeEditable) {
      setActiveEditable(tab);
    } else {
      setActiveEditable("");
    }
  };
  const handleTabChange = (e) => {
    if (e !== activeTabKey && !activeEditable) {
      setActiveTabKey(e);
    } else if (activeEditable && e !== activeEditable) {
    }
  };
  return (
    <ApplicationLayout>
      <div className="support-visa-form">
        {/* {aknowledgeState && (
          <AknowledgePopup
            setAcknowledgeState={setAcknowledgeState}
            activeTabKey={activeTabKey}
            setActiveEditable={setActiveEditable}
            editableTab={activeTabKey}
            texts={texts}
            handleUnlockFormForClient={handleUnlockFormForClient}
          />
        )} */}
        {!selectedVisa?.invoiceId && !selectedVisa?.visaName ? (
          <div className="select-visatype">
            <h2>Please Select visa type</h2>
          </div>
        ) : (
          <div className="visa-form-page">
            <div className="container">
              {loading ? (
                <Loader />
              ) : (
                <div className="home-visa-tab">
                  <Tabs
                    className=""
                    defaultActiveKey={"personalInfo"}
                    activeKey={activeTabKey}
                    onSelect={handleTabChange}
                  >
                    <Tab
                      eventKey="personalInfo"
                      title={
                        <span>
                          {"Personal Info"}
                          {personalInformation?.isLocked && (
                            <img
                              src={lock}
                              alt="lock"
                              onClick={() => handleFormDisable("personalInfo")}
                              role="presentation"
                            />
                          )}
                        </span>
                      }
                    >
                      <PersonalInfo
                        personalInformation={personalInformation}
                        setPersonalInfo={setPersonalInfo}
                        adminFormDisable={activeEditable !== "personalInfo"}
                        activeTabKey={activeTabKey}
                      />
                    </Tab>
                    <Tab
                      eventKey="Education"
                      title={
                        <span>
                          {"Education"}
                          {educationInfo && educationInfo?.isLocked && (
                            <img
                              src={lock}
                              alt="lock"
                              onClick={() => handleFormDisable("Education")}
                              role="presentation"
                            />
                          )}
                        </span>
                      }
                      className={educationInfo?.isLocked ? "test" : ""}
                    >
                      <EducationInfo
                        educationInfo={educationInfo}
                        setEducationInfo={setEducationInfo}
                        adminFormDisable={activeEditable !== "Education"}
                      />
                    </Tab>
                    <Tab
                      eventKey="Work Experience"
                      title={
                        <span>
                          {"Work Experience"}
                          {experienceInfo && experienceInfo?.isLocked && (
                            <img
                              src={lock}
                              alt="lock"
                              onClick={() =>
                                handleFormDisable("Work Experience")
                              }
                              role="presentation"
                            />
                          )}
                        </span>
                      }
                    >
                      <WorkExperience
                        experienceInfo={experienceInfo}
                        setExperienceInfo={setExperienceInfo}
                        adminFormDisable={activeEditable !== "Work Experience"}
                      />
                    </Tab>
                    <Tab
                      eventKey="Languages"
                      title={
                        <span>
                          {"Language"}
                          {LanguageInfo && LanguageInfo?.isLocked && (
                            <img
                              src={lock}
                              alt="lock"
                              onClick={() => handleFormDisable("Languages")}
                              role="presentation"
                            />
                          )}
                        </span>
                      }
                    >
                      <LanguagesSection
                        LanguageInfo={LanguageInfo}
                        setLanguageInfo={setLanguageInfo}
                        adminFormDisable={activeEditable !== "Languages"}
                      />
                    </Tab>

                    <Tab
                      eventKey="Documents"
                      title={
                        <span>
                          {"Documents"}
                          {documentInfo && documentInfo?.isLocked && (
                            <img
                              src={lock}
                              alt="lock"
                              onClick={() => handleFormDisable("Documents")}
                              role="presentation"
                            />
                          )}
                        </span>
                      }
                    >
                      <Documents
                        documentInfo={documentInfo}
                        setDocumentInfo={setDocumentInfo}
                        showButton={true}
                        adminFormDisable={activeEditable !== "Documents"}
                      />
                    </Tab>
                  </Tabs>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </ApplicationLayout>
  );
};
export default EvaluationVisaForm;
