import React, { useState, useContext, useEffect } from "react";

import caseicon from "../../assets/images/application/case.svg";
import { Link } from "react-router-dom";
import UserContext from "../../context/UserContext";
import bg from "../../assets/images/application/application-bg.svg";
import { CircleProgress } from "react-gradient-progress";
import SubMenu from "./submenu";

const ApplicationHeader = ({ handleSideBar }) => {
  const { user, savePercentage, selectedVisa } = useContext(UserContext);
  const [activeLink, setActiveLink] = useState();
  const [showsidebar, setShowSidebar] = useState(false);
  useEffect(() => {
    setActiveLink(window?.location?.pathname);
  }, []);

  const handleSidebarMob = () => {
    setShowSidebar(!showsidebar);
  };
  return (
    <div className={`application-header`}>
      <div className="container">
        <div className="application-header-grid">
          <div className="application-menu">
            <div className="top-header-section">
              <div className="application-case">
                <div className="application-case-icon">
                  <img src={caseicon} alt="" /> case:{" "}
                  {selectedVisa?.caseId ? selectedVisa?.caseId : ""} -{" "}
                  {user?.name}
                </div>
              </div>
              <div className="bottom-content">
                <h6>
                  Welcome, <span>{user?.name}</span>!
                </h6>
                <p>
                  Your Progress... Legal department analyzes your profile...
                </p>
              </div>
            </div>
            <div className="progress-bar-section">
              <CircleProgress
                percentage={parseInt(savePercentage)}
                strokeWidth={22}
                secondaryColor={"#F1F4FA"}
                primaryColor={["#CED2E0", "#CED2E0"]}
                width={207}
                fontSize={22}
                fontColor={"#252D43"}
                strokeLinecap={"round"}
              />
            </div>
            <div className="bg-image">
              <img src={bg} alt="bg" />
            </div>
          </div>
          <div className="application-action">
            <span
              className={`desktop-icon ${
                activeLink === "/notifications" ? "active-link" : ""
              }`}
            >
              <Link to="/notifications">
                <p>0</p>
                <BellIcon />
              </Link>
            </span>
            <span
              className={`desktop-icon ${
                activeLink === "/settings" ? "active-link" : ""
              }`}
            >
              <Link to="/settings">
                <SettingsIcon />
              </Link>
            </span>
            <div className="mob-icon">
              <i class="las la-bars" onClick={handleSidebarMob}></i>
            </div>
          </div>
        </div>
      </div>
      <SubMenu show={showsidebar} handleClose={handleSidebarMob} />
    </div>
  );
};

export default ApplicationHeader;

const BellIcon = () => {
  return (
    <svg
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.43928 0.856934C3.91765 0.856934 1.06279 3.7118 1.06279 7.23346V11.0443L0.311302 11.7958C0.00735469 12.0997 -0.083564 12.5568 0.0809292 12.9539C0.245422 13.3511 0.632945 13.61 1.06279 13.61H13.8158C14.2457 13.61 14.6332 13.3511 14.7977 12.9539C14.9622 12.5568 14.8712 12.0997 14.5673 11.7958L13.8158 11.0443V7.23346C13.8158 3.7118 10.9609 0.856934 7.43928 0.856934Z"
        fill="#9B9B9B"
      />
      <path
        d="M7.43936 17.8614C5.67854 17.8614 4.2511 16.434 4.2511 14.6731H10.6276C10.6276 16.434 9.20024 17.8614 7.43936 17.8614Z"
        fill="#9B9B9B"
      />
    </svg>
  );
};

const SettingsIcon = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.7337 1.77639C14.1587 -0.592131 10.7902 -0.592131 10.2152 1.77639C9.84376 3.30644 8.0908 4.03254 6.74623 3.21329C4.66484 1.94506 2.28295 4.32695 3.55118 6.40834C4.37043 7.75291 3.64433 9.50585 2.11428 9.87731C-0.254241 10.4523 -0.254241 13.8208 2.11428 14.3958C3.64433 14.7673 4.37043 16.5203 3.55118 17.8647C2.28295 19.9461 4.66484 22.3281 6.74625 21.0598C8.0908 20.2406 9.84376 20.9667 10.2152 22.4968C10.7902 24.8652 14.1587 24.8652 14.7337 22.4968C15.1052 20.9667 16.8582 20.2406 18.2026 21.0598C20.284 22.3281 22.666 19.9461 21.3977 17.8647C20.5785 16.5203 21.3046 14.7673 22.8347 14.3958C25.2031 13.8208 25.2031 10.4523 22.8347 9.87731C21.3046 9.50585 20.5785 7.75291 21.3977 6.40834C22.666 4.32695 20.284 1.94506 18.2026 3.21329C16.8582 4.03254 15.1052 3.30644 14.7337 1.77639ZM12.4745 16.6879C14.9881 16.6879 17.0257 14.6503 17.0257 12.1367C17.0257 9.62312 14.9881 7.58547 12.4745 7.58547C9.96092 7.58547 7.92327 9.62312 7.92327 12.1367C7.92327 14.6503 9.96092 16.6879 12.4745 16.6879Z"
        fill="#9B9B9B"
      />
    </svg>
  );
};
