export const singleEvaluationGenMain = [
  {
    name: 'frontPassportCopy',
    label: 'Passport Copy',
    secondLabel: '(front page + stamp / visa pages if any)',
    isRequired: true,
  },
  {
    name: 'updatedCv',
    label: 'Updated C.V',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'proofOfAddress',
    label: 'Proof of Address',
    secondLabel: '(example: water, gas, or electricity bill)',
    isRequired: true,
  },
]
export const doubleEvaluationGenMain = [
  {
    name: 'frontPassportCopy',
    label: 'Passport Copy',
    secondLabel: '(front page + stamp / visa pages if any)',
    isRequired: true,
  },
  {
    name: 'updatedCv',
    label: 'Updated C.V',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'proofOfAddress',
    label: 'Proof of Address',
    secondLabel: '(example: water, gas, or electricity bill)',
    isRequired: true,
  },
]
export const doubleEvaluationSpouse = [
  {
    name: 'spousePassportCopy',
    label: 'Passport Copy',
    secondLabel: '(front page + stamp / visa pages if any)',
    isRequired: true,
  },
  {
    name: 'spouseUpdatedCV',
    label: 'Updated C.V',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'spouseProofOfAddress',
    label: 'Proof of Address',
    secondLabel: '(example: water, gas, or electricity bill)',
    isRequired: true,
  },
]
