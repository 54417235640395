export const superVisaLetterClearanceSponsoredSpouse = [
  {
    name: 'spousePoliceClearance',
    label: 'Police Clearance (see Guidelines)',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'spouseCanadianMedicalExamProof',
    label:
      'Proof of Medical Exam From A Canadian Approved Physician (see Guidelines)',
    secondLabel: '',
    isRequired: true,
  },
]
export const selfEmpWorkPermitLetterClearSponsoredSpouse = [
  {
    name: 'spousePoliceClearance',
    label: 'Police Clearance (see Guidelines)',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'spouseCanadianMedicalExamProof',
    label:
      'Proof of Medical Exam From A Canadian Approved Physician (see Guidelines)',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'spouseLangTestResult',
    label: 'Language Test Results',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
]
