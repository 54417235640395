import axios from 'axios'
import { useContext } from 'react'

// import uploadimg from "../../assets/images/visa/upload.svg";
import { serverPath } from '../../config/key'
import UserContext from '../../context/UserContext'
// import attached from '../../assets/images/application/attached.svg'
// import trash from '../../assets/images/application/trash.png'
import { toast } from 'react-toastify'
import check from '../../assets/images/application/check-circle.svg'
import deleteimg from '../../assets/images/application/delete.svg'
import UploadIcon from '../../assets/icons/UploadIcon'
import { useParams } from 'react-router-dom'
// import upload from '../../assets/images/application/hover-upload.svg'
import download from '../../assets/images/application/download.png'
const SponsoringGeneralInfo = ({
  documentInfo,
  setDocumentInfo,
  documentDis,
  updFormType,
  generalSponsoringInfo,
  errors,
}) => {
  const { formType } = useParams()
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('t')
  const { selectedVisa } = useContext(UserContext)
  const handleFile = async (e) => {
    let apiPath = token ? 'support/visaUpdateDocuments' : 'visa/visaDocuments'
    const {
      target: { files, name },
    } = e
    const formData = new FormData()
    formData.append('file', files[0])
    formData.append('type', name)
    const res = await axios.post(`${serverPath}/${apiPath}`, formData, {
      headers: {
        authorization: `Bearer ${
          token ? token : localStorage.getItem('token')
        }`,
      },
      params: {
        visaTypeId: selectedVisa?.visaId,
        invoiceId: selectedVisa?.invoiceId,
        formsType: formType || updFormType,
      },
    })
    if (res?.data?.create?.findDoc) {
      const data = res?.data?.create?.findDoc
      setDocumentInfo({ ...documentInfo, ...data })
    }
  }
  const removeFile = async (type) => {
    try {
      let apiPath = token
        ? 'support/removeUpdateDocument'
        : 'visa/removeDocument'
      const res = await axios.post(
        `${serverPath}/${apiPath}`,
        {
          visaTypeId: selectedVisa?.visaId,
          invoiceId: selectedVisa?.invoiceId,
          type: type,
          formsType: formType || updFormType,
        },
        {
          headers: {
            authorization: `Bearer ${
              token ? token : localStorage.getItem('token')
            }`,
          },
        },
      )
      if (res?.data?.create?.findDoc) {
        const data = res?.data?.create?.findDoc
        setDocumentInfo(data)
      }
    } catch (err) {
      toast.error(err.message, { toastId: 'erro' })
    }
  }
  const downloadFile = async (url) => {
    try {
      const res = await axios.post(
        `${serverPath}/visa/downloadSingleDocument`,
        {
          visaTypeId: documentInfo.visaTypeId,
          invoiceId: documentInfo?.invoiceId,
          type: url,
          formsType: formType || (updFormType === 'forms' && 'main'),
        },
        {
          headers: {
            authorization: `Bearer ${token ? token : localStorage.getItem('token')}`,
            responseType: 'blob',
          },
        },
      )
      const { status } = res
      if (status === 201) {
        const file = url.split('com/').reverse()[0].split('/').reverse()[0]
        var blob = new Blob([res.data], {
          type: res.headers['content-type'],
        })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `${file}`
        link.click()
      }
    } catch (err) {
      toast.error(err.message, { toastId: 'erro' })
    }
  }
  return (
    <div className="general-info">
      {generalSponsoringInfo?.length > 0 &&
        generalSponsoringInfo?.map((el, i) => (
          <div className="general-info-listing" key={el?.name}>
            <div className="left-info">
              <h6>{el?.label}</h6>
            </div>
            <div className="middle-info">
              <h6>{el?.secondLabel} </h6>
            </div>
            {documentInfo && documentInfo[`${el?.name?.toString()}`] ? (
              <div className="documents-attached-section">
                <div className="application-attached">
                  Attached <img src={check} alt="" />
                </div>
                {!documentDis ? (
                  <>
                    <span onClick={() => removeFile(el?.name)}>
                      <img
                        src={deleteimg}
                        alt="uploadimg"
                        style={{ width: '20px', height: '20px' }}
                      />
                    </span>
                    {token ? (
                      <span onClick={() => downloadFile(documentInfo[`${el?.name?.toString()}`])}>
                        <img
                          src={download}
                          alt="download"
                          style={{ width: '20px', height: '20px' }}
                        />
                      </span>
                    ) : (
                      ''
                    )}
                  </>
                ) : (
                  ''
                )}
              </div>
            ) : (
              <div
                className={`right-info ${documentDis ? 'upload-block' : ''}`}
              >
                <h6>Upload</h6>
                <div className="upload-image">
                  <input
                    id={`sponsoringGeneralUpload${i}`}
                    type="file"
                    name={el?.name}
                    onChange={handleFile}
                    disabled={documentDis ? documentDis : false}
                  />
                  <label htmlFor={`sponsoringGeneralUpload${i}`}>
                    <UploadIcon />{' '}
                  </label>
                </div>
                {errors &&
                  errors?.map((er) =>
                    er?.name === el?.name ? (
                      <span className="error-msg doc-error">{er?.message}</span>
                    ) : (
                      ''
                    ),
                  )}
              </div>
            )}
          </div>
        ))}
    </div>
  )
}
export default SponsoringGeneralInfo
