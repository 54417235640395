import React, { useState, useEffect } from 'react'
import { useLocation, Route, Routes, Navigate } from 'react-router-dom'
import axios from 'axios'
import { serverPath } from './config/key'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import UserContext from './context/UserContext'
// import Express from './components/visa/express'
// import WorkerVisa from './components/visa/worker'
// import BusinessVisa from './components/visa/business'
// import TouristVisa from './components/visa/tourist'
// import FamilyVisa from './components/visa/family'
// import HolidayVisa from './components/visa/holiday'
// import TemporaryVisa from './components/visa/temporary'
// import StudentVisa from './components/visa/student'
import './assets/css/line-awesome.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-phone-input-2/lib/bootstrap.css'
import Welcome from './components/myapplication/welcome'

import Settings from './components/myapplication/settings'
import Notification from './components/myapplication/notification'
import Invoices from './components/myapplication/invoices'
import './assets/css/line-awesome.min.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-phone-input-2/lib/bootstrap.css'

import Products from './components/myapplication/products/index'
import WorldCardForm from './components/worldCardForm'

import VisaForm from './components/visaForm'
import SupportVisaForm from './components/visaForm/supportView'
import Agreement from './components/myapplication/agreements/agreement'
import EvaluationVisaForm from './components/visaEvaluation'
import SupportEvaluationVisaForm from './components/visaEvaluation/supportEvaluation'
import Guidelines from './components/myapplication/guidelines'
import { clientPath } from './config/key'
import Auth from './components/authPages/auth'
import OtpVerify from './components/authPages/otpVerify'
import AdminPanel from './components/admin/index'
import ForgotPassword from './components/authPages/forgotPassword'
import { getCookie } from './utils/cookieUtil'
const ProtectedRoute = ({ needRoles, user, children }) => {
  if (Object.keys(user)?.length > 0) {
    if (needRoles?.some((el) => user?.role?.includes(el))) {
      return children
    } else {
      return <Navigate to="/" />
    }
  }
  return <Navigate to="/" />
}
function App() {
  const { search } = useLocation()
  const userToken = new URLSearchParams(search).get('t')
  const [token, setToken] = useState(
    localStorage.getItem('token') || getCookie('token'),
  )
  const [user, setUser] = useState(
    localStorage.getItem('user')
      ? !getCookie('user')
        ? JSON.parse(localStorage.getItem('user'))
        : JSON.parse(localStorage.getItem('user'))
      : getCookie('user')
      ? JSON.parse(decodeURIComponent(getCookie('user')))
      : {},
  )
  const [users, setUsers] = useState([])
  const [countries, setCountries] = useState([])
  const [allCompany, setAllCompany] = useState([])
  const [allBrand, setAllBrand] = useState([])
  const [application, setApplication] = useState({})
  const [userGeoData, setUserGeoData] = useState({})
  const [savePercentage, setSavePercentage] = useState(0)
  const [selectedVisa, setSelectedVisa] = useState({
    visaId: '',
    invoiceId: '',
    caseId: '',
    visaName: '',
    invoiceNumber: '',
    sponsorForms: [],
    forms: [],
    productId: '',
    isEvaluation: false,
  })
  const [agreementVisa, setAgreementVisa] = useState([])
  const location = useLocation()
  useEffect(() => {
    if (getCookie('token') && getCookie('user')) {
      localStorage.setItem('token', getCookie('token'))
      localStorage.setItem(
        'user',
        JSON.parse(JSON.stringify(decodeURIComponent(getCookie('user')))),
      )
    }
  }, [])
  useEffect(() => {
    const getProfile = async () => {
      try {
        const res = await axios.get(`${serverPath}/users/getProfile`, {
          headers: { authorization: `Bearer ${token ? token : userToken}` },
        })
        const { user } = res?.data
        if (user) {
          setUser(user)
          localStorage.setItem('user', JSON.stringify(user))
        }
      } catch (error) {
        localStorage.removeItem('token')
        toast.error(error.response.data, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
        window.location.href = `${clientPath}/auth`
      }
    }
    if ((token && !user) || (userToken && !user)) {
      getProfile()
    }
  }, [token, userToken, user])
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }, [location])

  const getCountries = async () => {
    let countryDetail = []
    try {
      const res = await axios.get('https://restcountries.com/v2/all')
      if (res?.data?.length > 0) {
        res?.data?.forEach((el) => {
          countryDetail.push({ countryCode: el.alpha2Code, name: el.name })
          setCountries(countryDetail)
        })
      }
    } catch (err) {
      toast.error('Something went wrong!')
    }
  }
  useEffect(() => {
    if (countries.length === 0) {
      getCountries()
    }
  }, [countries])
  useEffect(() => {
    if (window.location.pathname === '/') {
      let ele = document.getElementById(window.location.hash.substring(1))
      if (ele) {
        ele.scrollIntoView({ top: 0, behavior: 'smooth' })
      }
    }
  }, [])
  const originData = async () => {
    const res = await axios.get('https://ipapi.co/json')

    setUserGeoData(res.data)
  }

  useEffect(() => {
    originData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="App">
      <UserContext.Provider
        value={{
          token,
          user,
          users,
          countries,
          setToken,
          setUser,
          setUsers,
          setCountries,
          setUserGeoData,
          userGeoData,
          setApplication,
          selectedVisa,
          setSelectedVisa,
          savePercentage,
          setSavePercentage,
          application,
          agreementVisa,
          setAgreementVisa,
          allCompany,
          setAllCompany,
          allBrand,
          setAllBrand,
        }}
      >
        <Routes>
          <Route path="/" element={<Auth />} />
          <Route path="/verifyUser" element={<OtpVerify />} />
          <Route path="/forgotPassword" element={<ForgotPassword />} />
          <Route
            exact
            path="/worldcard/:checkoutId"
            element={<WorldCardForm />}
          />
          <Route
            exact
            path="/application/:formType"
            element={
              <ProtectedRoute user={user} needRoles={['client']}>
                <VisaForm />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/evaluation"
            element={
              <ProtectedRoute user={user} needRoles={['client']}>
                <EvaluationVisaForm />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/welcome"
            element={
              <ProtectedRoute user={user} needRoles={['client', 'admin']}>
                <Welcome />
              </ProtectedRoute>
            }
          />
          <Route exact path="/guideline" element={<Guidelines />} />
          <Route exact path="/settings" element={<Settings />} />
          <Route exact path="/notifications" element={<Notification />} />
          <Route exact path="/invoices" element={<Invoices />} />
          <Route
            exact
            path="/products"
            element={
              <ProtectedRoute user={user} needRoles={['client']}>
                <Products />
              </ProtectedRoute>
            }
          />
          <Route
            exact
            path="/support/application"
            element={<SupportVisaForm />}
          />
          <Route
            exact
            path="/support/evaluation"
            element={<SupportEvaluationVisaForm />}
          />
          <Route
            exact
            path="/agreement"
            element={<Agreement />}
            user={user}
            needRoles={['client']}
          />
          <Route
            exact
            path="/admin"
            element={
              <ProtectedRoute user={user} needRoles={['admin']}>
                <AdminPanel />
              </ProtectedRoute>
            }
          />
        </Routes>
        <ToastContainer />
      </UserContext.Provider>
    </div>
  )
}

export default App
