import { useState, useContext } from "react";
import { Navbar, Nav, Offcanvas } from "react-bootstrap";
import { serverPath } from "../../config/key";
import axios from "axios";
import UserContext from "../../context/UserContext";
import { NavLink, useNavigate } from "react-router-dom";
// import logouticon from "../../assets/images/application/logout.svg";
import guideBlack from "../../assets/images/application/guideline-black.svg";
import guideOrg from "../../assets/images/application/guideline-orange.svg";
import caseicon from "../../assets/images/application/case.svg";
import menuClose from "../../assets/images/application/mobCloseIcon.svg";
import { useEffect } from "react";
import { deleteCookie, getCookie } from "../../utils/cookieUtil";

const SubMenu = ({ show, handleClose }) => {
  const navigate = useNavigate();
  const [visaType, setVisaType] = useState([]);
  const { user, selectedVisa, setSelectedVisa } = useContext(UserContext);

  const handleLogout = () => {
    localStorage.removeItem("token");
    deleteCookie("token");
    deleteCookie("user");
    setSelectedVisa({
      visaId: "",
      invoiceId: "",
      caseId: "",
      visaName: "",
      invoiceNumber: "",
    });
    navigate("/");
  };

  const allVisaType = async () => {
    const res = await axios.get(`${serverPath}/product/allVisaTypes`, {
      headers: { authorization: `Bearer ${localStorage.getItem("token") || getCookie('token')}` },
    });
    const { visaData } = res?.data || {};
    const { userVisaType = [] } = visaData;
    let type = [];
    if (userVisaType?.length) {
      userVisaType?.forEach((el) => {
        if (el?.productId?.visaType || el) {
          type.push({
            name: `${el?.productId?.visaType?.name}-${el.invoiceNumber}`,
            _id: el?.productId?.visaType?._id,
            invoiceNumber: el?.invoiceNumber,
            invoiceId: el?._id,
            formsSponsor: el?.productId?.visaType?.formsSponsor
              ? el?.productId?.visaType?.formsSponsor
              : [],
          });
        }
      });
      setVisaType(type);
    }
  };
  useEffect(() => {
    allVisaType();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="">
      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="left"
        className="mob-sidebar"
        backdrop="static"
      >
        <Offcanvas.Body>
          <div className="application-menu">
            <div
              className="menu-close-btn"
              onClick={handleClose}
              role="presentation"
            >
              <img src={menuClose} alt="" />
            </div>
            <div className="application-case-mob">
              <div className="application-case-icon">
                <span>{user?.name}</span>
                <div className="application-case-id">
                  <img src={caseicon} alt="" /> case:{" "}
                  {selectedVisa?.caseId ? selectedVisa?.caseId : ""}
                </div>
              </div>
            </div>
            <Navbar>
              <Nav>
                <NavLink to="/guideline">
                  <span>
                    {/* <GuidelineIcon /> */}
                    <img
                      src={guideBlack}
                      className="non-active-img"
                      alt="icon"
                    />
                    <img src={guideOrg} className="active-img" alt="icon" />
                  </span>{" "}
                  Guidelines
                </NavLink>
                {visaType?.find(
                  (el) => el?.invoiceId === selectedVisa?.invoiceId
                )?.formsSponsor?.length > 0 ? (
                  <>
                    <NavLink to="/application/sponsoring">
                      <span>
                        <ApplicationIcon />
                      </span>{" "}
                      Sponsoring(App)
                    </NavLink>
                    <NavLink to="/application/sponsored">
                      <span>
                        <ApplicationStarIconNew />
                      </span>{" "}
                      Sponsored(App)
                    </NavLink>
                  </>
                ) : (
                  <NavLink to="/application/0">
                    <span>
                      <ApplicationIcon />
                    </span>{" "}
                    Application
                  </NavLink>
                )}

                <NavLink to="/products">
                  <span>
                    <ProductIcon />
                  </span>{" "}
                  Product
                </NavLink>
                <NavLink to="/invoices">
                  <span>
                    <InvoiceIcon />
                  </span>{" "}
                  Invoices
                </NavLink>
              </Nav>
            </Navbar>
            <div className="application-logout" onClick={handleLogout}>
              <span>
                <LogoutIcon /> Log Out
              </span>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </div>
  );
};

export default SubMenu;

const ApplicationStarIconNew = () => {
  return (
    <svg
      width="20"
      height="24"
      viewBox="0 0 20 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 4H10L16 10V22C16 23.1 15.1 24 14 24H1.99C0.89 24 0 23.1 0 22V6C0 4.9 0.9 4 2 4ZM4 20H12V18H4V20ZM12 16H4V14H12V16ZM9 5.5V11H14.5L9 5.5Z"
        fill="#6243CE"
      />
      <path
        d="M19.008 3.98014L18.6 4.69214L17.272 3.92414C17.1813 3.86548 17.096 3.80681 17.016 3.74814C16.936 3.68948 16.8667 3.62281 16.808 3.54814C16.8827 3.71348 16.92 3.90548 16.92 4.12414V5.65214H16.088V4.14014C16.088 3.91081 16.1173 3.71881 16.176 3.56414C16.0747 3.69748 15.928 3.81748 15.736 3.92414L14.416 4.68414L14.008 3.97214L15.32 3.21214C15.512 3.10014 15.7013 3.02814 15.888 2.99614C15.7013 2.97481 15.512 2.90281 15.32 2.78014L14 2.01214L14.408 1.30014L15.736 2.06814C15.8267 2.12681 15.9093 2.18814 15.984 2.25214C16.064 2.31081 16.1333 2.37748 16.192 2.45214C16.1227 2.28148 16.088 2.08681 16.088 1.86814V0.348145H16.92V1.86014C16.92 1.96681 16.912 2.06814 16.896 2.16414C16.8853 2.26014 16.8613 2.35081 16.824 2.43614C16.9467 2.29214 17.096 2.16948 17.272 2.06814L18.592 1.30814L19 2.02014L17.688 2.78014C17.5973 2.83348 17.5067 2.88148 17.416 2.92414C17.3253 2.96148 17.2347 2.98548 17.144 2.99614C17.2347 3.01214 17.3253 3.03881 17.416 3.07614C17.5067 3.10814 17.5973 3.15348 17.688 3.21214L19.008 3.98014Z"
        fill="#6243CE"
      />
    </svg>
  );
};

// const GuidelineIcon = () => {
//   return (
//     <svg
//       width="16"
//       height="16"
//       viewBox="0 0 16 16"
//       fill="none"
//       xmlns="http://www.w3.org/2000/svg"
//     >
//       <g clip-path="url(#clip0_415_2094)">
//         <path
//           fill-rule="evenodd"
//           clip-rule="evenodd"
//           d="M0 2C0 1.46957 0.210714 0.960859 0.585786 0.585786C0.960859 0.210714 1.46957 0 2 0H14C14.5304 0 15.0391 0.210714 15.4142 0.585786C15.7893 0.960859 16 1.46957 16 2V14C16 14.5304 15.7893 15.0391 15.4142 15.4142C15.0391 15.7893 14.5304 16 14 16H2C1.46957 16 0.960859 15.7893 0.585786 15.4142C0.210714 15.0391 0 14.5304 0 14V2ZM9.20594 4.54193C8.97064 4.77723 8.6515 4.90942 8.31873 4.90942C7.98596 4.90942 7.66682 4.77723 7.43151 4.54193C7.19621 4.30662 7.06401 3.98748 7.06401 3.65471C7.06401 3.32194 7.19621 3.0028 7.43151 2.7675C7.66682 2.53219 7.98596 2.4 8.31873 2.4C8.6515 2.4 8.97064 2.53219 9.20594 2.7675C9.44125 3.0028 9.57344 3.32194 9.57344 3.65471C9.57344 3.98748 9.44125 4.30662 9.20594 4.54193ZM6.61232 6.63465L9.48561 6.27455L8.22964 12.178C8.14056 12.6046 8.26603 12.8467 8.61107 12.8467C8.85449 12.8467 9.22086 12.7589 9.47181 12.5381L9.36139 13.06C9.00129 13.4942 8.20706 13.8103 7.52324 13.8103C6.64118 13.8103 6.26602 13.2809 6.50943 12.1554L7.43541 7.80404C7.51571 7.43641 7.44294 7.30341 7.07405 7.21558L6.50943 7.11144L6.61232 6.63465Z"
//           fill="#9B9B9B"
//         />
//       </g>
//       <defs>
//         <clipPath id="clip0_415_2094">
//           <rect width="16" height="16" fill="white" />
//         </clipPath>
//       </defs>
//     </svg>
//   );
// };

const ApplicationIcon = () => {
  return (
    <svg
      width="16"
      height="20"
      viewBox="0 0 16 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 0H10L16 6V18C16 19.1 15.1 20 14 20H1.99C0.89 20 0 19.1 0 18V2C0 0.9 0.9 0 2 0ZM4 16H12V14H4V16ZM12 12H4V10H12V12ZM9 1.5V7H14.5L9 1.5Z"
        fill="#9B9B9B"
      />
    </svg>
  );
};

const InvoiceIcon = () => {
  return (
    <svg
      width="18"
      height="15"
      viewBox="0 0 18 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M17.0528 12.5001V13.3335C17.0528 14.2502 16.2002 15.0002 15.1581 15.0002H1.89476C0.843167 15.0002 0 14.2502 0 13.3335V1.66668C0 0.750008 0.843167 0 1.89476 0H15.1581C16.2002 0 17.0528 0.750008 17.0528 1.66668V2.50003H8.52641C7.47482 2.50003 6.63165 3.25003 6.63165 4.16671V10.8334C6.63165 11.7501 7.47482 12.5001 8.52641 12.5001H17.0528ZM8.52637 10.8332H18.0002V4.1665H8.52637V10.8332ZM10.8945 7.50001C10.8945 8.19169 11.5293 8.75003 12.3156 8.75003C13.1019 8.75003 13.7367 8.19169 13.7367 7.50001C13.7367 6.80834 13.1019 6.25 12.3156 6.25C11.5293 6.25 10.8945 6.80834 10.8945 7.50001Z"
        fill="#9B9B9B"
      />
    </svg>
  );
};
const ProductIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 8H4V6H20V8ZM18 2H6V4H18V2ZM22 12V20C22 20.5304 21.7893 21.0391 21.4142 21.4142C21.0391 21.7893 20.5304 22 20 22H4C3.47005 21.9984 2.96227 21.7872 2.58753 21.4125C2.2128 21.0377 2.00158 20.5299 2 20V12C2.00158 11.4701 2.2128 10.9623 2.58753 10.5875C2.96227 10.2128 3.47005 10.0016 4 10H20C20.5299 10.0016 21.0377 10.2128 21.4125 10.5875C21.7872 10.9623 21.9984 11.4701 22 12ZM13.927 17.042L16.25 15.056L13.191 14.8L12 12L10.809 14.8L7.75 15.056L10.073 17.042L9.373 20L12 18.428L14.627 20L13.927 17.042Z"
        fill="#6243CE"
      />
    </svg>
  );
};

const LogoutIcon = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.08 12.59L13.67 10H4V8H13.67L11.08 5.41L12.5 4L17.5 9L12.5 14L11.08 12.59ZM16 0C16.5304 0 17.0391 0.210714 17.4142 0.585786C17.7893 0.960859 18 1.46957 18 2V6.67L16 4.67V2H2V16H16V13.33L18 11.33V16C18 16.5304 17.7893 17.0391 17.4142 17.4142C17.0391 17.7893 16.5304 18 16 18H2C0.89 18 0 17.1 0 16V2C0 0.89 0.89 0 2 0H16Z"
        fill="#9B9B9B"
      />
    </svg>
  );
};
