export const superVisaGenSponsoredSpouse = [
  {
    name: 'spousePassportCopy',
    label: 'Passport Copy',
    secondLabel: ' (front page + stamp / visa pages if any)',
    isRequired: true,
  },
  {
    name: 'spouseUpdatedCv',
    label: 'Updated C.V',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'spouseFiveDigitalPhoto',
    label: 'Five Digital Photos',
    secondLabel: '',
  },
  {
    name: 'spouseBirthCertificate',
    label: 'Copy of Birth Certificate',
    secondLabel: '',
    isRequired: true,
  },
]
export const selfEmpWorkPermitGenSponsoredSpouse = [
  {
    name: 'spousePassportCopy',
    label: 'Passport Copy',
    secondLabel: ' (front page + stamp / visa pages if any)',
    isRequired: true,
  },
  {
    name: 'spouseUpdatedCv',
    label: 'Updated C.V',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'spouseDigitalPhoto',
    label: 'A Digital Photos',
    secondLabel: '',
  },
  {
    name: 'spouseBirthCertificate',
    label: 'Copy of Birth Certificate',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'spouseBackGroundDecl',
    label: 'Background/Declaration',
    secondLabel: '',
    isRequired: true,
  },
]
