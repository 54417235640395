/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import ApplicationLayout from "../layout";
import check from "../../../assets/images/application/check-circle.svg";
import deleteimg from "../../../assets/images/application/delete.svg";
import "./agreement.css";
import axios from "axios";
import { serverPath } from "../../../config/key";
import { useContext } from "react";
import UserContext from "../../../context/UserContext";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useState } from "react";
import Loader from "../../loader/loader";

const Agreement = () => {
  const { selectedVisa, agreementVisa, setAgreementVisa } =
    useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const getAgreement = async () => {
    setLoading(true);
    const res = await axios.get(`${serverPath}/visa/getVisaAgreement`, {
      headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
      params: {
        visaId: selectedVisa.visaId,
        invoiceId: selectedVisa.invoiceId,
      },
    });
    setLoading(false);
    const { findDoc } = res?.data;
    if (findDoc?.agreement?.length > 0) {
      setAgreementVisa(findDoc?.agreement);
    }
  };
  useEffect(() => {
    if (selectedVisa?.invoiceId && selectedVisa?.visaId) {
      getAgreement();
    }
  }, [selectedVisa]);
  const handleFile = async (e) => {
    try {
      const {
        target: { files, name },
      } = e;
      const formData = new FormData();
      formData.append("file", files[0]);
      formData.append("type", name);
      formData.append("agreement", JSON.stringify(agreementVisa));

      const res = await axios.post(
        `${serverPath}/visa/visaAgreement`,
        formData,
        {
          headers: {
            authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            visaTypeId: selectedVisa?.visaId,
            invoiceId: selectedVisa?.invoiceId,
          },
        }
      );
      if (res?.data?.create?.agreement.length > 0) {
        const data = res?.data?.create?.agreement;
        setAgreementVisa(data);
      }
    } catch (err) {
      toast.error("Failed to upload document", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  };
  const removeFile = async (type) => {
    try {
      const res = await axios.post(
        `${serverPath}/visa/removeAgreement`,
        {
          visaTypeId: selectedVisa?.visaId,
          invoiceId: selectedVisa?.invoiceId,
          type: type,
          agreement: JSON.stringify(agreementVisa),
        },
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      if (res?.data?.findDoc.agreement.length > 0) {
        const data = res?.data?.findDoc.agreement;
        setAgreementVisa(data);
      }
    } catch (err) {
      toast.error("Failed to remove document", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
      });
    }
  };
  const downloadAgreement = async (url) => {
    window.open(url, "_blank");
  };
  return (
    <ApplicationLayout>
      <div className="agreement-page">
        {selectedVisa?.invoiceId && selectedVisa?.visaId ? (
          <div className="container">
            {/* <h3>Agreement</h3> */}
            <div className="agreement-page-box">
              {agreementVisa && agreementVisa?.length > 0 ? (
                agreementVisa.map((el, i) => (
                  <div className="agreement-page-content" key={i}>
                    <div className="agreement-list">
                      <h6>{el?.title}</h6>

                      {el.uploadUrl && el.uploadUrl !== "" ? (
                        <div className="download-section">
                          <div
                            className="download"
                            onClick={() => downloadAgreement(el?.downloadUrl)}
                            role="presentation"
                          >
                            Download
                          </div>
                          <span> / </span>
                          <div className="attahed-img">
                            Attached
                            <img src={check} alt="check" />
                            <span onClick={() => removeFile(el?.name)}>
                              <img src={deleteimg} alt="check" />
                            </span>
                          </div>
                        </div>
                      ) : (
                        <div className="download-section">
                          <div
                            className="download"
                            onClick={() => downloadAgreement(el?.downloadUrl)}
                            role="presentation"
                          >
                            Download
                          </div>
                          <span> / </span>
                          <div className="upload">
                            <div className="upload-doc">
                              <label htmlFor={`upload${i}`}>Upload</label>
                              <input
                                type="file"
                                id={`upload${i}`}
                                name={el?.name}
                                onChange={handleFile}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                ))
              ) : (
                <div className="select-visatype">
                  <h2>No agreement to download yet</h2>
                </div>
              )}
            </div>
          </div>
        ) : loading ? (
          <Loader />
        ) : (
          <div className="select-visatype">
            <h2>Please Select visa type</h2>
          </div>
        )}
      </div>
    </ApplicationLayout>
  );
};
export default Agreement;
