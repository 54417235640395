/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import {
  Form,
  Accordion,
  Button,
  Card,
  useAccordionButton,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import axios from "axios";
import { toast } from "react-toastify";

import addIcon from "../../assets/images/visa/add-icon.svg";
import activeArrow from "../../assets/images/visa/active-arrow.svg";
import closeArrow from "../../assets/images/visa/close-arrow.svg";

import { serverPath } from "../../config/key";
import { useContext } from "react";
import UserContext from "../../context/UserContext";
import { useParams } from "react-router-dom";
import {
  sponsoringWorkExperience,
  workExperienceMain,
} from "../../utils/validationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import approved from "../../assets/images/header/approved.svg";
import ErrorModal from "../modals/errorModal";
import ExperienceExtraFields from "../visaAccordion/experienceExtraFields";
const WorkExperience = ({
  experienceInfo,
  setExperienceInfo,
  updFormType,
  adminFormDisable,
  setUnlockClient,
  unlockClient,
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("t");
  const { formType: type } = useParams();
  const { selectedVisa, setSelectedVisa } = useContext(UserContext);
  let intialState = {
    officialJobTitle: "",
    jobDescription: "",
    yearsExperience: "",
    fullTimePosition: "",
    hoursPerWeek: "",
    jobStartDate: "",
    jobEndTime: "",
  };
  let intialSpouseState = {
    sOfficialJobTitle: "",
    sJobDescription: "",
    sYearsExperience: "",
    sFullTimePosition: "",
    sHoursPerWeek: "",
    sJobStartDate: "",
    sJobEndTime: "",
  };
  let intialOnshoreState = {
    InCanOfficialJobTitle: "",
    InCanJobDescription: "",
    InCanYearsExperience: "",
    InCanFullTimePosition: "",
    InCanHoursPerWeek: "",
    InCanJobStartDate: "",
    InCanJobEndTime: "",
  };

  const [sponsoringShow, setSponsoringShow] = useState(false);
  useEffect(() => {
    if (type === "sponsoring" || updFormType === "sponsoring")
      setSponsoringShow(true);
    else setSponsoringShow(false);
  }, [type, updFormType]);
  let schema = sponsoringShow ? sponsoringWorkExperience : workExperienceMain;
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(schema),
    shouldUnregister: true,
  });

  const [jobs, setJobs] = useState([{ ...intialState }]);
  const [sponsoringJobOffshore, setSponsoringJobsOffshore] = useState([
    { ...intialState },
  ]);
  const [sponsoringJobOnshore, setSponsoringJobsOnshore] = useState([
    { ...intialOnshoreState },
  ]);
  const [jobsOnshore, setJobsOnshore] = useState([{ ...intialOnshoreState }]);
  const [spouseJobsOnshore, setSpouseJobsOnshore] = useState([
    { ...intialOnshoreState },
  ]);
  const [spouseJobsOffshore, setSpouseJobsOffshore] = useState([
    { ...intialSpouseState },
  ]);
  const [sponsoringSpouseJobOnshore, setSponsoringSpouseJobsOnshore] = useState(
    [{ ...intialOnshoreState }]
  );
  const [sponsoringSpouseJobsOffshore, setSponsoringSpouseJobsOffshore] =
    useState([{ ...intialSpouseState }]);
  const [offshoreDis, setOffshoreDis] = useState(false);
  const [onshoreDis, setOnshoreDis] = useState(false);
  const [spouseOffshoreDis, setSpouseOffshoreDis] = useState(false);
  const [spouseOnshoreDis, setSpouseOnshoreDis] = useState(false);
  const [customAccordBtn, setCustomAccordBtn] = useState(true);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [spouseFormState, setSpouseformstate] = useState("plus");
  const [mainApplicantMsg, setMainApplicantMsg] = useState("");
  // const [sponsoringSpouseFormState, setSponoringSpouseFormState] = useState(
  //   'plus',
  // )
  const [showDeleteIcon, setShowDeleteIcon] = useState(true);
  const [textSpouseMessage, setTextSpouseMessage] = useState("");
  useEffect(() => {
    setValue("offshoreMainInfo", jobs);
    if (spouseFormState === "open") {
      setValue("spouseOffshoreInfo", spouseJobsOffshore);
    }
  }, [jobs, spouseFormState, spouseJobsOffshore]);
  useEffect(() => {
    if (selectedVisa?.visaName === "Partner / Spouse Visa") {
      setValue("isSpouseOpen", true);
      setSpouseformstate("open");
      setShowDeleteIcon(false);
    }
    if (selectedVisa?.visaName === "Parent Sponsorship Visa") {
      setMainApplicantMsg("Applicant (Parent)");
    }
  }, [selectedVisa]);
  useEffect(() => {
    if (
      selectedVisa?.visaName === "Parent Sponsorship Visa" &&
      spouseFormState === "plus" &&
      !spouseOffshoreDis
    ) {
      setTextSpouseMessage("Add Applicant (Parent #2)");
    }
    if (
      selectedVisa?.visaName === "Parent Sponsorship Visa" &&
      spouseFormState === "open" &&
      !spouseOffshoreDis
    ) {
      setTextSpouseMessage("Applicant (Parent #2)");
    } else if (
      selectedVisa?.visaName === "Parent Sponsorship Visa" &&
      spouseFormState === "open" &&
      !spouseOffshoreDis
    ) {
      setTextSpouseMessage("Applicant (Parent #2)");
    } else if (
      selectedVisa?.visaName === "Parent Sponsorship Visa" &&
      spouseFormState === "plus" &&
      spouseOffshoreDis
    ) {
      setTextSpouseMessage("Applicant (Parent #2)");
    } else if (
      selectedVisa?.visaName === "Parent Sponsorship Visa" &&
      spouseFormState === "open" &&
      spouseOffshoreDis
    ) {
      setTextSpouseMessage("Applicant (Parent #2)");
    } else if (selectedVisa?.visaName === "Partner / Spouse Visa") {
      setTextSpouseMessage("Sponsor (partner/Spouse)");
    }
  }, [selectedVisa, spouseFormState, spouseOffshoreDis]);
  useEffect(() => {
    //set fetched value of main applicant offshore job
    if (
      experienceInfo &&
      Object.keys(experienceInfo)?.length > 0 &&
      experienceInfo?.offshoreJob?.length !== 0
    ) {
      // setOffshoreDis(token ? adminFormDisable : true)
      setJobs(experienceInfo?.offshoreJob);
    }
    if (
      experienceInfo &&
      experienceInfo?.offshoreJob?.length !== 0 &&
      experienceInfo.isLocked
    ) {
      setOnshoreDis(token ? adminFormDisable : true);
      setSpouseOffshoreDis(token ? adminFormDisable : true);
      setSpouseOnshoreDis(token ? adminFormDisable : true);
      setOffshoreDis(token ? adminFormDisable : true);
    }
    if (experienceInfo && experienceInfo?.onshoreJob?.length !== 0) {
      //set fetched value of main applicant onshore job
      setJobsOnshore(experienceInfo?.onshoreJob);
      //setOnshoreDis(token ? adminFormDisable : true)
    }
    //set fetched spouse offshore job
    if (experienceInfo && experienceInfo?.spouseOffshoreJob?.length !== 0) {
      setSpouseJobsOffshore(experienceInfo?.spouseOffshoreJob);
    }
    //set fetched spouse onshore job
    if (experienceInfo && experienceInfo?.spouseOnshoreJob?.length !== 0) {
      setSpouseJobsOnshore(experienceInfo?.spouseOnshoreJob);
      // setSpouseOnshoreDis(token ? adminFormDisable : true)
    }
    //set fetched sponsored offshore job
    if (
      experienceInfo &&
      experienceInfo?.sponsoredOffshoreJob?.length !== 0 &&
      experienceInfo.isLocked
    ) {
      setOnshoreDis(token ? adminFormDisable : true);
      setOffshoreDis(token ? adminFormDisable : true);
      setSpouseOffshoreDis(token ? adminFormDisable : true);
      setSpouseOnshoreDis(token ? adminFormDisable : true);
    }
    if (experienceInfo && experienceInfo?.sponsoredOffshoreJob?.length !== 0) {
      setJobs(experienceInfo?.sponsoredOffshoreJob);
      //setOffshoreDis(token ? adminFormDisable : true)
    }
    //set fetched sponsoredOnshore job
    if (experienceInfo && experienceInfo?.sponsoredOnshoreJob?.length !== 0) {
      setJobsOnshore(experienceInfo?.sponsoredOnshoreJob);
      //setOnshoreDis(token ? adminFormDisable : true)
    }
    if (
      experienceInfo &&
      experienceInfo?.sponsoredSpouseOffshoreJob?.length !== 0
    ) {
      setSpouseJobsOffshore(experienceInfo?.sponsoredSpouseOffshoreJob);
      // setSpouseOffshoreDis(token ? adminFormDisable : true)
    }
    //set fetched spouse onshore job
    if (
      experienceInfo &&
      experienceInfo?.sponsoredSpouseOnshoreJob?.length !== 0
    ) {
      setSpouseJobsOnshore(experienceInfo?.sponsoredSpouseOnshoreJob);
      // setSpouseOnshoreDis(token ? adminFormDisable : true)
    }
    //set fetched sponsoring offshore job
    if (
      experienceInfo &&
      experienceInfo?.sponsoringOffshoreJob?.length !== 0 &&
      experienceInfo.isLocked
    ) {
      setOffshoreDis(token ? adminFormDisable : true);
      setOnshoreDis(token ? adminFormDisable : true);
    }
    if (experienceInfo && experienceInfo?.sponsoringOffshoreJob?.length !== 0) {
      setSponsoringJobsOffshore(experienceInfo?.sponsoringOffshoreJob);
      //setOffshoreDis(token ? adminFormDisable : true)
    }
    //fetched and set spouse sponsoring offshore job
    if (
      experienceInfo &&
      experienceInfo?.sponsoringSpouseOffshoreJob?.length !== 0
    ) {
      setSponsoringSpouseJobsOffshore(
        experienceInfo?.sponsoringSpouseOffshoreJob
      );
      // setOffshoreDis(token ? adminFormDisable : true)
    }
    //fetched nad set spouse sponsoring onshore job
    if (
      experienceInfo &&
      experienceInfo?.sponsoringSpouseOnshoreJob?.length !== 0
    ) {
      setSponsoringSpouseJobsOnshore(
        experienceInfo?.sponsoringSpouseOnshoreJob
      );
      //setOffshoreDis(token ? adminFormDisable : true)
    }
    //set fetched sponsoring onshore job
    if (experienceInfo && experienceInfo?.sponsoringOnshoreJob?.length !== 0) {
      setSponsoringJobsOnshore(experienceInfo?.sponsoringOnshoreJob);
      // setOnshoreDis(token ? adminFormDisable : true)
    }
  }, [experienceInfo, token, adminFormDisable]);

  //add main applicant job offshore form
  const handleAddJob = () => {
    setJobs([...jobs, { ...intialState }]);
  };

  //remove main offshore job form
  const removeAddJob = (index) => {
    const oldJob = [...jobs];
    oldJob.splice(index, 1);
    setJobs(oldJob);
  };
  const handleAddJobOnshore = () => {
    setJobsOnshore([...jobsOnshore, { ...intialOnshoreState }]);
  };
  const removeAddJobOnshore = (index) => {
    const oldJob = [...jobsOnshore];
    oldJob.splice(index, 1);
    setJobsOnshore(oldJob);
  };
  const handleAddSpouseJob = () => {
    setSpouseJobsOnshore([...spouseJobsOnshore, { ...intialOnshoreState }]);
  };
  const removeAddSpouseJob = (index) => {
    const oldJob = [...spouseJobsOnshore];
    oldJob.splice(index, 1);
    setSpouseJobsOnshore(oldJob);
  };
  const handleAddSpouseOffshoreJob = () => {
    setSpouseJobsOffshore([...spouseJobsOffshore, { ...intialOnshoreState }]);
  };
  const removeAddSpouseOffshoreJob = (index) => {
    const oldJob = [...spouseJobsOffshore];
    oldJob.splice(index, 1);
    setSpouseJobsOffshore(oldJob);
  };
  const handleChange = (e, index) => {
    const { name, value } = e.target;
    let prevData = [...jobs];
    prevData[index][name] = value;
    setJobs([...prevData]);
  };
  const handleSpouseOnshoreChange = (e, index) => {
    const { name, value } = e.target;
    let prevData = [...spouseJobsOnshore];
    prevData[index][name] = value;
    setSpouseJobsOnshore([...prevData]);
  };
  const handleSpouseOffshoreChange = (e, index) => {
    const { name, value } = e.target;
    let prevData = [...spouseJobsOffshore];
    prevData[index][name] = value;
    setSpouseJobsOffshore([...prevData]);
  };
  const handleChangeInsideCan = (e, index) => {
    const { name, value } = e.target;
    let prevData = [...jobsOnshore];
    prevData[index][name] = value;
    setJobsOnshore([...prevData]);
  };
  const handleJobStartDatePicker = (date, index, type) => {
    if (type === "jobStartDate") {
      let prevData = [...jobs];
      prevData[index][type] = date;
      setJobs([...prevData]);
    }
    if (type === "InCanJobStartDate") {
      let prevData = [...jobsOnshore];
      prevData[index][type] = date;
      setJobsOnshore([...prevData]);
    }
    if (type === "spouseOffshoreStartDate") {
      let prevData = [...spouseJobsOffshore];
      prevData[index].sJobStartDate = date;
      setSpouseJobsOffshore([...prevData]);
    }
    if (type === "spouseOnshoreInCanJobStartDate") {
      let prevData = [...spouseJobsOnshore];
      prevData[index].InCanJobStartDate = date;
      setSpouseJobsOnshore([...prevData]);
    }
    if (type === "sponsoringSpouseOffshoreJobStartDate") {
      let prevData = [...sponsoringSpouseJobsOffshore];
      prevData[index].sJobStartDate = date;
      setSponsoringSpouseJobsOffshore([...prevData]);
    }
    if (type === "sponsoringSpouseInCanJobStartDate") {
      let prevData = [...sponsoringSpouseJobOnshore];
      prevData[index].InCanJobStartDate = date;
      setSponsoringSpouseJobsOnshore([...prevData]);
    }
    if (type === "sponsoringInCanJobStartDate") {
      let prevData = [...sponsoringJobOnshore];
      prevData[index].InCanJobStartDate = date;
      setSponsoringJobsOnshore([...prevData]);
    }
    if (type === "sponsoringOffshoreJobStartDate") {
      let prevData = [...sponsoringJobOffshore];
      prevData[index].jobStartDate = date;
      setSponsoringJobsOffshore([...prevData]);
    }
  };
  const handleJobEndTimePicker = (date, index, type) => {
    if (type === "jobEndTime") {
      let prevData = [...jobs];
      prevData[index][type] = date;
      setJobs([...prevData]);
    }
    if (type === "InCanJobEndTime") {
      let prevData = [...jobsOnshore];
      prevData[index][type] = date;
      setJobsOnshore([...prevData]);
    }
    if (type === "spouseOffshoreJobEndTime") {
      let prevData = [...spouseJobsOffshore];
      prevData[index].sJobEndTime = date;
      setSpouseJobsOffshore([...prevData]);
    }
    if (type === "spouseOnshoreInCanJobEndTime") {
      let prevData = [...spouseJobsOnshore];
      prevData[index].InCanJobEndTime = date;
      setSpouseJobsOnshore([...prevData]);
    }
    if (type === "sponsoringOffshoreJobEndTime") {
      let prevData = [...sponsoringJobOffshore];
      prevData[index].jobEndTime = date;
      setSponsoringJobsOffshore([...prevData]);
    }
    if (type === "sponsoringInCanJobEndTime") {
      let prevData = [...sponsoringJobOnshore];
      prevData[index].InCanJobEndTime = date;
      setSponsoringJobsOnshore([...prevData]);
    }
    if (type === "sponsoringInCanJobEndTime") {
      let prevData = [...sponsoringJobOnshore];
      prevData[index].InCanJobEndTime = date;
      setSponsoringJobsOnshore([...prevData]);
    }
    if (type === "sponsoringSpouseOffshoreJobEndTime") {
      let prevData = [...sponsoringSpouseJobsOffshore];
      prevData[index].sJobEndTime = date;
      setSponsoringSpouseJobsOffshore([...prevData]);
    }
    if (type === "sponsoringSpouseInCanJobEndTime") {
      let prevData = [...sponsoringSpouseJobOnshore];
      prevData[index].InCanJobEndTime = date;
      setSponsoringSpouseJobsOnshore([...prevData]);
    }
  };

  //for open the accordion

  const changeAccordionselect = (key) => {
    if (key === "1") {
      setSpouseformstate("open");
      setValue("isSpouseOpen", true);
    } else if (key === "3") {
      setValue("isSponsoringSpouseOpen", true);
      //setSponoringSpouseFormState('open')
    }
  };
  const handleDelete = (value, id) => {
    if (value === "spouseFormState") {
      setSpouseformstate("plus");
      setValue("isSpouseOpen", false);
      setCustomAccordBtn(false);
      setSpouseError({});
    } else if (value === "sponsoringSpouseFormState") {
      // setSponoringSpouseFormState('plus')
      setValue("isSponsoringSpouseOpen", false);
    }
    document.getElementById(id).click();
  };
  const handleClose = (value, id) => {
    document.getElementById(id).click();
  };
  const [spouseError, setSpouseError] = useState({});
  const workExperience = async (values) => {
    try {
      let apiPath = token
        ? "support/visaUpdateExperience"
        : "visa/visaWorkExperience";
      const res = await axios.post(
        `${serverPath}/${apiPath}`,
        {
          ...values,
          offshoreJob: jobs,
          onshoreJob: jobsOnshore,
          spouseOffshoreJob: spouseJobsOffshore,
          spouseOnshoreJob: spouseJobsOnshore,
          sponsoringJobOnshore: sponsoringJobOnshore,
          sponsoringJobOffshore: sponsoringJobOffshore,
          sponsoringSpouseJobOnshore: sponsoringSpouseJobOnshore,
          sponsoringSpouseJobsOffshore: sponsoringSpouseJobsOffshore,
          visaTypeId: selectedVisa.visaId,
          invoiceId: selectedVisa.invoiceId,
          formType: type || updFormType,
        },
        {
          headers: {
            authorization: `Bearer ${
              token ? token : localStorage.getItem("token")
            }`,
          },
        }
      );
      const { code, create } = res.data;
      if (code === 200) {
        setExperienceInfo(create);
        setUnlockClient(!unlockClient);
        setSelectedVisa({
          invoiceId: create?.invoiceId,
          visaId: create?.visaTypeId,
          caseId: selectedVisa?.caseId,
          visaName: selectedVisa?.visaName,
          isEvaluation: selectedVisa?.isEvaluation,
        });
        toast.success("Your information saved successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    } catch (err) {
      if (err?.response?.data) {
        toast.error(err.response.data.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    }
  };
  //custom accordian toggle
  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      if (customAccordBtn === eventKey) {
        setCustomAccordBtn("");
      } else setCustomAccordBtn(eventKey);
    });
    return (
      <Button id={`work-${eventKey}`} onClick={decoratedOnClick}>
        {children}
      </Button>
    );
  }

  //For Error popup
  const handleShowErrorModal = () => {
    setShowErrorModal(false);
  };
  useEffect(() => {
    if (errors && Object.keys(errors).length !== 0) {
      setShowErrorModal(true);
    } else {
      setShowErrorModal(false);
    }
  }, [errors, spouseError]);

  return (
    <div className="personal-info work-exp-section">
      <Form onSubmit={handleSubmit(workExperience)}>
        {!sponsoringShow ? (
          <Accordion
            defaultActiveKey="0"
            onSelect={(key) => changeAccordionselect(key)}
          >
            <div className={`accordion-list ${"change-accordion-icon"}`}>
              <Accordion.Item eventKey="0">
                <Accordion.Header>
                  {mainApplicantMsg ? mainApplicantMsg : "Main Applicant"}
                </Accordion.Header>

                <Accordion.Body>
                  <span className="tab-subheading">
                    Your work experience in the past 10 years (or since you were
                    18 years old if you are currently 28 years old).
                  </span>
                  <div className="outside-country">
                    <h6>OFF-Shore (Outside Canada)</h6>
                    <Form.Group
                      className={`form-group ${
                        errors?.numberOfJobOffshore?.message ? "error-msg" : ""
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>
                        Number of Jobs in the Past 10 Years*
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="numberOfJobOffshore"
                        defaultValue={
                          experienceInfo?.numberOfJobOffshore
                            ? experienceInfo?.numberOfJobOffshore
                            : experienceInfo?.sponsoredNumberOfJobOffshore
                        }
                        disabled={offshoreDis ? offshoreDis : false}
                        {...register("numberOfJobOffshore")}
                      />
                      {errors?.numberOfJobOffshore ? (
                        <span className="error-msg">
                          {errors?.numberOfJobOffshore?.message}
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                    {jobs &&
                      jobs.length > 0 &&
                      jobs?.map((child, i) => (
                        <>
                          {/* <h6 className="child-heading">
                            OFF-Shore Job #{i + 1}
                          </h6> */}
                          <div className="child-info">
                            <div className="box-grid-two">
                              <Form.Group
                                className={`form-group ${
                                  errors?.offshoreMainInfo &&
                                  errors?.offshoreMainInfo[`${i}`]
                                    ?.officialJobTitle
                                    ? "error-msg"
                                    : ""
                                }`}
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Official Job Title*</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="officialJobTitle"
                                  disabled={offshoreDis ? offshoreDis : false}
                                  defaultValue={child?.officialJobTitle}
                                  onChange={(e) => handleChange(e, i)}
                                />
                                {errors?.offshoreMainInfo &&
                                errors?.offshoreMainInfo[`${i}`]
                                  ?.officialJobTitle ? (
                                  <span className="error-msg">
                                    {
                                      errors?.offshoreMainInfo[`${i}`]
                                        ?.officialJobTitle?.message
                                    }
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Form.Group>
                              <Form.Group
                                className={`form-group ${
                                  errors?.offshoreMainInfo &&
                                  errors?.offshoreMainInfo[`${i}`]
                                    ?.jobDescription
                                    ? "error-msg"
                                    : ""
                                }`}
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Job Description*</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="jobDescription"
                                  placeholder="Example: dentistry, law, optometry..."
                                  disabled={offshoreDis ? offshoreDis : false}
                                  defaultValue={child?.jobDescription}
                                  onChange={(e) => handleChange(e, i)}
                                />
                                {errors?.offshoreMainInfo &&
                                errors?.offshoreMainInfo[`${i}`]
                                  ?.jobDescription ? (
                                  <span className="error-msg">
                                    {
                                      errors?.offshoreMainInfo[`${i}`]
                                        ?.jobDescription?.message
                                    }
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Form.Group>
                            </div>
                            <div className="box-grid-two">
                              <Form.Group
                                className={`form-group ${
                                  errors?.offshoreMainInfo &&
                                  errors?.offshoreMainInfo[`${i}`]
                                    ?.fullTimePosition
                                    ? "error-msg"
                                    : ""
                                }`}
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Full-Time Position?*</Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  name="fullTimePosition"
                                  disabled={offshoreDis ? offshoreDis : false}
                                  defaultValue={child?.fullTimePosition || ""}
                                  onChange={(e) => handleChange(e, i)}
                                >
                                  <option value={child?.fullTimePosition || ""}>
                                    {child?.fullTimePosition || "select"}
                                  </option>
                                  <option value="No">No</option>
                                  <option value="Yes">Yes</option>
                                </Form.Select>
                                {errors?.offshoreMainInfo &&
                                errors?.offshoreMainInfo[`${i}`]
                                  ?.fullTimePosition ? (
                                  <span className="error-msg">
                                    {
                                      errors?.offshoreMainInfo[`${i}`]
                                        ?.fullTimePosition?.message
                                    }
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Form.Group>

                              <Form.Group
                                className={`form-group ${
                                  errors?.offshoreMainInfo &&
                                  errors?.offshoreMainInfo[`${i}`]?.hoursPerWeek
                                    ? "error-msg"
                                    : ""
                                }`}
                                controlId="formBasicEmail"
                              >
                                <Form.Label>
                                  How many hours per week?*
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="hoursPerWeek"
                                  defaultValue={child?.hoursPerWeek}
                                  disabled={offshoreDis ? offshoreDis : false}
                                  onChange={(e) => handleChange(e, i)}
                                  placeholder=""
                                />
                                {errors?.offshoreMainInfo &&
                                errors?.offshoreMainInfo[`${i}`]
                                  ?.hoursPerWeek ? (
                                  <span className="error-msg">
                                    {
                                      errors?.offshoreMainInfo[`${i}`]
                                        ?.hoursPerWeek?.message
                                    }
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Form.Group>
                            </div>
                            <div className="box-grid-three">
                              <Form.Group
                                className={`form-group ${
                                  errors?.offshoreMainInfo &&
                                  errors?.offshoreMainInfo[`${i}`]
                                    ?.yearsExperience
                                    ? "error-msg"
                                    : ""
                                }`}
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Years of Experience*</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder=""
                                  name="yearsExperience"
                                  disabled={offshoreDis ? offshoreDis : false}
                                  defaultValue={child?.yearsExperience}
                                  onChange={(e) => handleChange(e, i)}
                                />
                                {errors?.offshoreMainInfo &&
                                errors?.offshoreMainInfo[`${i}`]
                                  ?.yearsExperience ? (
                                  <span className="error-msg">
                                    {
                                      errors?.offshoreMainInfo[`${i}`]
                                        ?.yearsExperience?.message
                                    }
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Form.Group>

                              <Form.Group
                                className={`form-group ${
                                  errors?.offshoreMainInfo &&
                                  errors?.offshoreMainInfo[`${i}`]?.jobStartDate
                                    ? "error-msg"
                                    : ""
                                }`}
                                controlId="formBasicEmail"
                              >
                                <Form.Label>
                                  Job Start Date (Month/Year)*
                                </Form.Label>
                                <DatePicker
                                  type="jobStartDate"
                                  name="jobStartDate"
                                  disabled={offshoreDis ? offshoreDis : false}
                                  placeholderText="Select Date"
                                  selected={
                                    child?.jobStartDate
                                      ? new Date(child?.jobStartDate)
                                      : new Date()
                                  }
                                  onChange={(date) =>
                                    handleJobStartDatePicker(
                                      date,
                                      i,
                                      "jobStartDate"
                                    )
                                  }
                                  className="form-select"
                                  dateFormat="dd-MM-yyyy"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                />
                                {errors?.offshoreMainInfo &&
                                errors?.offshoreMainInfo[`${i}`]
                                  ?.jobStartDate ? (
                                  <span className="error-msg">
                                    {
                                      errors?.offshoreMainInfo[`${i}`]
                                        ?.jobStartDate?.message
                                    }
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Form.Group>
                              <Form.Group
                                className={`form-group ${
                                  errors?.offshoreMainInfo &&
                                  errors?.offshoreMainInfo[`${i}`]?.jobEndTime
                                    ? "error-msg"
                                    : ""
                                }`}
                                controlId="formBasicEmail"
                              >
                                <Form.Label>
                                  Job End Date (Month/Year)*
                                </Form.Label>
                                <DatePicker
                                  type="jobEndTime"
                                  name="jobEndTime"
                                  disabled={offshoreDis ? offshoreDis : false}
                                  placeholderText="Select Date"
                                  selected={
                                    child?.jobEndTime
                                      ? new Date(child?.jobEndTime)
                                      : new Date()
                                  }
                                  onChange={(date) =>
                                    handleJobEndTimePicker(
                                      date,
                                      i,
                                      "jobEndTime"
                                    )
                                  }
                                  className="form-select"
                                  dateFormat="dd-MM-yyyy"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                />
                                {errors?.offshoreMainInfo &&
                                errors?.offshoreMainInfo[`${i}`]?.jobEndTime ? (
                                  <span className="error-msg">
                                    {
                                      errors?.offshoreMainInfo[`${i}`]
                                        ?.jobEndTime?.message
                                    }
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Form.Group>
                            </div>
                            <div className="add-btn">
                              {jobs && jobs.length === 1 ? (
                                <Button
                                  onClick={handleAddJob}
                                  disabled={offshoreDis ? offshoreDis : false}
                                >
                                  Add Job <i className="las la-plus"></i>
                                </Button>
                              ) : (
                                ""
                              )}
                              {i >= 0 && i !== 0 && (
                                <>
                                  <Button
                                    className="delete-btn"
                                    disabled={offshoreDis ? offshoreDis : false}
                                    onClick={() => removeAddJob(i)}
                                  >
                                    Delete Job
                                    <i class="las la-minus"></i>
                                  </Button>
                                  <Button
                                    onClick={handleAddJob}
                                    disabled={offshoreDis ? offshoreDis : false}
                                  >
                                    Add Job <i className="las la-plus"></i>
                                  </Button>
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                  </div>

                  <div className="inside-country">
                    <h6>ON-Shore (In Canada)</h6>
                    <Form.Group
                      className="form-group"
                      controlId="formBasicEmail"
                    >
                      <Form.Label>
                        Number of Jobs in the Past 10 Years
                      </Form.Label>
                      <Form.Control
                        type="number"
                        placeholder=""
                        name="numberOfJobOnshore"
                        disabled={onshoreDis ? onshoreDis : false}
                        defaultValue={
                          experienceInfo?.numberOfJobOnshore
                            ? experienceInfo?.numberOfJobOnshore
                            : experienceInfo?.sponsoredNumberOfJobOnshore
                        }
                        {...register("numberOfJobOnshore")}
                      />
                    </Form.Group>

                    {jobsOnshore &&
                      jobsOnshore?.length > 0 &&
                      jobsOnshore?.map((child, i) => (
                        <>
                          {/* <h6 className="child-heading">
                          ON-Shore Job #{i + 1}
                          </h6> */}
                          <div className="child-info">
                            <div className="box-grid-two">
                              <Form.Group
                                className="form-group"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Official Job Title</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="InCanOfficialJobTitle"
                                  disabled={onshoreDis ? onshoreDis : false}
                                  defaultValue={child?.InCanOfficialJobTitle}
                                  onChange={(e) => handleChangeInsideCan(e, i)}
                                  placeholder=""
                                />
                              </Form.Group>
                              <Form.Group
                                className="form-group"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Job Description</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder="Example: dentistry, law, optometry..."
                                  name="InCanJobDescription"
                                  disabled={onshoreDis ? onshoreDis : false}
                                  defaultValue={child?.InCanJobDescription}
                                  onChange={(e) => handleChangeInsideCan(e, i)}
                                />
                              </Form.Group>
                            </div>
                            <div className="box-grid-two">
                              <Form.Group
                                className="form-group"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Full-Time Position?</Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  name="InCanFullTimePosition"
                                  disabled={onshoreDis ? onshoreDis : false}
                                  defaultValue={
                                    child?.InCanFullTimePosition
                                      ? child?.InCanFullTimePosition
                                      : ""
                                  }
                                  onChange={(e) => handleChangeInsideCan(e, i)}
                                >
                                  <option value="">Select</option>
                                  <option value="Yes">Yes</option>
                                  <option value="No">No</option>
                                </Form.Select>
                              </Form.Group>

                              <Form.Group
                                className="form-group"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>
                                  How many hours per week?
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="InCanHoursPerWeek"
                                  disabled={onshoreDis ? onshoreDis : false}
                                  defaultValue={child?.InCanHoursPerWeek}
                                  onChange={(e) => handleChangeInsideCan(e, i)}
                                  placeholder=""
                                />
                              </Form.Group>
                            </div>
                            <div className="box-grid-three">
                              <Form.Group
                                className="form-group"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Years of Experience</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder=""
                                  name="InCanYearsExperience"
                                  disabled={onshoreDis ? onshoreDis : false}
                                  defaultValue={child?.InCanYearsExperience}
                                  onChange={(e) => handleChangeInsideCan(e, i)}
                                />
                              </Form.Group>

                              <Form.Group
                                className="form-group"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>
                                  Job Start Date (Month/Year)
                                </Form.Label>
                                <DatePicker
                                  type="InCanJobStartDate"
                                  name="InCanJobStartDate"
                                  placeholderText="Select Date"
                                  disabled={onshoreDis ? onshoreDis : false}
                                  selected={
                                    child?.InCanJobStartDate
                                      ? new Date(child?.InCanJobStartDate)
                                      : new Date()
                                  }
                                  onChange={(date) =>
                                    handleJobStartDatePicker(
                                      date,
                                      i,
                                      "InCanJobStartDate"
                                    )
                                  }
                                  className="form-select"
                                  dateFormat="dd-MM-yyyy"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                />
                              </Form.Group>
                              <Form.Group
                                className="form-group"
                                controlId="formBasicEmail"
                              >
                                <Form.Label>
                                  Job End Date (Month/Year)
                                </Form.Label>
                                <DatePicker
                                  type="InCanJobEndTime"
                                  name="InCanJobEndTime"
                                  disabled={onshoreDis ? onshoreDis : false}
                                  placeholderText="Select Date"
                                  selected={
                                    child?.InCanJobEndTime
                                      ? new Date(child?.InCanJobEndTime)
                                      : new Date()
                                  }
                                  onChange={(date) =>
                                    handleJobEndTimePicker(
                                      date,
                                      i,
                                      "InCanJobEndTime"
                                    )
                                  }
                                  className="form-select"
                                  dateFormat="dd-MM-yyyy"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                />
                              </Form.Group>
                            </div>
                            <div className="add-btn">
                              {jobsOnshore && jobsOnshore?.length === 1 ? (
                                <Button
                                  onClick={handleAddJobOnshore}
                                  disabled={onshoreDis ? onshoreDis : false}
                                >
                                  Add Job <i className="las la-plus"></i>
                                </Button>
                              ) : (
                                ""
                              )}
                              {i >= 0 && i !== 0 && (
                                <>
                                  <Button
                                    className="delete-btn"
                                    disabled={onshoreDis ? onshoreDis : false}
                                    onClick={() => removeAddJobOnshore(i)}
                                  >
                                    Delete Job
                                    <i class="las la-minus"></i>
                                  </Button>
                                  <Button
                                    onClick={handleAddJobOnshore}
                                    disabled={onshoreDis ? onshoreDis : false}
                                  >
                                    Add Job <i className="las la-plus"></i>
                                  </Button>
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      ))}
                  </div>
                  {/*----------------- Here add extra field start------------------*/}
                  <ExperienceExtraFields
                    onshoreDis={onshoreDis}
                    experienceInfo={experienceInfo}
                    register={register}
                    watch={watch}
                    errors={errors}
                    setValue={setValue}
                  />
                  {/*----------------- Here add extra field end------------------*/}
                </Accordion.Body>
              </Accordion.Item>
            </div>
            <div
              className={`accordion-list ${
                spouseFormState === "open" ? "change-accordion-icon" : ""
              }`}
            >
              <Card>
                <Card.Header
                  className={customAccordBtn === "1" ? "accord-open" : ""}
                  onClick={() =>
                    spouseFormState === "open" || spouseOffshoreDis
                      ? handleClose("spouseFormState", "work-1")
                      : ""
                  }
                >
                  <div className="accordian-title">
                    {textSpouseMessage
                      ? textSpouseMessage
                      : spouseFormState === "open" || spouseOffshoreDis
                      ? "Spouse Information"
                      : "Add Spouse Information"}
                    {spouseFormState === "open" &&
                      showDeleteIcon &&
                      !spouseOffshoreDis && (
                        <div className="delete-btn">
                          <Button
                            onClick={() =>
                              handleDelete("spouseFormState", "work-1")
                            }
                          >
                            Delete <i className="las la-minus"></i>
                          </Button>
                        </div>
                      )}
                  </div>
                  <CustomToggle eventKey="1">
                    {spouseFormState === "plus" && !spouseOffshoreDis ? (
                      <img src={addIcon} alt="Add Icon" />
                    ) : (spouseFormState === "open" && !spouseOffshoreDis) ||
                      (spouseFormState === "open" && spouseOffshoreDis) ||
                      (spouseFormState === "plus" && spouseOffshoreDis) ||
                      (spouseFormState === "plus" && !spouseOffshoreDis) ? (
                      <img
                        className="arrow-icon"
                        src={customAccordBtn !== "1" ? closeArrow : activeArrow}
                        alt="Icon"
                      />
                    ) : (
                      ""
                    )}
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <div className="outside-country">
                      <h6>OFF-Shore (Outside Canada)</h6>
                      <Form.Group
                        className={`form-group ${
                          errors?.sNumberOfJobOffshore?.message
                            ? "error-msg"
                            : ""
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>
                          Number of Jobs in the Past 10 Years*
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="sNumberOfJobOffshore"
                          disabled={
                            spouseOffshoreDis ? spouseOffshoreDis : false
                          }
                          defaultValue={
                            experienceInfo?.sNumberOfJobOffshore
                              ? experienceInfo?.sNumberOfJobOffshore
                              : experienceInfo?.sponsoredSpouseNumberOfJobOffshore
                          }
                          {...register("sNumberOfJobOffshore")}
                        />
                        {errors?.sNumberOfJobOffshore ? (
                          <span className="error-msg">
                            {errors?.sNumberOfJobOffshore?.message}
                          </span>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                      {spouseJobsOffshore &&
                        spouseJobsOffshore.length > 0 &&
                        spouseJobsOffshore.map((child, i) => (
                          <>
                            {/* <h6 className="child-heading">OFF-Shore Job</h6> */}
                            <div className="child-info">
                              <div className="box-grid-two">
                                <Form.Group
                                  className={`form-group ${
                                    errors?.spouseOffshoreInfo &&
                                    errors?.spouseOffshoreInfo[`${i}`]
                                      ?.sOfficialJobTitle
                                      ? "error-msg"
                                      : ""
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Official Job Title*</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="sOfficialJobTitle"
                                    disabled={
                                      spouseOffshoreDis
                                        ? spouseOffshoreDis
                                        : false
                                    }
                                    defaultValue={child?.sOfficialJobTitle}
                                    onChange={(e) =>
                                      handleSpouseOffshoreChange(e, i)
                                    }
                                    placeholder=""
                                  />
                                  {errors?.spouseOffshoreInfo &&
                                  errors?.spouseOffshoreInfo[`${i}`]
                                    ?.sOfficialJobTitle ? (
                                    <span className="error-msg">
                                      {
                                        errors?.spouseOffshoreInfo[`${i}`]
                                          ?.sOfficialJobTitle?.message
                                      }
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </Form.Group>
                                <Form.Group
                                  className={`form-group ${
                                    errors?.spouseOffshoreInfo &&
                                    errors?.spouseOffshoreInfo[`${i}`]
                                      ?.sJobDescription
                                      ? "error-msg"
                                      : ""
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Job Description*</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="sJobDescription"
                                    disabled={
                                      spouseOffshoreDis
                                        ? spouseOffshoreDis
                                        : false
                                    }
                                    defaultValue={child?.sJobDescription}
                                    onChange={(e) =>
                                      handleSpouseOffshoreChange(e, i)
                                    }
                                    placeholder="Example: dentistry, law, optometry..."
                                  />
                                  {errors?.spouseOffshoreInfo &&
                                  errors?.spouseOffshoreInfo[`${i}`]
                                    ?.sJobDescription ? (
                                    <span className="error-msg">
                                      {
                                        errors?.spouseOffshoreInfo[`${i}`]
                                          ?.sJobDescription?.message
                                      }
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </Form.Group>
                              </div>
                              <div className="box-grid-two">
                                <Form.Group
                                  className={`form-group ${
                                    errors?.spouseOffshoreInfo &&
                                    errors?.spouseOffshoreInfo[`${i}`]
                                      ?.sFullTimePosition
                                      ? "error-msg"
                                      : ""
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Full-Time Position?*</Form.Label>
                                  <Form.Select
                                    aria-label="Default select example"
                                    name="sFullTimePosition"
                                    disabled={
                                      spouseOffshoreDis
                                        ? spouseOffshoreDis
                                        : false
                                    }
                                    defaultValue={
                                      child?.sFullTimePosition
                                        ? child?.sFullTimePosition
                                        : ""
                                    }
                                    onChange={(e) =>
                                      handleSpouseOffshoreChange(e, i)
                                    }
                                  >
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </Form.Select>
                                  {errors?.spouseOffshoreInfo &&
                                  errors?.spouseOffshoreInfo[`${i}`]
                                    ?.sFullTimePosition ? (
                                    <span className="error-msg">
                                      {
                                        errors?.spouseOffshoreInfo[`${i}`]
                                          ?.sFullTimePosition?.message
                                      }
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </Form.Group>

                                <Form.Group
                                  className={`form-group ${
                                    errors?.spouseOffshoreInfo &&
                                    errors?.spouseOffshoreInfo[`${i}`]
                                      ?.sHoursPerWeek
                                      ? "error-msg"
                                      : ""
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    How many hours per week?*
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="sHoursPerWeek"
                                    disabled={
                                      spouseOffshoreDis
                                        ? spouseOffshoreDis
                                        : false
                                    }
                                    defaultValue={child?.sHoursPerWeek}
                                    onChange={(e) =>
                                      handleSpouseOffshoreChange(e, i)
                                    }
                                    placeholder=""
                                  />
                                  {errors?.spouseOffshoreInfo &&
                                  errors?.spouseOffshoreInfo[`${i}`]
                                    ?.sHoursPerWeek ? (
                                    <span className="error-msg">
                                      {
                                        errors?.spouseOffshoreInfo[`${i}`]
                                          ?.sHoursPerWeek?.message
                                      }
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </Form.Group>
                              </div>
                              <div className="box-grid-three">
                                <Form.Group
                                  className={`form-group ${
                                    errors?.spouseOffshoreInfo &&
                                    errors?.spouseOffshoreInfo[`${i}`]
                                      ?.sYearsExperience
                                      ? "error-msg"
                                      : ""
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Years of Experience*</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    name="sYearsExperience"
                                    defaultValue={child?.sYearsExperience}
                                    disabled={
                                      spouseOffshoreDis
                                        ? spouseOffshoreDis
                                        : false
                                    }
                                    onChange={(e) =>
                                      handleSpouseOffshoreChange(e, i)
                                    }
                                  />
                                  {errors?.spouseOffshoreInfo &&
                                  errors?.spouseOffshoreInfo[`${i}`]
                                    ?.sYearsExperience ? (
                                    <span className="error-msg">
                                      {
                                        errors?.spouseOffshoreInfo[`${i}`]
                                          ?.sYearsExperience?.message
                                      }
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </Form.Group>

                                <Form.Group
                                  className={`form-group ${
                                    errors?.spouseOffshoreInfo &&
                                    errors?.spouseOffshoreInfo[`${i}`]
                                      ?.sJobStartDate
                                      ? "error-msg"
                                      : ""
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    Job Start Date (Month/Year)*
                                  </Form.Label>
                                  <DatePicker
                                    type="sJobStartDate"
                                    name="sJobStartDate"
                                    placeholderText="Select Date"
                                    //selected={child.jobStartDate}
                                    disabled={
                                      spouseOffshoreDis
                                        ? spouseOffshoreDis
                                        : false
                                    }
                                    selected={
                                      child?.sJobStartDate
                                        ? new Date(child?.sJobStartDate)
                                        : new Date()
                                    }
                                    onChange={(date) =>
                                      handleJobStartDatePicker(
                                        date,
                                        i,
                                        "spouseOffshoreStartDate"
                                      )
                                    }
                                    className="form-select"
                                    dateFormat="dd-MM-yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                  />
                                  {errors?.spouseOffshoreInfo &&
                                  errors?.spouseOffshoreInfo[`${i}`]
                                    ?.sJobStartDate ? (
                                    <span className="error-msg">
                                      {
                                        errors?.spouseOffshoreInfo[`${i}`]
                                          ?.sJobStartDate?.message
                                      }
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </Form.Group>
                                <Form.Group
                                  className={`form-group ${
                                    errors?.spouseOffshoreInfo &&
                                    errors?.spouseOffshoreInfo[`${i}`]
                                      ?.sJobEndTime
                                      ? "error-msg"
                                      : ""
                                  }`}
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    Job End Date (Month/Year)*
                                  </Form.Label>
                                  <DatePicker
                                    type="sJobEndTime"
                                    name="sJobEndTime"
                                    placeholderText="Select Date"
                                    disabled={
                                      spouseOffshoreDis
                                        ? spouseOffshoreDis
                                        : false
                                    }
                                    selected={
                                      child?.sJobEndTime
                                        ? new Date(child?.sJobEndTime)
                                        : new Date()
                                    }
                                    onChange={(date) =>
                                      handleJobEndTimePicker(
                                        date,
                                        i,
                                        "spouseOffshoreJobEndTime"
                                      )
                                    }
                                    className="form-select"
                                    dateFormat="dd-MM-yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                  />
                                  {errors?.spouseOffshoreInfo &&
                                  errors?.spouseOffshoreInfo[`${i}`]
                                    ?.sJobEndTime ? (
                                    <span className="error-msg">
                                      {
                                        errors?.spouseOffshoreInfo[`${i}`]
                                          ?.sJobEndTime?.message
                                      }
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </Form.Group>
                              </div>
                              <div className="add-btn">
                                {spouseJobsOffshore &&
                                spouseJobsOffshore.length === 1 ? (
                                  <Button
                                    onClick={handleAddSpouseOffshoreJob}
                                    disabled={
                                      spouseOffshoreDis
                                        ? spouseOffshoreDis
                                        : false
                                    }
                                  >
                                    Add Job <i className="las la-plus"></i>
                                  </Button>
                                ) : (
                                  ""
                                )}
                                {i >= 0 && i !== 0 && (
                                  <>
                                    <Button
                                      className="delete-btn"
                                      disabled={
                                        spouseOffshoreDis
                                          ? spouseOffshoreDis
                                          : false
                                      }
                                      onClick={() =>
                                        removeAddSpouseOffshoreJob(i)
                                      }
                                    >
                                      Delete Job #{i + 1}{" "}
                                      <i class="las la-minus"></i>
                                    </Button>
                                    <Button
                                      onClick={handleAddSpouseOffshoreJob}
                                      disabled={
                                        spouseOffshoreDis
                                          ? spouseOffshoreDis
                                          : false
                                      }
                                    >
                                      Add Job <i className="las la-plus"></i>
                                    </Button>
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        ))}
                    </div>

                    <div className="inside-country">
                      <h6>ON-Shore (In Canada)</h6>
                      <Form.Group
                        className="form-group"
                        controlId="formBasicEmail"
                      >
                        <Form.Label>
                          Number of Jobs in the Past 10 Years
                        </Form.Label>
                        <Form.Control
                          type="number"
                          placeholder=""
                          name="sNumberOfJobOnshore"
                          disabled={spouseOnshoreDis ? spouseOnshoreDis : false}
                          defaultValue={
                            experienceInfo?.sNumberOfJobOnshore
                              ? experienceInfo?.sNumberOfJobOnshore
                              : experienceInfo?.sponsoredSpouseNumberOfJobOnshore
                          }
                          {...register("sNumberOfJobOnshore")}
                        />
                      </Form.Group>
                      {spouseJobsOnshore &&
                        spouseJobsOnshore?.length > 0 &&
                        spouseJobsOnshore.map((child, i) => (
                          <>
                            {/* <h6 className="child-heading">ON-Shore Job</h6> */}
                            <div className="child-info">
                              <div className="box-grid-two">
                                <Form.Group
                                  className="form-group"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Official Job Title</Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="InCanOfficialJobTitle"
                                    disabled={
                                      spouseOnshoreDis
                                        ? spouseOnshoreDis
                                        : false
                                    }
                                    defaultValue={child?.InCanOfficialJobTitle}
                                    onChange={(e) =>
                                      handleSpouseOnshoreChange(e, i)
                                    }
                                    placeholder=""
                                  />
                                </Form.Group>
                                <Form.Group
                                  className="form-group"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Job Description</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder="Example: dentistry, law, optometry..."
                                    name="InCanJobDescription"
                                    disabled={
                                      spouseOnshoreDis
                                        ? spouseOnshoreDis
                                        : false
                                    }
                                    defaultValue={child?.InCanJobDescription}
                                    onChange={(e) =>
                                      handleSpouseOnshoreChange(e, i)
                                    }
                                  />
                                </Form.Group>
                              </div>
                              <div className="box-grid-two">
                                <Form.Group
                                  className="form-group"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Full-Time Position?</Form.Label>
                                  <Form.Select
                                    aria-label="Default select example"
                                    name="InCanFullTimePosition"
                                    disabled={
                                      spouseOnshoreDis
                                        ? spouseOnshoreDis
                                        : false
                                    }
                                    defaultValue={
                                      child?.InCanFullTimePosition
                                        ? child?.InCanFullTimePosition
                                        : ""
                                    }
                                    onChange={(e) =>
                                      handleSpouseOnshoreChange(e, i)
                                    }
                                  >
                                    <option value="">Select</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                  </Form.Select>
                                </Form.Group>

                                <Form.Group
                                  className="form-group"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    How many hours per week?
                                  </Form.Label>
                                  <Form.Control
                                    type="text"
                                    name="InCanHoursPerWeek"
                                    disabled={
                                      spouseOnshoreDis
                                        ? spouseOnshoreDis
                                        : false
                                    }
                                    defaultValue={child?.InCanHoursPerWeek}
                                    onChange={(e) =>
                                      handleSpouseOnshoreChange(e, i)
                                    }
                                    placeholder=""
                                  />
                                </Form.Group>
                              </div>
                              <div className="box-grid-three">
                                <Form.Group
                                  className="form-group"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>Years of Experience</Form.Label>
                                  <Form.Control
                                    type="text"
                                    placeholder=""
                                    name="InCanYearsExperience"
                                    disabled={
                                      spouseOnshoreDis
                                        ? spouseOnshoreDis
                                        : false
                                    }
                                    defaultValue={child?.InCanYearsExperience}
                                    onChange={(e) =>
                                      handleSpouseOnshoreChange(e, i)
                                    }
                                  />
                                </Form.Group>

                                <Form.Group
                                  className="form-group"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    Job Start Date (Month/Year)
                                  </Form.Label>
                                  <DatePicker
                                    type="InCanJobStartDate"
                                    name="InCanJobStartDate"
                                    placeholderText="Select Date"
                                    // selected={child.InCanJobStartDate}
                                    disabled={
                                      spouseOnshoreDis
                                        ? spouseOnshoreDis
                                        : false
                                    }
                                    selected={
                                      child?.InCanJobStartDate
                                        ? new Date(child?.InCanJobStartDate)
                                        : new Date()
                                    }
                                    onChange={(date) =>
                                      handleJobStartDatePicker(
                                        date,
                                        i,
                                        "spouseOnshoreInCanJobStartDate"
                                      )
                                    }
                                    className="form-select"
                                    dateFormat="dd-MM-yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                  />
                                </Form.Group>
                                <Form.Group
                                  className="form-group"
                                  controlId="formBasicEmail"
                                >
                                  <Form.Label>
                                    Job End Date (Month/Year)
                                  </Form.Label>
                                  <DatePicker
                                    type="InCanJobEndTime"
                                    name="InCanJobEndTime"
                                    placeholderText="Select Date"
                                    disabled={
                                      spouseOnshoreDis
                                        ? spouseOnshoreDis
                                        : false
                                    }
                                    selected={
                                      child?.InCanJobEndTime
                                        ? new Date(child?.InCanJobEndTime)
                                        : new Date()
                                    }
                                    onChange={(date) =>
                                      handleJobEndTimePicker(
                                        date,
                                        i,
                                        "spouseOnshoreInCanJobEndTime"
                                      )
                                    }
                                    className="form-select"
                                    dateFormat="dd-MM-yyyy"
                                    peekNextMonth
                                    showMonthDropdown
                                    showYearDropdown
                                    dropdownMode="select"
                                  />
                                </Form.Group>
                              </div>
                              <div className="add-btn">
                                {spouseJobsOnshore &&
                                spouseJobsOnshore.length === 1 ? (
                                  <Button
                                    onClick={handleAddSpouseJob}
                                    disabled={
                                      spouseOnshoreDis
                                        ? spouseOnshoreDis
                                        : false
                                    }
                                  >
                                    Add Job <i className="las la-plus"></i>
                                  </Button>
                                ) : (
                                  ""
                                )}
                                {i >= 0 && i !== 0 && (
                                  <>
                                    <Button
                                      className="delete-btn"
                                      disabled={
                                        spouseOnshoreDis
                                          ? spouseOnshoreDis
                                          : false
                                      }
                                      onClick={() => removeAddSpouseJob(i)}
                                    >
                                      Delete Job
                                      <i class="las la-minus"></i>
                                    </Button>
                                    <Button
                                      onClick={handleAddSpouseJob}
                                      disabled={
                                        spouseOnshoreDis
                                          ? spouseOnshoreDis
                                          : false
                                      }
                                    >
                                      Add Job <i className="las la-plus"></i>
                                    </Button>
                                  </>
                                )}
                              </div>
                            </div>
                          </>
                        ))}
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </div>
          </Accordion>
        ) : (
          ""
        )}

        <div className="visa-btn">
          {sponsoringShow ? (
            !offshoreDis ? (
              <Button
                type="submit"
                disabled={offshoreDis ? offshoreDis : false}
                className={`${offshoreDis ? "success-btn" : ""}`}
              >
                Save {offshoreDis && <img src={approved} alt="approved" />}
              </Button>
            ) : (
              <div className="for-new-changes">
                <p>
                  <span>Important! </span> Please contact our Support team if
                  you would like to make new changes .
                </p>
              </div>
            )
          ) : !offshoreDis ? (
            <Button
              type="submit"
              disabled={offshoreDis ? offshoreDis : false}
              className={`${offshoreDis ? "success-btn" : ""}`}
            >
              Save {offshoreDis && <img src={approved} alt="approved" />}
            </Button>
          ) : (
            <div className="for-new-changes">
              <p>
                <span>Important! </span> Please contact our Support team if you
                would like to make new changes .
              </p>
            </div>
          )}
          {!offshoreDis ? (
            <div className="visa-desc-text">
              <p>
                Once your form is completed, you will be able to make new
                changes by contacting our Support team.
              </p>
              <p>When you're ready, click "Submit"!</p>
            </div>
          ) : (
            ""
          )}
        </div>
      </Form>
      <ErrorModal show={showErrorModal} handleClose={handleShowErrorModal} />
    </div>
  );
};
export default WorkExperience;
