/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from "react";
import ApplicationLayout from "../myapplication/layout";
import { Tab, Tabs, Button } from "react-bootstrap";
import PersonalInfo from "./personalInfo";
import "./index.css";
import EducationInfo from "./educationInfo";
import WorkExperience from "./workExperience";
import LanguagesSection from "./languagesSection";
import Documents from "./documents";
import UserContext from "../../context/UserContext";
import { serverPath } from "../../config/key";
import axios from "axios";
import lock from "../../assets/images/visa/lock.svg";
import { useNavigate, useParams } from "react-router-dom";
import AdaptabilityInfo from "./adaptabilityInfo";
import BusinessInfo from "./businessInfo";
import Loader from "../loader/loader";

const VisaForm = () => {
  const { formType } = useParams();

  const [openVisaApplication, setOpenVisaApplication] = useState(false);
  ///const [visaDetail, setVisaDetail] = useState({})
  const { user, selectedVisa, setSavePercentage } = useContext(UserContext);
  const [openForm, setOpenForm] = useState([]);
  const [showSelectedMessage, setShowSelectMessage] = useState(false);
  const [personalInformation, setPersonalInfo] = useState();
  const [educationInfo, setEducationInfo] = useState();
  const [experienceInfo, setExperienceInfo] = useState();
  const [LanguageInfo, setLanguageInfo] = useState();
  const [documentInfo, setDocumentInfo] = useState();
  const [saveCount, setSaveCount] = useState(0);
  const [formCount, setFormCount] = useState(0);
  const [sponsorForms, setSponsorForms] = useState([]);
  const [showFormTab, setShowFormTab] = useState([]);
  const [sponsoredInfo, setSponsoredInfo] = useState();
  const [adaptabilityInfo, setAdaptabilityInfo] = useState();
  const [businessInfo, setBusinessInfo] = useState();
  const [visaFormData, setVisaFormData] = useState();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [activeTabKey, setActiveTabKey] = useState("personalInfo");

  const checkProVisaType = async () => {
    setPersonalInfo();
    setExperienceInfo();
    setOpenForm([]);
    setShowFormTab([]);
    setSponsorForms([]);
    setVisaFormData();
    setSaveCount(0);
    setSavePercentage(0);
    setFormCount(0);
    setLoading(true);
    if (
      selectedVisa?.visaId &&
      selectedVisa?.invoiceId &&
      !selectedVisa?.isEvaluation
    ) {
      const res = await axios.get(
        `${serverPath}/product/checkProductVisaType`,
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
          params: selectedVisa,
        }
      );
      setLoading(false);
      const { visaData } = res?.data?.visaData || {};
      setVisaFormData(res.data.visaData);
      const { visaType } = visaData?.productIds[0] || {};

      if (visaData?.productIds?.length && visaType?.forms?.length) {
        setFormCount(visaType?.forms?.length);
        setOpenForm(visaType?.forms);
        navigate("/application/main");
      }
      if (visaData?.productIds?.length && visaType?.formsSponsor?.length) {
        setSponsorForms(visaType?.formsSponsor);
        if (formType === "main") {
          navigate("/application/main");
        }
      }
    } else {
      navigate("/evaluation");
    }
  };

  useEffect(() => {
    if (formType === "sponsoring") {
      setShowFormTab(sponsorForms);
      let count = [];
      if (visaFormData && visaFormData?.personalSponsoringInfo?.isLocked) {
        count.push(1);
      }
      setPersonalInfo(visaFormData?.personalSponsoringInfo);
      if (
        visaFormData &&
        visaFormData?.workExperienceSponsoringInfo?.isLocked
      ) {
        count.push(2);
      }
      if (visaFormData && visaFormData?.documentSponsoringInfo?.isLocked) {
        count.push(3);
      }
      setDocumentInfo(visaFormData?.documentSponsoringInfo);
      setExperienceInfo(visaFormData?.workExperienceSponsoringInfo);
      setSaveCount(count?.length);
    } else if (formType === "sponsored") {
      setShowFormTab(openForm);
      let count = [];
      if (visaFormData && visaFormData?.personalSponsoredInfo?.isLocked) {
        count.push(1);
      }
      setPersonalInfo(visaFormData?.personalSponsoredInfo);
      if (visaFormData && visaFormData?.workExperienceSponsoredInfo?.isLocked) {
        count.push(2);
      }
      setExperienceInfo(visaFormData?.workExperienceSponsoredInfo);
      if (visaFormData && visaFormData?.documentSponsoredInfo?.isLocked) {
        count.push(3);
      }
      setEducationInfo(visaFormData?.sponsoredEducationInfo);
      if (visaFormData && visaFormData?.sponsoredEducationInfo?.isLocked) {
        count.push(4);
      }
      setDocumentInfo(visaFormData?.documentSponsoredInfo);
      setSaveCount(count?.length);
    } else {
      setShowFormTab(openForm?.length > 0 ? openForm : sponsorForms);
      let count = [];
      if (visaFormData && visaFormData?.personal?.isLocked) {
        count.push(1);
      }
      setPersonalInfo(visaFormData?.personal);
      if (visaFormData && visaFormData?.education?.isLocked) {
        count.push(2);
      }
      setEducationInfo(visaFormData?.education);
      if (visaFormData && visaFormData?.Experience?.isLocked) {
        count.push(3);
      }
      setExperienceInfo(visaFormData?.Experience);

      if (visaFormData && visaFormData?.language?.isLocked) {
        count.push(4);
      }
      setLanguageInfo(visaFormData?.language);
      if (visaFormData && visaFormData?.document?.isLocked) {
        count.push(5);
      }
      setDocumentInfo(visaFormData?.document);
      if (visaFormData && visaFormData?.adaptability?.isLocked) {
        count.push(6);
      }
      setAdaptabilityInfo(visaFormData?.adaptability);
      if (visaFormData && visaFormData?.business?.isLocked) {
        count.push(7);
      }
      setBusinessInfo(visaFormData?.business);
      setSaveCount(count?.length);
    }
    setActiveTabKey(activeTabKey);
  }, [formType, openForm, sponsorForms]);
  useEffect(() => {
    if (saveCount && formCount) {
      const per = (Number(saveCount) / Number(formCount)) * 100;
      setSavePercentage(per);
    }
  }, [
    selectedVisa,
    openForm,
    personalInformation,
    educationInfo,
    experienceInfo,
    LanguageInfo,
    documentInfo,
    saveCount,
    formCount,
  ]);

  useEffect(() => {
    if (selectedVisa?.invoiceId) {
      setOpenVisaApplication(false);
      setShowSelectMessage(false);
      setPersonalInfo();
      checkProVisaType();
    } else if (selectedVisa?.visaId) {
      setShowSelectMessage(false);
      setOpenVisaApplication(true);
    } else {
      setShowSelectMessage(true);
    }
  }, [selectedVisa, formType]);
  const purchasePackage = async () => {
    const providerId = "62e9117ccc0c0af330f7c40c";
    const res1 = await axios.get(`${serverPath}/product/visaProductByVisaId`, {
      headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
      params: { visaId: selectedVisa.visaId },
    });
    const { productData } = res1?.data || {};
    const res = await axios.get(
      `${serverPath}/payment-provider/supportPayment/${productData._id}/${user._id}/${providerId}`
    );
    if (res?.data) {
      window.location.href = res?.data?.url;
    }
  };

  const capitalize = (strg) => {
    let str2 = "";
    strg.split("").forEach((el, index) => {
      if (index !== 0 && el === el.toUpperCase()) {
        str2 += ` ${el}`;
      } else {
        str2 += el;
      }
    });
    return str2.charAt(0).toUpperCase() + str2.slice(1);
  };
  const getFromTab = (tab) => {
    switch (tab) {
      case "personalInfo":
        return (
          <PersonalInfo
            personalInformation={personalInformation}
            setPersonalInfo={setPersonalInfo}
            setSponsoredInfo={setSponsoredInfo}
            sponsoredInfo={sponsoredInfo}
          />
        );
      case "education":
        return (
          <EducationInfo
            educationInfo={educationInfo}
            setEducationInfo={setEducationInfo}
          />
        );
      case "workExperience":
        return (
          <WorkExperience
            experienceInfo={experienceInfo}
            setExperienceInfo={setExperienceInfo}
          />
        );
      case "language":
        return (
          <LanguagesSection
            LanguageInfo={LanguageInfo}
            setLanguageInfo={setLanguageInfo}
          />
        );
      case "documents":
        return (
          <Documents
            documentInfo={documentInfo}
            setDocumentInfo={setDocumentInfo}
          />
        );
      case "adaptability":
        return (
          <AdaptabilityInfo
            adaptabilityInfo={adaptabilityInfo}
            setAdaptabilityInfo={setAdaptabilityInfo}
          />
        );
      case "business":
        return (
          <BusinessInfo
            businessInfo={businessInfo}
            setBusinessInfo={setBusinessInfo}
          />
        );
      default:
        return "";
    }
  };

  const getDynamicClass = (tab) => {
    switch (tab) {
      case "personalInfo":
        return personalInformation && personalInformation?.isLocked
          ? true
          : personalInformation?.sponsoredInformation &&
            personalInformation?.formsType === formType &&
            personalInformation?.isLocked
          ? true
          : false;
      case "education":
        return educationInfo && educationInfo?.isLocked ? true : false;
      case "workExperience":
        return experienceInfo && experienceInfo?.isLocked ? true : false;
      case "language":
        return LanguageInfo && LanguageInfo?.isLocked ? true : false;
      case "documents":
        return documentInfo && documentInfo?.isLocked ? true : false;
      case "adaptability":
        return adaptabilityInfo && adaptabilityInfo?.isLocked ? true : false;
      case "business":
        return businessInfo && businessInfo?.isLocked ? true : false;
      default:
        return "";
    }
  };

  const handleTabChange = (e) => {
    setActiveTabKey(e);
  };
  return (
    <ApplicationLayout>
      {openVisaApplication ? (
        <>
          <h2>
            In order to start your visa application please purchase a package
            first
          </h2>
          <Button onClick={purchasePackage}>Buy</Button>
        </>
      ) : showSelectedMessage ? (
        <div className="select-visatype">
          <h2>Please Select visa type</h2>
        </div>
      ) : (
        <div className="visa-form-page">
          <div className="container">
            <div className="home-visa-tab">
              {loading ? (
                <Loader />
              ) : (
                <Tabs
                  defaultActiveKey={"personalInfo"}
                  activeKey={activeTabKey}
                  onSelect={handleTabChange}
                >
                  {showFormTab &&
                    showFormTab?.length > 0 &&
                    showFormTab?.map((el) => (
                      <Tab
                        eventKey={el}
                        title={
                          <span className={getDynamicClass(el) && "locked-tab"}>
                            {capitalize(el)}
                            {getDynamicClass(el) && (
                              <img src={lock} alt="lock" />
                            )}
                          </span>
                        }
                      >
                        {getFromTab(el)}
                      </Tab>
                    ))}
                </Tabs>
              )}
            </div>
          </div>
        </div>
      )}
    </ApplicationLayout>
  );
};
export default VisaForm;
