export const partnerSpouseVisaSponsoringGen = [
  {
    name: 'sponsoringFrontPassportCopy',
    label: 'Canadian Passport Copy',
    secondLabel: ' (front page + stamp / visa pages if any)',
    isRequired: true,
  },
  {
    name: 'sponsoringPermanentResidenceCard',
    label: 'Copy of Permanent Residence Card',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'sponsoringBirthCertificate',
    label: 'Copy of Birth Certificate',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'sponsoringMarriageCertificate',
    label: 'Marriage Certificate',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'sponsoringCommonLawDeclaration',
    label: 'Common-Law Declaration',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
]
export const partnerSpouseVisaSponsoringFinancialDoc = [
  {
    name: 'sponsoringEmpReferenceLetter',
    label: 'Employment Reference Letter',
    secondLabel: ' (if applicable)',
    isRequired: false,
  },
  {
    name: 'sponsoringBankStatement',
    label: 'Bank Statement',
    secondLabel: '(showing your joint bank account)',
    isRequired: true,
  },
]
export const partnerSpouseVisaSponsoringDependents = [
  {
    name: 'sponsoringFrontPassportCopy',
    label: 'Passport Copy',
    secondLabel: '(front page + stamp / visa pages if any)',
    isRequired: true,
  },
  {
    name: 'sponsoringDigitalPhoto',
    label: 'Digital Photo',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'sponsoringBirthCertificate',
    label: 'Copy of Birth Certificate',
    secondLabel: '',
    isRequired: true,
  },
]
export const partner_parentSpouseVisaRelationship = [
  {
    name: 'loveLetter',
    label: 'Love Letter',
    secondLabel: '(the sponsoring parent)',
    isRequired: true,
  },
  {
    name: 'vocationPhoto1',
    label: 'Vacation Photo 1',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'vocationPhoto2',
    label: 'Vacation Photo 2',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'vocationPhoto3',
    label: 'Vacation Photo 3',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'cohabitationScreenshot',
    label: 'Cohabitation – Screenshot of communications',
    secondLabel: '(such as emails, SMS, WhatsApp, phone calls)',
    isRequired: true,
  },
  {
    name: 'flightTickets',
    label: 'Flight tickets',
    secondLabel: '(to any location)',
    isRequired: true,
  },
]
