/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios'
import { useContext } from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { serverPath } from '../../config/key'
import UserContext from '../../context/UserContext'
import { toast } from 'react-toastify'
import approved from '../../assets/images/header/approved.svg'
const AdaptabilityInfo = ({
  adaptabilityInfo,
  setAdaptabilityInfo,
  adminFormDisable,
}) => {
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('t')
  const [adaptabilityDis, setAdaptabilityDis] = useState(false)
  const { selectedVisa, setSelectedVisa } = useContext(UserContext)
  useEffect(() => {
    if (
      adaptabilityInfo &&
      Object.keys(adaptabilityInfo)?.length !== 0 &&
      adaptabilityInfo.isLocked
    ) {
      setAdaptabilityDis(token ? adminFormDisable : true)
    }
    
  }, [adaptabilityInfo, token, adminFormDisable])
  const { handleSubmit, register } = useForm()
  const submitAdaptabilityForm = async (values) => {
    try {
      let apiPath = token
        ? 'support/visaUpdateAdaptability'
        : 'visa/visaAdaptability'
      const res = await axios.post(
        `${serverPath}/${apiPath}`,
        {
          ...values,
          visaTypeId: selectedVisa.visaId,
          invoiceId: selectedVisa.invoiceId,
        },
        {
          headers: {
            authorization: `Bearer ${
              token ? token : localStorage.getItem('token')
            }`,
          },
        },
      )
      const { code, create } = res.data
      if (code === 200) {
        setAdaptabilityInfo(create)
        setSelectedVisa({
          invoiceId: create?.invoiceId,
          visaId: create?.visaTypeId,
          caseId: selectedVisa?.caseId,
          visaName: selectedVisa?.visaName,
          isEvaluation: selectedVisa?.isEvaluation,
        })
        toast.success('Your information saved successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    } catch (err) {
      toast.error(err.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        toastId: 'err',
      })
    }
  }
  return (
    <div className="personal-info adaptability-info">
      <Form onSubmit={handleSubmit(submitAdaptabilityForm)}>
        <div className="adaptability-bg">
          <h6>Education</h6>
          <div className="box-grid-three">
            <Form.Group className="form-group" controlId="formBasicEmail">
              <Form.Label>Did You Study in Canada??</Form.Label>
              <Form.Select
                aria-label="Default select example"
                disabled={adaptabilityDis ? adaptabilityDis : false}
                defaultValue={
                  adaptabilityInfo ? adaptabilityInfo?.studyInCanada : ''
                }
                name="studyInCanada"
                {...register('studyInCanada')}
              >
                <option value="">
                  {adaptabilityInfo
                    ? adaptabilityInfo?.studyInCanada
                    : 'Select'}
                </option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="form-group" controlId="formBasicEmail">
              <Form.Label>Field of Study:</Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                defaultValue={adaptabilityInfo && adaptabilityInfo.fieldStudy}
                disabled={adaptabilityDis ? adaptabilityDis : false}
                name="fieldStudy"
                {...register('fieldStudy')}
              />
            </Form.Group>
            <Form.Group className="form-group" controlId="formBasicEmail">
              <Form.Label>Institutions:</Form.Label>
              <Form.Select
                aria-label="Default select example"
                disabled={adaptabilityDis ? adaptabilityDis : false}
                defaultValue={
                  adaptabilityInfo ? adaptabilityInfo?.institution : ''
                }
                name="institution"
                {...register('institution')}
              >
                <option value="">
                  {adaptabilityInfo ? adaptabilityInfo?.institution : 'Select'}
                </option>
                <option value="None">None</option>
                <option value="College">College</option>
                <option value="University">University</option>
                <option value="Trade School">Trade School</option>
                <option value="Technical School">Technical School</option>
                <option value="Online Course">Online Course</option>
              </Form.Select>
            </Form.Group>
          </div>
          <div className="box-grid-three">
            <Form.Group className="form-group" controlId="formBasicEmail">
              <Form.Label>Study Duration?</Form.Label>
              <Form.Select
                aria-label="Default select example"
                disabled={adaptabilityDis ? adaptabilityDis : false}
                defaultValue={
                  adaptabilityInfo ? adaptabilityInfo?.studyDuration : ''
                }
                name="studyDuration"
                {...register('studyDuration')}
              >
                <option value="">
                  {adaptabilityInfo
                    ? adaptabilityInfo?.studyDuration
                    : 'Select'}
                </option>
                <option value="None">None</option>
                <option value="Less than 1 Academic year">
                  Less than 1 Academic year
                </option>
                <option value="1 Academic Year">1 Academic Year</option>
                <option value="2 Academic Years">2 Academic Years</option>
                <option value="3 Academic Years">3 Academic Years</option>
                <option value="4 Academic Years">4 Academic Years</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="form-group" controlId="formBasicEmail">
              <Form.Label>Full Time or Part time Studies?</Form.Label>
              <Form.Select
                aria-label="Default select example"
                disabled={adaptabilityDis ? adaptabilityDis : false}
                name="fullTimePartTimeStudies"
                defaultValue={
                  adaptabilityInfo
                    ? adaptabilityInfo?.fullTimePartTimeStudies
                    : ''
                }
                {...register('fullTimePartTimeStudies')}
              >
                <option value="">
                  {adaptabilityInfo
                    ? adaptabilityInfo?.fullTimePartTimeStudies
                    : 'Select'}
                </option>
                <option value="Full Time">Full Time</option>
                <option value="Part Time">Part Time</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="form-group" controlId="formBasicEmail">
              <Form.Label>Completed?</Form.Label>
              <Form.Select
                aria-label="Default select example"
                disabled={adaptabilityDis ? adaptabilityDis : false}
                defaultValue={
                  adaptabilityInfo ? adaptabilityInfo?.completed : ''
                }
                name="completed"
                {...register('completed')}
              >
                <option value="">
                  {adaptabilityInfo ? adaptabilityInfo?.completed : 'Select'}
                </option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="form-group" controlId="formBasicEmail">
              <Form.Label>
                If not, how long until studies are completed?
              </Form.Label>
              <Form.Control
                type="text"
                placeholder=""
                defaultValue={
                  adaptabilityInfo && adaptabilityInfo?.completionTime
                }
                disabled={adaptabilityDis ? adaptabilityDis : false}
                name="completionTime"
                {...register('completionTime')}
              />
            </Form.Group>
          </div>
          <h6>Internship</h6>
          <div className="box-grid-two">
            <Form.Group className="form-group" controlId="formBasicEmail">
              <Form.Label>
                Did You Have Paid or Non-Paid Internship in Canada?
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                disabled={adaptabilityDis ? adaptabilityDis : false}
                defaultValue={
                  adaptabilityInfo
                    ? adaptabilityInfo?.paidNonPaidInternshipInCanada
                    : ''
                }
                name="paidNonPaidInternshipInCanada"
                {...register('paidNonPaidInternshipInCanada')}
              >
                <option value="">
                  {adaptabilityInfo
                    ? adaptabilityInfo?.paidNonPaidInternshipInCanada
                    : 'Select'}
                </option>
                <option value="Yes">None</option>
                <option value="Paid">Paid</option>
                <option value="Non-Paid">Non-Paid</option>
              </Form.Select>
            </Form.Group>
          </div>
          <h6>Candidate Relatives</h6>
          <div className="box-grid-three">
            <Form.Group className="form-group" controlId="formBasicEmail">
              <Form.Label>
                Do You Have Relatives With PR/Citizenship ?
              </Form.Label>
              <Form.Select
                aria-label="Default select example"
                disabled={adaptabilityDis ? adaptabilityDis : false}
                defaultValue={
                  adaptabilityInfo
                    ? adaptabilityInfo?.relativesWithPRCitizenship
                    : ''
                }
                name="relativesWithPRCitizenship"
                {...register('relativesWithPRCitizenship')}
              >
                <option value="">
                  {adaptabilityInfo
                    ? adaptabilityInfo?.relativesWithPRCitizenship
                    : 'Select'}
                </option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="form-group" controlId="formBasicEmail">
              <Form.Label>Are Your Relatives 18+?</Form.Label>
              <Form.Select
                aria-label="Default select example"
                disabled={adaptabilityDis ? adaptabilityDis : false}
                name="relatives18above"
                defaultValue={
                  adaptabilityInfo ? adaptabilityInfo?.relatives18above : ''
                }
                {...register('relatives18above')}
              >
                <option value="">
                  {adaptabilityInfo
                    ? adaptabilityInfo?.relatives18above
                    : 'Select'}
                </option>
                <option value="Yes">Yes</option>
                <option value="No">No</option>
              </Form.Select>
            </Form.Group>
            <Form.Group className="form-group" controlId="formBasicEmail">
              <Form.Label>What is your Relationship</Form.Label>
              <Form.Select
                aria-label="Default select example"
                disabled={adaptabilityDis ? adaptabilityDis : false}
                defaultValue={
                  adaptabilityInfo ? adaptabilityInfo?.relationship : ''
                }
                name="relationship"
                {...register('relationship')}
              >
                <option value="">
                  {adaptabilityInfo ? adaptabilityInfo?.relationship : 'Select'}
                </option>
                <option value="Parent">Parent</option>
                <option value="Grand Parent">Grand Parent</option>
                <option value="Uncle/Aunt">Uncle/Aunt</option>
                <option value="Child">Child</option>
                <option value="Grandchild">Grandchild</option>
                <option value="Niece/Nephew">Niece/Nephew</option>
                <option value="Spouse/Common Law partner">
                  Spouse/Common Law partner
                </option>
              </Form.Select>
            </Form.Group>
          </div>
        </div>
        <div className="visa-btn">
          {!adaptabilityDis ? (
            <>
              <Button
                type="submit"
                disabled={adaptabilityDis ? adaptabilityDis : false}
                className={`${adaptabilityDis ? 'success-btn' : ''}`}
              >
                Save {adaptabilityDis && <img src={approved} alt="approved" />}
              </Button>
              <div className="visa-desc-text">
                <p>
                  Once your form is completed, you will be able to make new
                  changes by contacting our Support team.
                </p>
                <p>When you're ready, click "Submit"!</p>
              </div>
            </>
          ) : (
            <div className="for-new-changes">
              <p>
                <span>Important! </span> Please contact our Support team if you
                would like to make new changes .
              </p>
            </div>
          )}
        </div>
      </Form>
    </div>
  )
}
export default AdaptabilityInfo
