export const parentSponsorSpouseVisaSponsoringFinance = [
  {
    name: 'sponsoringBankStatement',
    label: 'Bank Statement (showing your joint bank account)',
    secondLabel: '',
    isRequired: true,
  },
]
export const partnerSpouseVisaFinanceDocSponsoring = [
  {
    name: 'sponsoringEmpReferenceLetter',
    label: 'Employment Reference Letter',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'sponsoringBankStatement',
    label: 'Bank Statement (showing your joint bank account)',
    secondLabel: '',
    isRequired: true,
  },
]
export const superVisaFinanceDocSponsoring = [
  {
    name: 'sponsoringEmpReferenceLetter',
    label: 'Notice of Assessment (NOA) or T4/T1 for the most recent tax year',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'sponsoringEmpBenefitInsurance',
    label: 'Employment Insurance Benefit Statement',
    secondLabel: '',
    isRequired: true,
  },

  {
    name: 'sponsoringEmpReferenceLetter',
    label: 'Employment Reference Letter',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'sponsoringPayStubsOrSlips',
    label: 'Pay Stubs / Pay Slips',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'sponsoringBankStatement',
    label: 'Recent Bank Statement',
    secondLabel:
      '(account balance must include CAD$26,620 or more depending on each family member that is joining you)',
    isRequired: true,
  },
]
