/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import ApplicationLayout from '../layout'
import {  Table } from 'react-bootstrap'
import axios from 'axios'
import { serverPath } from '../../../config/key'
import { useEffect } from 'react'
import { useState } from 'react'
import { toast } from 'react-toastify'

import Loader from '../../loader/loader'
let options = {
  // weekday: "long",
  year: 'numeric',
  month: 'long',
  day: 'numeric',
}
const Invoices = () => {
  const [userInvoice, setUserInvoice] = useState([])

  const [loading, setLoading] = useState(false)
  const getUserInvoice = async () => {
    try {
      setLoading(true)
      const res = await axios.get(`${serverPath}/users/getUserInvoiceById`, {
        headers: { authorization: `Bearer ${localStorage.getItem('token')}` },
      })
      setLoading(false)
      const { invoiceDetail } = res?.data || []
      if (invoiceDetail?.length > 0) {
        setUserInvoice(invoiceDetail)
      }
    } catch (err) {
      toast.error(err.message, { toastId: 'err0r' })
    }
  }
  useEffect(() => {
    getUserInvoice()
  }, [])
  const handleShowPdf = async (id) => {
    const response = await axios.post(
      `${serverPath}/invoice/generateSignedUrl`,
      { pdfId: id },
      {
        headers: {
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    )
    const { data, status } = response
    if (status === 200) {
      window.open(data?.signedUrl, '_blank')
    }
  }

  return (
    <ApplicationLayout>
      <div className={`invoices-page`}>
        <div className="container">
          {/* <h3>Invoices</h3> */}
          <div className={`invoices-page-content scroll-content`}>
            {loading ? (
              <Loader />
            ) : (
              <Table responsive>
                <tbody>
                  {userInvoice &&
                    userInvoice.length > 0 &&
                    userInvoice.map((inv, i) => (
                      <tr key={i}>
                        <td className="col col-lg-2">
                          {inv?.products?.length && inv?.products.map((pr)=>(
                            <div className="invoice-date">
                            {pr?.name}
                          </div>
                          ))}
                          
                        </td>
                        <td className="col col-lg-2">
                          <div className="invoice-date">
                            {new Date(inv?.date)?.toLocaleDateString(
                              'en-US',
                              options,
                            )}
                            <span>
                              #
                              {`${
                                inv?.company[0]?.companyCode +
                                '-' +
                                inv?.invoiceNumber
                              }`}
                            </span>
                          </div>
                        </td>
                        <td className="col col-lg-2">
                          <div className="invoice-date">{inv?.clientName}</div>
                        </td>
                        <td className="col col-lg-2">
                          <div className="invoice-date">{inv?.clientEmail}</div>
                        </td>
                        {/* <td className="col col-lg-2">
                          <div className="invoice-date">{inv?.clientPhone}</div>
                        </td> */}
                        <td className="col col-lg-2" align="center">
                          <span className="invoice-price">
                            {`${inv?.transaction[0]?.currency}  
                              ${inv?.transaction[0]?.price}`}
                          </span>
                        </td>
                        <td className="col col-lg-2 text-right" align="right">
                          <span
                            className="invoice-pdf"
                            onClick={() => handleShowPdf(inv?.invoicePdf?._id)}
                          >
                            Download PDF
                          </span>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </Table>
            )}
          </div>
        </div>
      </div>
    </ApplicationLayout>
  )
}

export default Invoices
