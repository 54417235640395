import React from "react";
import ApplicationLayout from "../layout";
import { Form, Button } from "react-bootstrap";
import { useState } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { serverPath } from "../../../config/key";
import { toast } from "react-toastify";
import { updatePasswordSchema } from "../../../utils/validationSchema";
import approved from "../../../assets/images/header/approved.svg";
import editicon from "../../../assets/images/application/edit.svg";
import showicon from "../../../assets/images/login/show-icon.svg";
import hideicon from "../../../assets/images/login/hide-icon.svg";
import { useContext } from "react";
import UserContext from "../../../context/UserContext";
const Settings = () => {
  const { user } = useContext(UserContext);
  const [values, setValues] = useState({
    password: "",
    confirmPassword: "",
  });
  const [success, setSuccess] = useState(false);
  const [editInputnput, setEditInput] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  const handleEditInput = () => {
    setEditInput(!editInputnput);
  };
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleShowConfPassword = () => {
    setShowConfPassword(!showConfPassword);
  };
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
  } = useForm({ resolver: yupResolver(updatePasswordSchema) });
  const updatePassword = async (values) => {
    try {
      const response = await axios.post(
        `${serverPath}/users/updatePassword`,
        values,
        {
          headers: { authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      const { upd } = response.data;
      if (upd.modifiedCount === 1) {
        reset();
        toast.success("Password updated successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
        setSuccess(true);
      }
    } catch (error) {
      if (error?.response?.data) {
        toast.error(error.response.data.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    }
  };
  return (
    <ApplicationLayout>
      <div className={`settings-page`}>
        <div className="container">
          {/* <h3>Settings</h3> */}
          <div className={`settings-page-content`}>
            <Form
              onSubmit={handleSubmit(updatePassword)}
              className={`${success ? "success-form" : ""}`}
            >
              <div className="settings-form-content">
                <div className="settings-form-container">
                  {/* <h4>Username</h4> */}
                  <Form.Group>
                    <Form.Label>Username</Form.Label>
                    <div className="edit-input">
                      <Form.Control
                        type="text"
                        name="username"
                        placeholder={user?.email}
                        defaultValue={user?.email ? user?.email : ""}
                        disabled={editInputnput}
                      />
                      <span className="edit-icon" onClick={handleEditInput}>
                        <img src={editicon} alt="" />
                      </span>
                    </div>
                    <p>
                      Edit your account setting and change your password here.
                    </p>
                    {/* <h4>Password</h4> */}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>New Password*</Form.Label>
                    <div className="pass-show">
                      <span className="pass-icon" onClick={handleShowPassword}>
                        {showPassword ? (
                          <img src={hideicon} alt="" />
                        ) : (
                          <img src={showicon} alt="" />
                        )}
                      </span>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        name="password"
                        onChange={handleChange}
                        {...register("password")}
                        defaultValue={values.password}
                      />
                    </div>

                    {errors?.password ? (
                      <span className="error-msg">
                        {errors.password.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Confirm New Password*</Form.Label>
                    <div className="pass-show">
                      <span
                        className="pass-icon"
                        onClick={handleShowConfPassword}
                      >
                        {showConfPassword ? (
                          <img src={hideicon} alt="" />
                        ) : (
                          <img src={showicon} alt="" />
                        )}
                      </span>
                      <Form.Control
                        type={showConfPassword ? "text" : "password"}
                        name="confirmPassword"
                        onChange={handleChange}
                        {...register("confirmPassword")}
                        defaultValue={values.confirmPassword}
                      />
                    </div>

                    {errors?.confirmPassword ? (
                      <span className="error-msg">
                        {errors.confirmPassword.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </div>
              </div>

              <Button type="submit">
                Submit {success && <img src={approved} alt="approved" />}
              </Button>
            </Form>
          </div>
        </div>
      </div>
    </ApplicationLayout>
  );
};

export default Settings;
