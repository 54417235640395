/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useRef, useContext, useEffect } from "react";
import PhoneInput from "react-phone-input-2";
import {
  Accordion,
  Button,
  Card,
  Form,
  useAccordionButton,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import axios from "axios";
import "react-datepicker/dist/react-datepicker.css";
import { evaluationPersonalInfoSchema } from "../../utils/validationSchema";
import UserContext from "../../context/UserContext";
import { serverPath } from "../../config/key";
import { toast } from "react-toastify";
import approved from "../../assets/images/header/approved.svg";
// import { findFlagUrlByNationality, countries } from 'country-flags-svg'
import addIcon from "../../assets/images/visa/add-icon.svg";
import activeArrow from "../../assets/images/visa/active-arrow.svg";
import closeArrow from "../../assets/images/visa/close-arrow.svg";
import ErrorModal from "../modals/errorModal";

const PersonalInfo = ({
  personalInformation,
  setPersonalInfo,
  adminFormDisable,
  activeTabKey,
}) => {
  const urlParams = new URLSearchParams(window.location.search);
  const { selectedVisa, countries, setSelectedVisa, userGeoData } =
    useContext(UserContext);
  const token = urlParams.get("t");
  let intialState = {
    childFirstName: "",
    childMiddleName: "",
    childLastName: "",
    childGender: "",
    childBirthCity: "",
    childBirthCountry: userGeoData?.country_name,
    childCitizenship: userGeoData?.country_name,
    childSecondCitizenship: "",
    childDateOfBirth: "",
  };
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm({
    resolver: yupResolver(evaluationPersonalInfoSchema),
    shouldUnregister: true,
  });
  const inputRef = useRef();
  const [error, setErrors] = useState();
  const [dateOfBirth, setDateOfBirth] = useState(
    personalInformation?.mainApplicant?.dateOfBirth
      ? new Date(personalInformation?.mainApplicant?.dateOfBirth)
      : personalInformation?.sponsoredInformation?.dateOfBirth
      ? new Date(personalInformation?.sponsoredInformation?.dateOfBirth)
      : new Date()
  );
  const [sDateOfBirth, setSDateOfBirth] = useState(
    personalInformation?.spouseInfo?.dateOfBirth
      ? new Date(personalInformation?.spouseInfo?.dateOfBirth)
      : personalInformation?.sponsoredSpouseInfo?.dateOfBirth
      ? new Date(personalInformation?.sponsoredSpouseInfo?.dateOfBirth)
      : new Date()
  );

  const [childs, setChilds] = useState(
    personalInformation?.sponsoredChildrenInfo?.length > 0
      ? personalInformation?.sponsoredChildrenInfo
      : personalInformation?.childInformation?.length > 0
      ? personalInformation?.childInformation
      : [{ ...intialState }]
  );

  const [mainApplicanDis, setMainApplicaDis] = useState(false);
  const [spouseDis, setSpouseDis] = useState(false);
  const [childrenInfoDis, setChildrenInfo] = useState(false);
  const [spouseFormState, setSpouseformstate] = useState("plus");
  const [childrenFormState, setChildrenFormState] = useState("plus");
  const [customAccordBtn, setCustomAccordBtn] = useState(true);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const watchShowHealthDetail = watch("healthCondition");
  const criminalOffenses = watch("criminalOffense");
  const sposeCriminalOffense = watch("spouseInformation.sCriminalOffense");
  const spouseHealthCondition = watch("spouseInformation.sHealthCondition");
  useEffect(() => {
    if (
      personalInformation?.mainApplicant &&
      Object.keys(personalInformation?.mainApplicant)?.length !== 0 &&
      personalInformation?.isLocked
    ) {
      setMainApplicaDis(token ? adminFormDisable : true);
      setSpouseDis(token ? adminFormDisable : true);
      setChildrenInfo(token ? adminFormDisable : true);
    }
    if (
      personalInformation?.mainApplicant &&
      Object.keys(personalInformation?.mainApplicant)?.length !== 0 &&
      personalInformation?.childInformation &&
      personalInformation?.childInformation?.length > 0
    ) {
      setChilds(personalInformation?.childInformation);
    }
    if (token) {
      setValue(
        "birthCountry",
        personalInformation?.mainApplicant?.birthCountry
          ? personalInformation?.mainApplicant?.birthCountry
          : personalInformation?.sponsoredInformation?.birthCountry
          ? personalInformation?.sponsoredInformation?.birthCountry
          : userGeoData?.country_name
      );
      setValue(
        "countryCitizenship",
        personalInformation?.mainApplicant?.countryCitizenship
          ? personalInformation?.mainApplicant?.countryCitizenship
          : personalInformation?.sponsoredInformation?.countryCitizenship
          ? personalInformation?.sponsoredInformation?.countryCitizenship
          : userGeoData?.country_name
      );
      setValue(
        "countryResidence",
        personalInformation?.mainApplicant?.countryResidence
          ? personalInformation?.mainApplicant?.countryResidence
          : personalInformation?.sponsoredInformation?.countryResidence
          ? personalInformation?.sponsoredInformation?.countryResidence
          : userGeoData?.country_name
      );
      setValue(
        "spouseInformation.sBirthCountry",
        personalInformation?.spouseInfo?.birthCountry
          ? personalInformation?.spouseInfo?.birthCountry
          : personalInformation?.sponsoredSpouseInfo?.birthCountry
          ? personalInformation?.sponsoredSpouseInfo?.birthCountry
          : userGeoData?.country_name
      );
      setValue(
        "spouseInformation.sCountryCitizenship",
        personalInformation?.spouseInfo?.countryCitizenship
          ? personalInformation?.spouseInfo?.countryCitizenship
          : personalInformation?.sponsoredSpouseInfo?.countryCitizenship
          ? personalInformation?.sponsoredSpouseInfo?.countryCitizenship
          : userGeoData?.country_name
      );
      setValue(
        "spouseInformation.sCountryResidence",
        personalInformation?.spouseInfo?.countryResidence
          ? personalInformation?.spouseInfo?.countryResidence
          : personalInformation?.sponsoredSpouseInfo?.countryResidence
          ? personalInformation?.sponsoredSpouseInfo?.countryResidence
          : userGeoData?.country_name
      );
      setValue(
        "phoneNumber",
        personalInformation?.mainApplicant?.phoneNumber ||
          personalInformation?.sponsoredInformation?.phoneNumber ||
          ""
      );
      setValue(
        "mobile",
        personalInformation?.mainApplicant?.mobile
          ? personalInformation?.mainApplicant?.mobile
          : personalInformation?.sponsoredInformation?.mobile
          ? personalInformation?.sponsoredInformation?.mobile
          : ""
      );
      setValue(
        "officePhoneNumber",
        personalInformation?.mainApplicant?.officePhoneNumber
          ? personalInformation?.mainApplicant?.officePhoneNumber
          : personalInformation?.sponsoredInformation?.officePhoneNumber
          ? personalInformation?.sponsoredInformation?.officePhoneNumber
          : ""
      );
      setValue(
        "spouseInformation.sPhoneNumber",
        personalInformation?.spouseInfo?.phoneNumber ||
          personalInformation?.sponsoredSpouseInfo?.phoneNumber ||
          ""
      );
      setValue(
        "spouseInformation.sMobile",
        personalInformation?.spouseInfo?.mobile
          ? personalInformation?.spouseInfo?.mobile
          : personalInformation?.sponsoredSpouseInfo?.mobile
      );
      setValue(
        "spouseInformation.sOfficePhoneNumber",
        personalInformation?.spouseInfo?.officePhoneNumber
          ? personalInformation?.spouseInfo?.officePhoneNumber
          : personalInformation?.sponsoredSpouseInfo?.officePhoneNumber
      );
      setValue(
        "spouseInformation.sDateOfBirth",
        personalInformation?.spouseInfo?.dateOfBirth
          ? new Date(personalInformation?.spouseInfo?.dateOfBirth)
          : personalInformation?.sponsoredSpouseInfo?.dateOfBirth
          ? new Date(personalInformation?.sponsoredSpouseInfo?.dateOfBirth)
          : new Date()
      );
    }
  }, [personalInformation, token, adminFormDisable, activeTabKey]);
  const changeAccordionselect = (key) => {
    if (key === "1") {
      setSpouseformstate("open");
      setValue("isSpouseOpen", true);
    } else if (key === "2") {
      setChildrenFormState("open");
      setValue("isChildren", true);
      setValue("childrenInfo", [{ ...intialState }]);
      setChilds(
        personalInformation?.childInformation?.length > 0
          ? personalInformation?.childInformation
          : [{ ...intialState }]
      );
    }
  };
  const handleAddChild = () => {
    setValue("childrenInfo", [...childs, { ...intialState }]);
    setChilds([...childs, { ...intialState }]);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    let prevData = [...childs];
    prevData[index][name] = value;
    setChilds([...prevData]);
    setValue("childrenInfo", [...prevData]);
  };
  const removeChild = (index) => {
    const oldChild = [...childs];
    oldChild.splice(index, 1);
    setChilds(oldChild);
    setValue("childrenInfo", oldChild);
  };

  const handleChildDatePicker = (date, index) => {
    let prevData = [...childs];
    prevData[index].childDateOfBirth = date;
    setChilds([...prevData]);
    setValue("childrenInfo", [...prevData]);
  };

  const changeDatePicker = (date, type, ageType) => {
    if (type === "dateOfBirth") {
      setDateOfBirth(date);
      setValue("dateOfBirth", date);
    }

    if (type === "sDateOfBirth") {
      setSDateOfBirth(date);
      setValue("spouseInformation.sDateOfBirth", date);
    }

    setValue(type, date);
    var dob = new Date(date);
    //calculate month difference from current date in time
    var month_diff = Date.now() - dob.getTime();
    //convert the calculated difference in date format
    var age_dt = new Date(month_diff);
    //extract year from date
    var year = age_dt.getUTCFullYear();
    //now calculate the age of the user
    var age = Math.abs(year - 1970);
    if (ageType === "age") {
      setValue(ageType, age);
    }
    if (ageType === "sAge") {
      setValue("spouseInformation.sAge", age);
    }
  };
  const submitPersonalInfo = async (values) => {
    try {
      let apiPath = token
        ? "supportEvaluation/updatePersonalInfo"
        : "evaluation/evaluationPersonalInfo";
      setErrors({});
      const res = await axios.post(
        `${serverPath}/${apiPath}`,
        {
          ...values,
          childData: childs,
          productId: selectedVisa.productId,
          invoiceId: selectedVisa.invoiceId,
        },
        {
          headers: {
            authorization: `Bearer ${
              token ? token : localStorage.getItem("token")
            }`,
          },
        }
      );
      const { code, create } = res?.data;

      if (code === 200) {
        reset();
        setPersonalInfo(create);
        setSelectedVisa({
          invoiceId: create?.invoiceId,
          productId: selectedVisa?.productId,
          caseId: selectedVisa?.caseId,
          visaName: selectedVisa?.visaName,
          isEvaluation: selectedVisa.isEvaluation,
        });
        toast.success("Your information is saved successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    } catch (err) {
      if (err?.response?.data) {
        toast.error(error.response.data.msg, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        });
      }
    }
  };
  const handleDelete = (value, id) => {
    if (value === "childrenFormState") {
      setChildrenFormState("plus");
      setErrors({});
      setValue("isChildren", false);
    } else if (value === "spouseFormState") {
      setSpouseformstate("plus");
      setValue("isSpouseOpen", false);
      setCustomAccordBtn(false);
    }
    document.getElementById(id).click();
  };
  const handleClose = (value, id) => {
    document.getElementById(id).click();
  };
  //custom accordian toggle
  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      if (customAccordBtn === eventKey) {
        setCustomAccordBtn("");
      } else setCustomAccordBtn(eventKey);
    });
    return (
      <Button id={`person-${eventKey}`} onClick={decoratedOnClick}>
        {children}
      </Button>
    );
  }
  //For Error popup
  const handleShowErrorModal = () => {
    setShowErrorModal(false);
  };
  useEffect(() => {
    if (
      (errors && Object.keys(errors).length !== 0) ||
      (error && Object.keys(error).length !== 0)
    ) {
      setShowErrorModal(true);
    } else {
      setShowErrorModal(false);
    }
  }, [errors, error]);

  return (
    <div className="personal-info ">
      <Form onSubmit={handleSubmit(submitPersonalInfo)}>
        <Accordion
          defaultActiveKey="0"
          onSelect={(key) => changeAccordionselect(key)}
        >
          <div className={`accordion-list ${"change-accordion-icon"}`}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>Main Applicant</Accordion.Header>

              <Accordion.Body>
                <div className="box-grid-three">
                  <Form.Group
                    className={`form-group ${
                      errors?.firstName?.message ? "error-msg" : ""
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>First Name*</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      defaultValue={
                        personalInformation?.mainApplicant?.firstName
                          ? personalInformation?.mainApplicant?.firstName
                          : ""
                      }
                      {...register("firstName")}
                    />

                    {errors?.firstName ? (
                      <span className="error-msg">
                        {errors?.firstName?.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                  {/* <Work control={control} /> */}
                  <Form.Group
                    className={`form-group`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Middle Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="midName"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      defaultValue={
                        personalInformation?.mainApplicant?.middleName
                          ? personalInformation?.mainApplicant?.middleName
                          : ""
                      }
                      {...register("midName")}
                    />
                    {errors?.midName ? (
                      <span className="error-msg">
                        {errors?.midName?.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                  <Form.Group
                    className={`form-group ${
                      errors?.lName?.message ? "error-msg" : ""
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Last Name*</Form.Label>
                    <Form.Control
                      type="text"
                      name="lName"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      defaultValue={
                        personalInformation?.mainApplicant?.lastName
                          ? personalInformation?.mainApplicant?.lastName
                          : ""
                      }
                      {...register("lName")}
                    />
                    {errors?.lName ? (
                      <span className="error-msg">
                        {errors?.lName?.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </div>
                <div className="box-grid-three">
                  <Form.Group
                    className={`form-group ${
                      errors?.gender?.message ? "error-msg" : ""
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Gender*</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="gender"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      defaultValue={
                        personalInformation?.mainApplicant?.gender
                          ? personalInformation?.mainApplicant?.gender
                          : ""
                      }
                      {...register("gender")}
                    >
                      <option value="">Select</option>
                      <option value="male">Male</option>
                      <option value="female">Female</option>
                    </Form.Select>
                    {errors?.gender ? (
                      <span className="error-msg">
                        {errors?.gender?.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Group>

                  <Form.Group
                    className={`form-group ${
                      errors?.birthCountry ? "error-msg" : ""
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Birth Country*</Form.Label>
                    <Form.Select
                      name="birthCountry"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      defaultValue={
                        personalInformation?.mainApplicant?.birthCountry
                          ? personalInformation?.mainApplicant?.birthCountry
                          : personalInformation?.sponsoredInformation
                              ?.birthCountry
                          ? personalInformation?.sponsoredInformation
                              ?.birthCountry
                          : userGeoData?.country_name
                      }
                      {...register("birthCountry")}
                    >
                      <option value={userGeoData?.country_name}>
                        {personalInformation?.mainApplicant?.birthCountry
                          ? personalInformation?.mainApplicant?.birthCountry
                          : userGeoData?.country_name}
                      </option>
                      {countries &&
                        countries?.length > 0 &&
                        countries.map((el) => (
                          <option value={el?.name}>{el?.name}</option>
                        ))}
                    </Form.Select>
                    {errors?.birthCountry ? (
                      <span className="error-msg">
                        {errors?.birthCountry?.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Group>

                  <Form.Group
                    className={`form-group ${
                      errors?.dateOfBirth ? "error-msg" : ""
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Date of Birth*</Form.Label>
                    <DatePicker
                      type="dateOfBirth"
                      name="dateOfBirth"
                      placeholderText="Select Date"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      selected={dateOfBirth}
                      onChange={(date) =>
                        changeDatePicker(date, "dateOfBirth", "age")
                      }
                      className="form-select"
                      dateFormat="dd-MM-yyyy"
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                    <input
                      ref={inputRef}
                      defaultValue={
                        personalInformation?.mainApplicant?.dateOfBirth
                          ? new Date(
                              personalInformation?.mainApplicant?.dateOfBirth
                            )
                          : dateOfBirth
                      }
                      type="hidden"
                      {...register("dateOfBirth")}
                    />
                    {errors?.dateOfBirth ? (
                      <span className="error-msg">
                        {errors?.dateOfBirth?.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </div>
                <div className="box-grid-three">
                  <Form.Group
                    className={`form-group ${
                      errors?.phoneNumber ? "error-msg" : ""
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Phone*</Form.Label>
                    {/* <Form.Control type="email" placeholder="" /> */}

                    <Form.Group>
                      <PhoneInput
                        country={
                          personalInformation &&
                          personalInformation?.mainApplicant?.phoneNumber
                            ? ""
                            : userGeoData?.country_code?.toLowerCase()
                        }
                        enableAreaCodes={false}
                        disabled={mainApplicanDis ? mainApplicanDis : false}
                        value={
                          personalInformation &&
                          personalInformation?.mainApplicant?.phoneNumber
                            ? personalInformation?.mainApplicant?.phoneNumber?.substring(
                                1
                              )
                            : ""
                        }
                        onChange={(e) => setValue("phoneNumber", "+" + e)}
                        inputProps={{
                          name: "phoneNumber",
                        }}
                      />
                      {errors?.phoneNumber ? (
                        <span className="error-msg">
                          {errors?.phoneNumber?.message}
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </Form.Group>
                  <Form.Group
                    className={`form-group`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Mobile</Form.Label>
                    <PhoneInput
                      country={
                        personalInformation &&
                        personalInformation?.mainApplicant?.mobile
                          ? ""
                          : userGeoData?.country_code?.toLowerCase()
                      }
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      value={
                        personalInformation?.mainApplicant?.mobile
                          ? personalInformation?.mainApplicant?.mobile?.substring(
                              1
                            )
                          : ""
                      }
                      onChange={(e) => {
                        setValue("mobile", "+" + e);
                      }}
                      inputProps={{
                        name: "mobile",
                      }}
                    />
                    {errors?.mobile ? (
                      <span className="error-msg">
                        {errors?.mobile?.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                  <Form.Group
                    className={`form-group`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Office</Form.Label>
                    <PhoneInput
                      country={
                        personalInformation &&
                        personalInformation?.mainApplicant?.officePhoneNumber
                          ? ""
                          : userGeoData?.country_code?.toLowerCase()
                      }
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      value={
                        personalInformation?.mainApplicant?.officePhoneNumber
                          ? personalInformation?.mainApplicant?.officePhoneNumber?.substring(
                              1
                            )
                          : ""
                      }
                      onChange={(e) => setValue("officePhoneNumber", "+" + e)}
                      inputProps={{
                        name: "officePhoneNumber",
                      }}
                    />
                  </Form.Group>
                </div>
                <div className="box-grid-two">
                  <Form.Group
                    className={`form-group ${
                      errors?.primaryEmail ? "error-msg" : ""
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Primary Email*</Form.Label>
                    <Form.Control
                      type="email"
                      name="primaryEmail"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      defaultValue={
                        personalInformation?.mainApplicant?.primaryEmail
                          ? personalInformation?.mainApplicant?.primaryEmail
                          : ""
                      }
                      {...register("primaryEmail")}
                    />

                    {errors?.primaryEmail ? (
                      <span className="error-msg">
                        <i class="las la-exclamation-triangle"></i>
                        {errors?.primaryEmail?.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Group>

                  <Form.Group
                    className={`form-group`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Secondary Email</Form.Label>
                    <Form.Control
                      type="email"
                      name="secondaryEmail"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      defaultValue={
                        personalInformation?.mainApplicant?.secondaryEmail
                          ? personalInformation?.mainApplicant?.secondaryEmail
                          : ""
                      }
                      {...register("secondaryEmail")}
                    />
                    {errors?.secondaryEmail ? (
                      <span className="error-msg">
                        {errors?.secondaryEmail?.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </div>
                <div className="box-grid-three">
                  <Form.Group
                    className={`form-group ${
                      errors?.countryCitizenship ? "error-msg" : ""
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Country of Citizenship*</Form.Label>

                    <Form.Select
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      name="countryCitizenship"
                      defaultValue={
                        personalInformation?.mainApplicant?.countryCitizenship
                          ? personalInformation?.mainApplicant
                              ?.countryCitizenship
                          : userGeoData?.country_name
                      }
                      {...register("countryCitizenship")}
                    >
                      <option value={userGeoData?.country_name}>
                        {personalInformation?.mainApplicant?.countryCitizenship
                          ? personalInformation?.mainApplicant
                              ?.countryCitizenship
                          : personalInformation?.sponsoredInformation
                              ?.countryCitizenship
                          ? personalInformation?.sponsoredInformation
                              ?.countryCitizenship
                          : userGeoData?.country_name}
                      </option>
                      {countries &&
                        countries?.length > 0 &&
                        countries.map((el) => (
                          <option value={el?.name}>{el?.name}</option>
                        ))}
                    </Form.Select>
                    {errors?.countryCitizenship ? (
                      <span className="error-msg">
                        {errors?.countryCitizenship?.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                  <Form.Group
                    className={`form-group ${
                      errors?.secondCitizenship ? "error-msg" : ""
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Second Citizenship</Form.Label>
                    <Form.Select
                      name="secondCitizenship"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      defaultValue={
                        personalInformation?.mainApplicant?.secondCitizenship
                          ? personalInformation?.mainApplicant
                              ?.secondCitizenship
                          : ""
                      }
                      {...register("secondCitizenship")}
                    >
                      {personalInformation?.mainApplicant?.secondCitizenship ? (
                        <option
                          value={
                            personalInformation?.mainApplicant
                              ?.secondCitizenship
                          }
                        >
                          {
                            personalInformation?.mainApplicant
                              ?.secondCitizenship
                          }
                        </option>
                      ) : (
                        <option value="">Select</option>
                      )}

                      {countries &&
                        countries?.length > 0 &&
                        countries.map((el) => (
                          <option value={el?.name}>{el?.name}</option>
                        ))}
                    </Form.Select>
                    {errors?.secondCitizenship ? (
                      <span className="error-msg">
                        {errors?.secondCitizenship?.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                  <Form.Group
                    className={`form-group ${
                      errors?.countryResidence ? "error-msg" : ""
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Country of Residence*</Form.Label>
                    <Form.Select
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      name="countryResidence"
                      defaultValue={
                        personalInformation?.mainApplicant?.countryResidence
                          ? personalInformation?.mainApplicant?.countryResidence
                          : userGeoData?.country_name
                      }
                      {...register("countryResidence")}
                    >
                      <option value={userGeoData?.country_name}>
                        {personalInformation?.mainApplicant?.countryResidence
                          ? personalInformation?.mainApplicant?.countryResidence
                          : personalInformation?.sponsoredInformation
                              ?.countryResidence
                          ? personalInformation?.sponsoredInformation
                              ?.countryResidence
                          : userGeoData?.country_name}
                      </option>
                      {countries &&
                        countries?.length > 0 &&
                        countries.map((el) => (
                          <option value={el?.name}>{el?.name}</option>
                        ))}
                    </Form.Select>
                    {errors?.countryResidence ? (
                      <span className="error-msg">
                        {errors?.countryResidence?.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </div>
                <div className="box-grid-three">
                  <Form.Group
                    className={`form-group ${errors?.city ? "error-msg" : ""}`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>City*</Form.Label>
                    <Form.Control
                      type="text"
                      name="city"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      defaultValue={
                        personalInformation?.mainApplicant?.city
                          ? personalInformation?.mainApplicant?.city
                          : ""
                      }
                      {...register("city")}
                    />
                    {errors?.city ? (
                      <span className="error-msg">{errors?.city?.message}</span>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                  <Form.Group
                    className={`form-group ${
                      errors?.street ? "error-msg" : ""
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Street*</Form.Label>
                    <Form.Control
                      type="text"
                      name="street"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      defaultValue={
                        personalInformation?.mainApplicant?.street
                          ? personalInformation?.mainApplicant?.street
                          : ""
                      }
                      {...register("street")}
                    />
                    {errors?.street ? (
                      <span className="error-msg">
                        {errors?.street?.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                  <Form.Group
                    className={`form-group ${
                      errors?.zipCode ? "error-msg" : ""
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Zip Code / Postal Code*</Form.Label>
                    <Form.Control
                      type="number"
                      name="zipCode"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      defaultValue={
                        personalInformation?.mainApplicant?.zipCode
                          ? personalInformation?.mainApplicant?.zipCode
                          : ""
                      }
                      {...register("zipCode")}
                    />
                    {errors?.zipCode ? (
                      <span className="error-msg">
                        {errors?.zipCode?.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </div>
                <div className="box-grid-two">
                  <Form.Group
                    className={`form-group ${
                      errors?.maritalStatus ? "error-msg" : ""
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Marital Status*</Form.Label>
                    <Form.Select
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      defaultValue={
                        personalInformation?.mainApplicant?.maritalStatus
                          ? personalInformation?.mainApplicant?.maritalStatus
                          : ""
                      }
                      name="maritalStatus"
                      {...register("maritalStatus")}
                    >
                      <option value="">Select Status</option>
                      <option value="Single">Single</option>
                      <option value="Married">Married</option>
                      <option value="Married">
                        Common-Law Partnership{" "}
                        <span>
                          (you are living together for at least 1 year)
                        </span>
                      </option>
                      <option value="Legally Separated">
                        Legally Separated
                      </option>
                      <option value="Divorced">Divorced</option>
                      <option value="Widowed">Widowed</option>
                    </Form.Select>
                    {errors?.maritalStatus ? (
                      <span className="error-msg">
                        {errors?.maritalStatus?.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Group>

                  <Form.Group
                    className={`form-group ${
                      errors?.dependentChildren ? "error-msg" : ""
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Number of dependent children under 22
                    </Form.Label>
                    <Form.Control
                      type="text"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      defaultValue={
                        personalInformation?.mainApplicant?.dependentChildren?.toString()
                          ? personalInformation?.mainApplicant
                              ?.dependentChildren
                          : ""
                      }
                      name="dependentChildren"
                      {...register("dependentChildren")}
                    />

                    {errors?.dependentChildren ? (
                      <span className="error-msg">
                        {errors?.dependentChildren?.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                </div>
                <div className="box-grid-two">
                  <Form.Group
                    className={`form-group ${
                      errors?.criminalOffense ? "error-msg" : ""
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>
                      Have you had any criminal offenses lodged against you?*
                    </Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="criminalOffense"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      defaultValue={
                        personalInformation?.mainApplicant?.criminalOffense
                          ? personalInformation?.mainApplicant?.criminalOffense
                          : ""
                      }
                      {...register("criminalOffense")}
                    >
                      <option value="">Select</option>
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </Form.Select>
                    {errors?.criminalOffense ? (
                      <span className="error-msg">
                        {errors?.criminalOffense?.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                  {(criminalOffenses && criminalOffenses === "Yes") ||
                  personalInformation?.mainApplicant?.criminalOffense ===
                    "Yes" ||
                  personalInformation?.sponsoredInformation?.criminalOffense ===
                    "Yes" ? (
                    <Form.Group
                      className={`form-group ${
                        errors?.offenseTypeDetail ? "error-msg" : ""
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>
                        Please describe your criminal offenses from the past 10
                        years
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="offenseTypeDetail"
                        disabled={mainApplicanDis ? mainApplicanDis : false}
                        defaultValue={
                          personalInformation?.mainApplicant?.offenseTypeDetail
                            ? personalInformation?.mainApplicant
                                ?.offenseTypeDetail
                            : ""
                        }
                        {...register("offenseTypeDetail")}
                      />
                      {errors?.offenseTypeDetail ? (
                        <span className="error-msg">
                          {errors?.offenseTypeDetail?.message}
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  ) : (
                    ""
                  )}
                </div>
                <div className="box-grid-two">
                  <Form.Group
                    className={`form-group ${
                      errors?.healthCondition ? "error-msg" : ""
                    }`}
                    controlId="formBasicEmail"
                  >
                    <Form.Label>Health Problems*</Form.Label>
                    <Form.Select
                      aria-label="Default select example"
                      name="healthCondition"
                      disabled={mainApplicanDis ? mainApplicanDis : false}
                      defaultValue={
                        personalInformation?.mainApplicant?.healthCondition
                          ? personalInformation?.mainApplicant?.healthCondition
                          : personalInformation?.sponsoredInformation
                              ?.healthCondition
                          ? personalInformation?.sponsoredInformation
                              ?.healthCondition
                          : ""
                      }
                      {...register("healthCondition")}
                    >
                      <option value="">Select</option>
                      <option value="No">No</option>
                      <option value="Yes">Yes</option>
                    </Form.Select>
                    {errors?.healthCondition ? (
                      <span className="error-msg">
                        {errors?.healthCondition?.message}
                      </span>
                    ) : (
                      ""
                    )}
                  </Form.Group>
                  {watchShowHealthDetail === "Yes" ||
                  personalInformation?.mainApplicant?.healthCondition ===
                    "Yes" ||
                  personalInformation?.sponsoredInformation?.healthCondition ===
                    "Yes" ? (
                    <Form.Group
                      className={`form-group ${
                        errors?.healthConditionDetail ? "error-msg" : ""
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>
                        Please describe your health condition/s
                      </Form.Label>
                      <Form.Control
                        type="text"
                        name="healthConditionDetail"
                        disabled={mainApplicanDis ? mainApplicanDis : false}
                        defaultValue={
                          personalInformation?.mainApplicant
                            ?.healthConditionDetail
                            ? personalInformation?.mainApplicant
                                ?.healthConditionDetail
                            : personalInformation?.sponsoredInformation
                                ?.healthConditionDetail
                        }
                        {...register("healthConditionDetail")}
                      />
                    </Form.Group>
                  ) : (
                    ""
                  )}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </div>
          <div
            className={`accordion-list ${
              spouseFormState === "open" ? "change-accordion-icon" : ""
            }`}
          >
            <Card>
              <Card.Header
                className={customAccordBtn === "1" ? "accord-open" : ""}
                onClick={() =>
                  spouseFormState === "open" || spouseDis
                    ? handleClose("spouseFormState", "person-1")
                    : ""
                }
              >
                <div className="accordian-title">
                  {spouseFormState === "open" || spouseDis
                    ? "Spouse (Partner) Information"
                    : "Add Spouse (Partner) Information"}
                  {spouseFormState === "open" && !spouseDis && (
                    <div className="delete-btn">
                      <Button
                        onClick={() =>
                          handleDelete("spouseFormState", "person-1")
                        }
                      >
                        Delete <i className="las la-minus"></i>
                      </Button>
                    </div>
                  )}
                </div>
                <CustomToggle eventKey="1">
                  {spouseFormState === "plus" && !spouseDis ? (
                    <img src={addIcon} alt="Add Icon" />
                  ) : (spouseFormState === "open" && !spouseDis) ||
                    (spouseFormState === "plus" && spouseDis) ||
                    (spouseFormState === "open" && spouseDis) ||
                    (spouseFormState === "plus" && !spouseDis) ? (
                    <img
                      className="arrow-icon"
                      src={customAccordBtn !== "1" ? closeArrow : activeArrow}
                      alt="Icon"
                    />
                  ) : (
                    ""
                  )}
                </CustomToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <div className="box-grid-three">
                    <Form.Group
                      className={`form-group ${
                        errors?.spouseInformation?.sFirstName ? "error-msg" : ""
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>First Name*</Form.Label>
                      <Form.Control
                        type="text"
                        name="sFirstName"
                        {...register("spouseInformation.sFirstName")}
                        disabled={spouseDis ? spouseDis : false}
                        defaultValue={
                          personalInformation?.spouseInfo?.firstName
                            ? personalInformation?.spouseInfo?.firstName
                            : personalInformation?.sponsoredSpouseInfo
                                ?.firstName
                        }
                      />
                      {errors?.spouseInformation?.sFirstName ? (
                        <span className="error-msg">
                          {errors?.spouseInformation?.sFirstName?.message}
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                    <Form.Group
                      className={`form-group`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Middle Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="sMidName"
                        disabled={spouseDis ? spouseDis : false}
                        defaultValue={
                          personalInformation?.spouseInfo?.middleName
                            ? personalInformation?.spouseInfo?.middleName
                            : personalInformation?.sponsoredSpouseInfo
                                ?.middleName
                        }
                        {...register("spouseInformation.sMidName")}
                      />
                      {errors?.spouseInformation?.sMidName ? (
                        <span className="error-msg">
                          {errors?.spouseInformation?.sMidName?.message}
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                    <Form.Group
                      className={`form-group ${
                        errors?.spouseInformation?.sLastName ? "error-msg" : ""
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Last Name*</Form.Label>
                      <Form.Control
                        type="text"
                        name="sLastName"
                        disabled={spouseDis ? spouseDis : false}
                        defaultValue={
                          personalInformation?.spouseInfo?.lastName
                            ? personalInformation?.spouseInfo?.lastName
                            : personalInformation?.sponsoredSpouseInfo?.lastName
                        }
                        {...register("spouseInformation.sLastName")}
                      />
                      {errors?.spouseInformation?.sLastName ? (
                        <span className="error-msg">
                          {errors?.spouseInformation?.sLastName?.message}
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </div>
                  <div className="box-grid-three">
                    <Form.Group
                      className={`form-group ${
                        errors?.spouseInformation?.sGender ? "error-msg" : ""
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Gender*</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="sGender"
                        disabled={spouseDis ? spouseDis : false}
                        defaultValue={
                          personalInformation?.spouseInfo
                            ? personalInformation?.spouseInfo?.gender
                            : personalInformation?.sponsoredSpouseInfo?.gender
                            ? personalInformation?.sponsoredSpouseInfo?.gender
                            : ""
                        }
                        {...register("spouseInformation.sGender")}
                      >
                        <option value="">select</option>
                        <option value="male">Male</option>
                        <option value="female">Female</option>
                      </Form.Select>
                      {errors?.spouseInformation?.sGender ? (
                        <span className="error-msg">
                          {errors?.spouseInformation?.sGender?.message}
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Group>

                    <Form.Group
                      className={`form-group ${
                        errors?.spouseInformation?.sBirthCountry?.message
                          ? "error-msg"
                          : ""
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Birth Country*</Form.Label>
                      <Form.Select
                        //onChange={handleSelectChange}
                        disabled={spouseDis ? spouseDis : false}
                        name="sBirthCountry"
                        defaultValue={
                          personalInformation?.spouseInfo
                            ? personalInformation?.spouseInfo?.birthCountry
                            : personalInformation?.sponsoredSpouseInfo
                                ?.birthCountry
                            ? personalInformation?.sponsoredSpouseInfo
                                ?.birthCountry
                            : userGeoData?.country_name
                        }
                        {...register("spouseInformation.sBirthCountry")}
                      >
                        <option value={userGeoData?.country_name}>
                          {personalInformation?.spouseInfo
                            ? personalInformation?.spouseInfo?.birthCountry
                            : personalInformation?.sponsoredSpouseInfo
                                ?.birthCountry
                            ? personalInformation?.sponsoredSpouseInfo
                                ?.birthCountry
                            : userGeoData?.country_name}
                        </option>
                        {countries &&
                          countries?.length > 0 &&
                          countries.map((el) => (
                            <option value={el?.name}>{el?.name}</option>
                          ))}
                      </Form.Select>
                      {errors?.spouseInformation?.sBirthCountry ? (
                        <span className="error-msg">
                          {errors?.spouseInformation?.sBirthCountry?.message}
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Group>

                    <Form.Group
                      className={`form-group ${
                        error?.sDateOfBirth?.message ? "error-msg" : ""
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Date of Birth*</Form.Label>
                      <DatePicker
                        type="sDateOfBirth"
                        name="sDateOfBirth"
                        placeholderText="Select Date"
                        // selected={sDateOfBirth}
                        selected={sDateOfBirth}
                        disabled={spouseDis ? spouseDis : false}
                        onChange={(date) =>
                          changeDatePicker(date, "sDateOfBirth", "sAge")
                        }
                        className="form-select"
                        dateFormat="dd-MM-yyyy"
                        peekNextMonth
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                      <input
                        ref={inputRef}
                        value={sDateOfBirth}
                        type="hidden"
                        {...register("spouseInformation.sDateOfBirth")}
                      />
                      {errors?.spouseInformation?.sDateOfBirth ? (
                        <span className="error-msg">
                          {errors?.spouseInformation?.sDateOfBirth?.message}
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </div>
                  <div className="box-grid-three">
                    <Form.Group
                      className={`form-group ${
                        errors?.spouseInformation?.sPhoneNumber
                          ? "error-msg"
                          : ""
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Phone*</Form.Label>
                      {/* <Form.Control type="email" placeholder="" /> */}
                      <Form.Group>
                        <PhoneInput
                          country={
                            personalInformation?.spouseInfo?.phoneNumber
                              ? ""
                              : personalInformation?.sponsoredSpouseInfo
                                  ?.phoneNumber
                              ? ""
                              : userGeoData?.country_code?.toLowerCase()
                          }
                          value={
                            personalInformation?.spouseInfo?.phoneNumber
                              ? personalInformation?.spouseInfo?.phoneNumber?.substring(
                                  1
                                )
                              : personalInformation?.sponsoredSpouseInfo?.phoneNumber?.substring(
                                  1
                                )
                          }
                          disabled={spouseDis ? spouseDis : false}
                          onChange={(e) =>
                            setValue("spouseInformation.sPhoneNumber", "+" + e)
                          }
                          inputProps={{
                            name: "sPhoneNumber",
                          }}
                        />
                        {errors?.spouseInformation?.sPhoneNumber ? (
                          <span className="error-msg">
                            {errors?.spouseInformation?.sPhoneNumber?.message}
                          </span>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                    </Form.Group>
                    <Form.Group
                      className={`form-group`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Mobile</Form.Label>
                      <PhoneInput
                        country={
                          personalInformation?.spouseInfo?.mobile
                            ? ""
                            : personalInformation?.sponsoredSpouseInfo?.mobile
                            ? ""
                            : userGeoData?.country_code?.toLowerCase()
                        }
                        value={
                          personalInformation?.spouseInfo?.mobile
                            ? personalInformation?.spouseInfo?.mobile?.substring(
                                1
                              )
                            : personalInformation?.sponsoredSpouseInfo?.mobile?.substring(
                                1
                              )
                        }
                        disabled={spouseDis ? spouseDis : false}
                        onChange={(e) =>
                          setValue("spouseInformation.sMobile", "+" + e)
                        }
                        inputProps={{
                          name: "sMobile",
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      className={`form-group`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Office</Form.Label>
                      <PhoneInput
                        country={
                          personalInformation?.spouseInfo?.officePhoneNumber
                            ? ""
                            : personalInformation?.sponsoredSpouseInfo
                                ?.officePhoneNumber
                            ? ""
                            : userGeoData?.country_code?.toLowerCase()
                        }
                        value={
                          personalInformation?.spouseInfo?.officePhoneNumber
                            ? personalInformation?.spouseInfo?.officePhoneNumber?.substring(
                                1
                              )
                            : personalInformation?.sponsoredSpouseInfo?.officePhoneNumber?.substring(
                                1
                              )
                        }
                        disabled={spouseDis ? spouseDis : false}
                        onChange={(e) =>
                          setValue(
                            "spouseInformation.sOfficePhoneNumber",
                            "+" + e
                          )
                        }
                        inputProps={{
                          name: "sOfficePhoneNumber",
                        }}
                      />
                    </Form.Group>
                  </div>
                  <div className="box-grid-two">
                    <Form.Group
                      className={`form-group ${
                        errors?.spouseInformation?.sPrimaryEmail?.message
                          ? "error-msg"
                          : ""
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Primary Email*</Form.Label>
                      <Form.Control
                        type="email"
                        name="sPrimaryEmail"
                        disabled={spouseDis ? spouseDis : false}
                        defaultValue={
                          personalInformation?.spouseInfo?.primaryEmail
                            ? personalInformation?.spouseInfo?.primaryEmail
                            : personalInformation?.sponsoredSpouseInfo
                                ?.primaryEmail
                        }
                        {...register("spouseInformation.sPrimaryEmail")}
                      />
                      {errors?.spouseInformation?.sPrimaryEmail ? (
                        <span className="error-msg">
                          {errors?.spouseInformation?.sPrimaryEmail?.message}
                        </span>
                      ) : (
                        ""
                      )}
                      {/* <p>
                    <i class="las la-exclamation-triangle"></i>Please enter an
                    email address in the format{' '}
                    <span> username@domain.com</span>
                  </p> */}
                    </Form.Group>

                    <Form.Group
                      className={`form-group`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Secondary Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="sSecondaryEmail"
                        disabled={spouseDis ? spouseDis : false}
                        defaultValue={
                          personalInformation?.spouseInfo?.secondaryEmail
                            ? personalInformation?.spouseInfo?.secondaryEmail
                            : personalInformation?.sponsoredSpouseInfo
                                ?.secondaryEmail
                        }
                        {...register("spouseInformation.sSecondaryEmail")}
                      />
                      {errors?.spouseInformation?.sSecondaryEmail ? (
                        <span className="error-msg">
                          {errors?.spouseInformation?.sSecondaryEmail?.message}
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </div>
                  <div className="box-grid-three">
                    <Form.Group
                      className={`form-group ${
                        errors?.spouseInformation?.sCountryCitizenship?.message
                          ? "error-msg"
                          : ""
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Country of Citizenship*</Form.Label>
                      <Form.Select
                        //onChange={handleSelectChange}
                        disabled={spouseDis ? spouseDis : false}
                        name="sCountryCitizenship"
                        defaultValue={
                          personalInformation?.spouseInfo
                            ? personalInformation?.spouseInfo
                                ?.countryCitizenship
                            : personalInformation?.sponsoredSpouseInfo
                                ?.countryCitizenship
                            ? personalInformation?.sponsoredSpouseInfo
                                ?.countryCitizenship
                            : userGeoData?.country_name
                        }
                        {...register("spouseInformation.sCountryCitizenship")}
                      >
                        <option value={userGeoData?.country_name}>
                          {personalInformation?.spouseInfo
                            ? personalInformation?.spouseInfo
                                ?.countryCitizenship
                            : personalInformation?.sponsoredSpouseInfo
                                ?.countryCitizenship
                            ? personalInformation?.sponsoredSpouseInfo
                                ?.countryCitizenship
                            : userGeoData?.country_name}
                        </option>
                        {countries &&
                          countries?.length > 0 &&
                          countries.map((el) => (
                            <option value={el?.name}>{el?.name}</option>
                          ))}
                      </Form.Select>
                      {errors?.spouseInformation?.sCountryCitizenship ? (
                        <span className="error-msg">
                          {
                            errors?.spouseInformation?.sCountryCitizenship
                              ?.message
                          }
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                    <Form.Group
                      className={`form-group ${
                        errors?.sSecondCitizenship ? "error-msg" : ""
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Second Citizenship</Form.Label>
                      <Form.Select
                        name="sSecondCitizenship"
                        disabled={mainApplicanDis ? mainApplicanDis : false}
                        defaultValue={
                          personalInformation?.mainApplicant?.sSecondCitizenship
                            ? personalInformation?.mainApplicant
                                ?.sSecondCitizenship
                            : personalInformation?.sponsoredInformation
                                ?.sSecondCitizenship
                            ? personalInformation?.sponsoredInformation
                                ?.sSecondCitizenship
                            : ""
                        }
                        {...register("secondCitizenship")}
                      >
                        <option value="">Select country</option>
                        {countries &&
                          countries?.length > 0 &&
                          countries.map((el) => (
                            <option value={el?.name}>{el?.name}</option>
                          ))}
                      </Form.Select>
                      {errors?.sSecondCitizenship ? (
                        <span className="error-msg">
                          {errors?.sSecondCitizenship?.message}
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Group>

                    <Form.Group
                      className={`form-group ${
                        errors?.spouseInformation?.sCountryResidence?.message
                          ? "error-msg"
                          : ""
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Country of Residence*</Form.Label>
                      <Form.Select
                        name="sCountryResidence"
                        disabled={spouseDis ? spouseDis : false}
                        defaultValue={
                          personalInformation?.spouseInfo
                            ? personalInformation?.spouseInfo?.countryResidence
                            : personalInformation?.sponsoredSpouseInfo
                                ?.countryResidence
                            ? personalInformation?.sponsoredSpouseInfo
                                ?.countryResidence
                            : userGeoData?.country_name
                        }
                        {...register("spouseInformation.sCountryResidence")}
                      >
                        <option value={userGeoData?.country_name}>
                          {personalInformation?.spouseInfo
                            ? personalInformation?.spouseInfo?.countryResidence
                            : personalInformation?.sponsoredSpouseInfo
                                ?.countryResidence
                            ? personalInformation?.sponsoredSpouseInfo
                                ?.countryResidence
                            : userGeoData?.country_name}
                        </option>
                        {countries &&
                          countries?.length > 0 &&
                          countries.map((el) => (
                            <option value={el?.name}>{el?.name}</option>
                          ))}
                      </Form.Select>
                      {errors?.spouseInformation?.sCountryResidence ? (
                        <span className="error-msg">
                          {errors?.spouseInformation.sCountryResidence.message}
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </div>
                  <div className="box-grid-three">
                    <Form.Group
                      className={`form-group ${
                        errors?.spouseInformation?.sCity?.message
                          ? "error-msg"
                          : ""
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>City*</Form.Label>
                      <Form.Control
                        type="text"
                        name="sCity"
                        disabled={spouseDis ? spouseDis : false}
                        defaultValue={
                          personalInformation?.spouseInfo?.city
                            ? personalInformation?.spouseInfo?.city
                            : personalInformation?.sponsoredSpouseInfo?.city
                        }
                        {...register("spouseInformation.sCity")}
                      />
                      {errors?.spouseInformation?.sCity ? (
                        <span className="error-msg">
                          {errors?.spouseInformation?.sCity?.message}
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                    <Form.Group
                      className={`form-group ${
                        errors?.spouseInformation?.sStreet?.message
                          ? "error-msg"
                          : ""
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Street*</Form.Label>
                      <Form.Control
                        type="text"
                        name="sStreet"
                        disabled={spouseDis ? spouseDis : false}
                        defaultValue={
                          personalInformation?.spouseInfo?.street
                            ? personalInformation?.spouseInfo?.street
                            : personalInformation?.sponsoredSpouseInfo?.street
                        }
                        {...register("spouseInformation.sStreet")}
                      />
                      {errors?.spouseInformation?.sStreet ? (
                        <span className="error-msg">
                          {errors?.spouseInformation.sStreet.message}
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                    <Form.Group
                      className={`form-group ${
                        errors?.spouseInformation?.sZipCode?.message
                          ? "error-msg"
                          : ""
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Zip Code / Postal Code*</Form.Label>
                      <Form.Control
                        type="number"
                        name="sZipCode"
                        disabled={spouseDis ? spouseDis : false}
                        defaultValue={
                          personalInformation?.spouseInfo?.zipCode
                            ? personalInformation?.spouseInfo?.zipCode
                            : personalInformation?.sponsoredSpouseInfo?.zipCode
                        }
                        {...register("spouseInformation.sZipCode")}
                      />
                      {errors?.spouseInformation?.sZipCode ? (
                        <span className="error-msg">
                          {errors?.spouseInformation?.sZipCode?.message}
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </div>
                  <div className="box-grid-two">
                    <Form.Group
                      className={`form-group ${
                        errors?.spouseInformation?.sMaritalStatus?.message
                          ? "error-msg"
                          : ""
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Marital Status*</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="sMaritalStatus"
                        disabled={spouseDis ? spouseDis : false}
                        defaultValue={
                          personalInformation?.spouseInfo?.maritalStatus
                            ? personalInformation?.spouseInfo?.maritalStatus
                            : personalInformation?.sponsoredSpouseInfo
                                ?.maritalStatus
                            ? personalInformation?.sponsoredSpouseInfo
                                ?.maritalStatus
                            : ""
                        }
                        {...register("spouseInformation.sMaritalStatus")}
                      >
                        <option value="">Select</option>
                        <option value="Single">Single</option>
                        <option value="2">Married</option>
                        <option value="Married">
                          Common-Law Partnership{" "}
                          <span>
                            (you are living together for at least 1 year)
                          </span>
                        </option>
                        <option value="Legally Separated">
                          Legally Separated
                        </option>
                        <option value="Divorced">Divorced</option>
                        <option value="Widowed">Widowed</option>
                      </Form.Select>
                      {errors?.spouseInformation?.sMaritalStatus ? (
                        <span className="error-msg">
                          {errors?.spouseInformation.sMaritalStatus.message}
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Group>

                    <Form.Group
                      className={`form-group ${
                        errors?.spouseInformation?.sDependentChildren?.message
                          ? "error-msg"
                          : ""
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>
                        Number of dependent children under 22
                      </Form.Label>
                      <Form.Control
                        aria-label="Default select example"
                        type="text"
                        name="sDependentChildren"
                        disabled={spouseDis ? spouseDis : false}
                        defaultValue={
                          personalInformation?.spouseInfo?.dependentChildren?.toString()
                            ? personalInformation?.spouseInfo?.dependentChildren?.toString()
                            : personalInformation?.sponsoredSpouseInfo?.dependentChildren?.toString()
                            ? personalInformation?.sponsoredSpouseInfo?.dependentChildren?.toString()
                            : ""
                        }
                        {...register("spouseInformation.sDependentChildren")}
                      />

                      {errors?.spouseInformation?.sDependentChildren ? (
                        <span className="error-msg">
                          {
                            errors?.spouseInformation?.sDependentChildren
                              ?.message
                          }
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </div>
                  <div className="box-grid-two">
                    <Form.Group
                      className={`form-group ${
                        errors?.spouseInformation?.sCriminalOffense
                          ? "error-msg"
                          : ""
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>
                        Have you had any criminal offenses lodged against you?*
                      </Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="sCriminalOffense"
                        disabled={spouseDis ? spouseDis : false}
                        defaultValue={
                          personalInformation?.spouseInfo?.criminalOffense
                            ? personalInformation?.spouseInfo?.criminalOffense
                            : personalInformation?.sponsoredSpouseInfo
                                ?.criminalOffense
                            ? personalInformation?.sponsoredSpouseInfo
                                ?.criminalOffense
                            : ""
                        }
                        {...register("spouseInformation.sCriminalOffense")}
                      >
                        <option value="">Select</option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </Form.Select>
                      {errors?.spouseInformation?.sCriminalOffense ? (
                        <span className="error-msg">
                          {errors?.spouseInformation.sCriminalOffense.message}
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                    {sposeCriminalOffense === "Yes" ||
                    personalInformation?.spouseInfo?.criminalOffense ===
                      "Yes" ||
                    personalInformation?.sponsoredSpouseInfo
                      ?.criminalOffense === "Yes" ? (
                      <Form.Group
                        className={`form-group ${
                          errors?.sOffenseTypeDetail?.message ? "error-msg" : ""
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>
                          Please describe your criminal offenses from the past
                          10 years
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="sOffenseTypeDetail"
                          disabled={spouseDis ? spouseDis : false}
                          defaultValue={
                            personalInformation?.spouseInfo?.offenseTypeDetail
                              ? personalInformation?.spouseInfo
                                  ?.offenseTypeDetail
                              : personalInformation?.sponsoredSpouseInfo
                                  ?.offenseTypeDetail
                          }
                          {...register("spouseInformation.sOffenseTypeDetail")}
                        />
                        {errors?.spouseInformation?.sOffenseTypeDetail ? (
                          <span className="error-msg">
                            {
                              errors?.spouseInformation.sOffenseTypeDetail
                                .message
                            }
                          </span>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="box-grid-two">
                    <Form.Group
                      className={`form-group ${
                        errors?.spouseInformation?.sHealthCondition?.message
                          ? "error-msg"
                          : ""
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Health Problems*</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="sHealthCondition"
                        disabled={spouseDis ? spouseDis : false}
                        //onChange={handleSpouseSelect}
                        defaultValue={
                          personalInformation?.spouseInfo?.healthCondition
                            ? personalInformation?.spouseInfo?.healthCondition
                            : personalInformation?.sponsoredSpouseInfo
                                ?.healthCondition
                            ? personalInformation?.sponsoredSpouseInfo
                                ?.healthCondition
                            : ""
                        }
                        {...register("spouseInformation.sHealthCondition")}
                      >
                        <option value="">Select</option>
                        <option value="No">No</option>
                        <option value="Yes">Yes</option>
                      </Form.Select>
                      {errors?.spouseInformation?.sHealthCondition ? (
                        <span className="error-msg">
                          {errors?.spouseInformation.sHealthCondition.message}
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                    {(spouseHealthCondition &&
                      spouseHealthCondition === "Yes") ||
                    personalInformation?.spouseInfo?.healthCondition ===
                      "Yes" ||
                    personalInformation?.sponsoredSpouseInfo
                      ?.healthCondition === "Yes" ? (
                      <Form.Group
                        className={`form-group ${
                          errors?.spouseInformation?.sHealthConditionDetail
                            ?.message
                            ? "error-msg"
                            : ""
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>
                          If yes, Please describe your health condition/s
                        </Form.Label>
                        <Form.Control
                          type="text"
                          name="sHealthConditionDetail"
                          disabled={spouseDis ? spouseDis : false}
                          defaultValue={
                            personalInformation?.spouseInfo
                              ?.healthConditionDetail
                              ? personalInformation?.spouseInfo
                                  ?.healthConditionDetail
                              : personalInformation?.sponsoredSpouseInfo
                                  ?.healthConditionDetail
                          }
                          {...register(
                            "spouseInformation.sHealthConditionDetail"
                          )}
                        />
                        {errors?.spouseInformation?.sHealthConditionDetail ? (
                          <span className="error-msg">
                            {
                              errors?.spouseInformation.sHealthConditionDetail
                                .message
                            }
                          </span>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                    ) : (
                      ""
                    )}
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </div>
          <div
            className={`accordion-list ${
              childrenFormState === "open" ? "change-accordion-icon" : ""
            }`}
          >
            <Card>
              <Card.Header
                className={customAccordBtn === "2" ? "accord-open" : ""}
                onClick={() =>
                  childrenFormState === "open" || childrenInfoDis
                    ? handleClose("childrenFormState", "person-2")
                    : ""
                }
              >
                <div className="accordian-title">
                  {childrenFormState === "open" || childrenInfoDis
                    ? "Children Information"
                    : "Add Children Information"}
                  {childrenFormState === "open" && !childrenInfoDis && (
                    <div className="delete-btn">
                      <Button
                        onClick={() =>
                          handleDelete("childrenFormState", "person-2")
                        }
                      >
                        Delete <i className="las la-minus"></i>
                      </Button>
                    </div>
                  )}
                </div>

                <CustomToggle eventKey="2">
                  {childrenFormState === "plus" && !childrenInfoDis ? (
                    <img src={addIcon} alt="Add Icon" />
                  ) : (childrenFormState === "open" && !childrenInfoDis) ||
                    (childrenFormState === "plus" && childrenInfoDis) ||
                    (childrenFormState === "open" && childrenInfoDis) ||
                    (childrenFormState === "plus" && !childrenInfoDis) ? (
                    <img
                      className="arrow-icon"
                      src={customAccordBtn !== "2" ? closeArrow : activeArrow}
                      alt="Icon"
                    />
                  ) : (
                    ""
                  )}
                </CustomToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body className="accord-cardbody-scnd">
                  {childs && childs?.length > 0
                    ? childs?.map((child, i) => (
                        <Card.Body key={i}>
                          <h6 className="child-heading">Child #{i + 1}</h6>
                          <duiv className="child-info">
                            <div className="box-grid-three">
                              <Form.Group
                                className={`form-group ${
                                  errors?.childrenInfo &&
                                  errors?.childrenInfo[`${i}`]?.childFirstName
                                    ? "error-msg"
                                    : ""
                                }`}
                                controlId="formBasicEmail"
                              >
                                <Form.Label>First Name*</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="childFirstName"
                                  disabled={
                                    childrenInfoDis ? childrenInfoDis : false
                                  }
                                  defaultValue={child?.childFirstName}
                                  onChange={(e) => handleChange(e, i)}
                                />
                                {errors?.childrenInfo &&
                                errors?.childrenInfo[`${i}`]?.childFirstName ? (
                                  <span className="error-msg">
                                    {
                                      errors?.childrenInfo[`${i}`]
                                        ?.childFirstName?.message
                                    }
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Form.Group>
                              <Form.Group
                                className={`form-group`}
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Middle Name</Form.Label>
                                <Form.Control
                                  type="text"
                                  name="childMiddleName"
                                  disabled={
                                    childrenInfoDis ? childrenInfoDis : false
                                  }
                                  // {...register('childMiddleName', {
                                  //   required: 'Middle name is required',
                                  // })}
                                  defaultValue={child?.childMiddleName}
                                  onChange={(e) => handleChange(e, i)}
                                />
                                {error && error[`[${i}].childMiddleName`] ? (
                                  <span className="error-msg">
                                    {error[`[${i}].childMiddleName`]}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Form.Group>
                              <Form.Group
                                className={`form-group ${
                                  errors?.childrenInfo &&
                                  errors?.childrenInfo[`${i}`]?.childLastName
                                    ? "error-msg"
                                    : ""
                                }`}
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Last Name*</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder=""
                                  name="childLastName"
                                  disabled={
                                    childrenInfoDis ? childrenInfoDis : false
                                  }
                                  defaultValue={child?.childLastName}
                                  onChange={(e) => handleChange(e, i)}
                                />
                                {errors?.childrenInfo &&
                                errors?.childrenInfo[`${i}`]?.childLastName ? (
                                  <span className="error-msg">
                                    {
                                      errors?.childrenInfo[`${i}`]
                                        ?.childLastName?.message
                                    }
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Form.Group>
                            </div>
                            <div className="box-grid-two">
                              <Form.Group
                                className={`form-group ${
                                  errors?.childrenInfo &&
                                  errors?.childrenInfo[`${i}`]?.childGender
                                    ? "error-msg"
                                    : ""
                                }`}
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Gender*</Form.Label>
                                <Form.Select
                                  aria-label="Default select example"
                                  name="childGender"
                                  disabled={
                                    childrenInfoDis ? childrenInfoDis : false
                                  }
                                  defaultValue={
                                    child?.childGender ? child?.childGender : ""
                                  }
                                  onChange={(e) => handleChange(e, i)}
                                >
                                  <option value="">Select Gender</option>
                                  <option value="male">Male</option>
                                  <option value="female">Female</option>
                                </Form.Select>
                                {errors?.childrenInfo &&
                                errors?.childrenInfo[`${i}`]?.childGender ? (
                                  <span className="error-msg">
                                    {
                                      errors?.childrenInfo[`${i}`]?.childGender
                                        ?.message
                                    }
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Form.Group>

                              <Form.Group
                                className={`form-group ${
                                  errors?.childrenInfo &&
                                  errors?.childrenInfo[`${i}`]?.childDateOfBirth
                                    ? "error-msg"
                                    : ""
                                }`}
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Date of Birth*</Form.Label>
                                <DatePicker
                                  type="childDateOfBirth"
                                  name="childDateOfBirth"
                                  placeholderText="Select Date"
                                  disabled={
                                    childrenInfoDis ? childrenInfoDis : false
                                  }
                                  selected={
                                    child?.childDateOfBirth
                                      ? new Date(child?.childDateOfBirth)
                                      : new Date()
                                  }
                                  onChange={(date) =>
                                    handleChildDatePicker(date, i)
                                  }
                                  className="form-select"
                                  dateFormat="dd-MM-yyyy"
                                  peekNextMonth
                                  showMonthDropdown
                                  showYearDropdown
                                  dropdownMode="select"
                                />

                                {errors?.childrenInfo &&
                                errors?.childrenInfo[`${i}`]
                                  ?.childDateOfBirth ? (
                                  <span className="error-msg">
                                    {
                                      errors?.childrenInfo[`${i}`]
                                        ?.childDateOfBirth?.message
                                    }
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Form.Group>
                            </div>
                            <div className="box-grid-two">
                              <Form.Group
                                className={`form-group ${
                                  errors?.childrenInfo &&
                                  errors?.childrenInfo[`${i}`]
                                    ?.childBirthCountry
                                    ? "error-msg"
                                    : ""
                                }`}
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Birth Country*</Form.Label>
                                <Form.Select
                                  name="childBirthCountry"
                                  disabled={
                                    childrenInfoDis ? childrenInfoDis : false
                                  }
                                  onChange={(e) => handleChange(e, i)}
                                >
                                  <option
                                    value={
                                      child?.childBirthCountry !== ""
                                        ? child?.childBirthCountry
                                        : userGeoData?.country_name
                                    }
                                  >
                                    {child?.childBirthCountry !== ""
                                      ? child?.childBirthCountry
                                      : userGeoData?.country_name}
                                  </option>
                                  {countries &&
                                    countries?.length > 0 &&
                                    countries.map((el) => (
                                      <option value={el?.name}>
                                        {el?.name}
                                      </option>
                                    ))}
                                </Form.Select>
                                {errors?.childrenInfo &&
                                errors?.childrenInfo[`${i}`]
                                  ?.childBirthCountry ? (
                                  <span className="error-msg">
                                    {
                                      errors?.childrenInfo[`${i}`]
                                        ?.childBirthCountry?.message
                                    }
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Form.Group>

                              <Form.Group
                                className={`form-group ${
                                  errors?.childrenInfo &&
                                  errors?.childrenInfo[`${i}`]?.childBirthCity
                                    ? "error-msg"
                                    : ""
                                }`}
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Birth City*</Form.Label>
                                <Form.Control
                                  type="text"
                                  placeholder=""
                                  name="childBirthCity"
                                  disabled={
                                    childrenInfoDis ? childrenInfoDis : false
                                  }
                                  onChange={(e) => handleChange(e, i)}
                                  defaultValue={child?.childBirthCity}
                                />
                                {errors?.childrenInfo &&
                                errors?.childrenInfo[`${i}`]?.childBirthCity ? (
                                  <span className="error-msg">
                                    {
                                      errors?.childrenInfo[`${i}`]
                                        ?.childBirthCity?.message
                                    }
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Form.Group>
                            </div>
                            <div className="box-grid-two">
                              <Form.Group
                                className={`form-group ${
                                  errors?.childrenInfo &&
                                  errors?.childrenInfo[`${i}`]?.childCitizenship
                                    ? "error-msg"
                                    : ""
                                }`}
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Citizenship*</Form.Label>
                                <Form.Select
                                  name="childCitizenship"
                                  disabled={
                                    childrenInfoDis ? childrenInfoDis : false
                                  }
                                  onChange={(e) => handleChange(e, i)}
                                >
                                  <option
                                    value={
                                      child?.childCitizenship
                                        ? child?.childCitizenship
                                        : userGeoData?.country_name
                                    }
                                  >
                                    {child?.childCitizenship
                                      ? child?.childCitizenship
                                      : userGeoData?.country_name}
                                  </option>
                                  {countries &&
                                    countries?.length > 0 &&
                                    countries.map((el) => (
                                      <option value={el?.name}>
                                        {el?.name}
                                      </option>
                                    ))}
                                </Form.Select>
                                {errors?.childrenInfo &&
                                errors?.childrenInfo[`${i}`]
                                  ?.childCitizenship ? (
                                  <span className="error-msg">
                                    {
                                      errors?.childrenInfo[`${i}`]
                                        ?.childCitizenship?.message
                                    }
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Form.Group>

                              <Form.Group
                                className={`form-group ${
                                  errors?.childrenInfo &&
                                  errors?.childrenInfo[`${i}`]
                                    ?.childSecondCitizenship
                                    ? "error-msg"
                                    : ""
                                }`}
                                controlId="formBasicEmail"
                              >
                                <Form.Label>Second Citizenship</Form.Label>
                                <Form.Select
                                  name="childSecondCitizenship"
                                  disabled={
                                    childrenInfoDis ? childrenInfoDis : false
                                  }
                                  defaultValue={
                                    child?.childSecondCitizenship
                                      ? child?.childSecondCitizenship
                                      : ""
                                  }
                                  onChange={(e) => handleChange(e, i)}
                                >
                                  <option value="">Select Country</option>
                                  {countries &&
                                    countries?.length > 0 &&
                                    countries.map((el) => (
                                      <option value={el?.name}>
                                        {el?.name}
                                      </option>
                                    ))}
                                </Form.Select>
                                {errors?.childrenInfo &&
                                errors?.childrenInfo[`${i}`]
                                  ?.childSecondCitizenship ? (
                                  <span className="error-msg">
                                    {
                                      errors?.childrenInfo[`${i}`]
                                        ?.childSecondCitizenship?.message
                                    }
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Form.Group>
                            </div>
                            <div
                              className="add-btn"
                              disabled={
                                childrenInfoDis ? childrenInfoDis : false
                              }
                            >
                              {i >= 0 &&
                                (!childrenInfoDis ? (
                                  <Button
                                    className="delete-btn"
                                    disabled={
                                      childrenInfoDis ? childrenInfoDis : false
                                    }
                                    onClick={() => removeChild(i)}
                                  >
                                    {`Delete Child #${i + 1}`}{" "}
                                    <i class="las la-minus"></i>
                                  </Button>
                                ) : (
                                  ""
                                ))}
                              <Button
                                onClick={handleAddChild}
                                disabled={
                                  childrenInfoDis ? childrenInfoDis : false
                                }
                              >
                                Add <i className="las la-plus"></i>
                              </Button>
                            </div>
                          </duiv>
                        </Card.Body>
                      ))
                    : ""}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </div>
        </Accordion>
        <div className="visa-btn">
          {!mainApplicanDis ? (
            <>
              <Button
                type="submit"
                onClick={() => {
                  handleSubmit(submitPersonalInfo);
                }}
                disabled={mainApplicanDis ? mainApplicanDis : false}
                className={`${mainApplicanDis ? "success-btn" : ""}`}
              >
                Save {mainApplicanDis && <img src={approved} alt="approved" />}
              </Button>
              <div className="visa-desc-text">
                <p>
                  Once your form is completed, you will be able to make new
                  changes by contacting our Support team.
                </p>
                <p>When you're ready, click "Submit"!</p>
              </div>
            </>
          ) : (
            <div className="for-new-changes">
              <p>
                <span>Important! </span> Please contact our Support team if you
                would like to make new changes .
              </p>
            </div>
          )}
        </div>
      </Form>
      <ErrorModal show={showErrorModal} handleClose={handleShowErrorModal} />
    </div>
  );
};
export default PersonalInfo;
