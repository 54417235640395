export const workingHolydayVisaGenMain = [
  {
    name: 'frontPassportCopy',
    label: 'Passport Copy',
    secondLabel: '(front page + stamp / visa pages if any)',
    isRequired: true,
  },
  {
    name: 'updatedCv',
    label: 'Updated C.V',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'digitalPhoto',
    label: 'Digital Photo',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'proofOfAddress',
    label: 'Proof of Address',
    secondLabel: '(example: water, gas, or electricity bill)',
    isRequired: true,
  },
]
export const studentVisaGenMain = [
  {
    name: 'frontPassportCopy',
    label: 'Passport Copy',
    secondLabel: '(front page + stamp / visa pages if any)',
    isRequired: true,
  },
  {
    name: 'updatedCv',
    label: 'Updated C.V',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'digitalPhoto',
    label: 'Digital Photo',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'birthCertificate',
    label: 'Copy of Birth Certificate',
    secondLabel: '',
    isRequired: true,
  },
]
export const touristVisaGenMain = [
  {
    name: 'frontPassportCopy',
    label: 'Passport Copy',
    secondLabel: '(front page + stamp / visa pages if any)',
    isRequired: true,
  },
  {
    name: 'updatedCv',
    label: 'Updated C.V',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'digitalPhoto',
    label: 'Digital Photo',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'proofOfAddress',
    label: 'Proof of Address',
    secondLabel: '(example: water, gas, or electricity bill)',
    isRequired: true,
  },
  {
    name: 'marriageCertificate',
    label: 'Marriage Certificate',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'proofOffOwnProperty',
    label: 'Proof you own properties in your country of origin',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
]
export const pnpExpressEntryVisaGenMain = [
  {
    name: 'frontPassportCopy',
    label: 'Passport Copy',
    secondLabel: '(front page + stamp / visa pages if any)',
    isRequired: true,
  },
  {
    name: 'updatedCv',
    label: 'Updated C.V',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'digitalPhoto',
    label: 'Digital Photo',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'birthCertificate',
    label: 'Copy of Birth Certificate',
    secondLabel: '',
    isRequired: true,
  },
]
export const investorVisaGenMain = [
  {
    name: 'frontPassportCopy',
    label: 'Passport Copy',
    secondLabel: '(front page + stamp / visa pages if any)',
    isRequired: true,
  },
  {
    name: 'updatedCv',
    label: 'Updated C.V',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'twoDigitalPhoto',
    label: 'Two Digital Photo',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'birthCertificate',
    label: 'Copy of Birth Certificate',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'legalDocumentOfName',
    label: 'Legal Document of Name or Date of Birth Changes',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'proofOfAddress',
    label: 'Proof of Address',
    secondLabel: '(example: water, gas, or electricity bill)',
    isRequired: true,
  },
  {
    name: 'marriageCertificate',
    label: 'Marriage Certificate',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'divorceCertificate',
    label: 'Divorce certificate',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'deathCertificate',
    label: 'Death Certificate',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
]
export const provincialNomineeVisaGenMain = [
  {
    name: 'frontPassportCopy',
    label: 'Passport Copy',
    secondLabel: '(front page + stamp / visa pages if any)',
    isRequired: true,
  },
  {
    name: 'updatedCv',
    label: 'Updated C.V',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'twoDigitalPhoto',
    label: 'Two Digital Photo',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'birthCertificate',
    label: 'Copy of Birth Certificate',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'marriageCertificate',
    label: 'Marriage Certificate',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'divorceCertificate',
    label: 'Divorce certificate',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'deathCertificate',
    label: 'Death Certificate',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'commonLawDeclaration',
    label: 'Common-Law Declaration',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
]
export const selfEmpVisaGenMain = [
  {
    name: 'frontPassportCopy',
    label: 'Passport Copy',
    secondLabel: '(front page + stamp / visa pages if any)',
    isRequired: true,
  },
  {
    name: 'updatedCv',
    label: 'Updated C.V',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'twoDigitalPhoto',
    label: 'Two Digital Photo',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'birthCertificate',
    label: 'Copy of Birth Certificate',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'legalDocumentOfName',
    label: 'Legal Document of Name or Date of Birth Changes',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'proofOfAddress',
    label: 'Proof of Address',
    secondLabel: '(example: water, gas, or electricity bill)',
    isRequired: true,
  },
  {
    name: 'marriageCertificate',
    label: 'Marriage Certificate',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'divorceCertificate',
    label: 'Divorce certificate',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'deathCertificate',
    label: 'Death Certificate',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
]
