//import React, { useState } from "react";
import ApplicationLayout from "../layout";
//import closeicon from "../../../assets/images/application/close.svg";

const Notification = () => {
  // const [newNotification, setNewnotification] = useState("new");

  // const handleNotification = (value) => {
  //   setNewnotification(value);
  // };

  return (
    <ApplicationLayout>
      <div className={`notification-page`}>
        <div className="container">
          <div className="notification-heading">
            {/* <h3>Notifications</h3> */}
            {/* <div className="notification-tab">
              <h6
                onClick={() => handleNotification("new")}
                className={`${newNotification === "new" ? "active-link" : ""}`}
              >
                New
              </h6>
              <h6
                onClick={() => handleNotification("cleared")}
                className={`${
                  newNotification === "cleared" ? "active-link" : ""
                }`}
              >
                Cleared
              </h6>
            </div> */}
          </div>
          {/* <div
            className={`notification-page-content ${newNotification} scroll-content`}
          >
            <div className={`notification-box-${newNotification}`}>
              <p>Hi there,</p>
              <p>Your application was submitted by legal team.</p>
              <span className="greeting-text"> Good Luck!</span>
              <span className="noti-close-btn">
                <img src={closeicon} alt="" />
              </span>
            </div>

            <div className={`notification-box-${newNotification}`}>
              <p>Hi there, </p>
              <p> Thank you for submitting your documents.</p>
              <p>Our legal team is currently reviewing your application.</p>
              <p>
                If you have any questions, feel free to contact us at any time.
              </p>
              <span className="greeting-text">
                Regards,
                <br /> Legal Team
              </span>
              <span className="noti-close-btn">
                <img src={closeicon} alt="" />
              </span>
            </div>

            <div className={`notification-box-${newNotification}`}>
              <p>Hi there, </p> <p> Congratulations! </p>
              <p> You have been submitted to the Working Holiday pool. </p>
              <p>
                Results are typically delivered within 4 - 8 weeks (although,
                this could take a bit longer…). We will notify you as soon as
                you are selected for a Working Holiday Visa.
              </p>
              <span className="greeting-text">
                Good luck!
                <br /> Customer Support Team
              </span>
              <span className="noti-close-btn">
                <img src={closeicon} alt="" />
              </span>
            </div>
          </div> */}
        </div>
      </div>
    </ApplicationLayout>
  );
};
export default Notification;
