export const workingHolydayVisaLetterClearanceMain = [
  {
    name: 'policeClearance',
    label: 'Police Clearance (see Guidelines)',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'updatedCv',
    label:
      'Proof of Medical Exam From A Canadian Approved Physician (see Guidelines)',
    secondLabel: '',
    isRequired: true,
  },
]
export const workingHolydayVisaGenMain = [
  {
    name: 'frontPassportCopy',
    label: 'Passport Copy',
    secondLabel: '(front page + stamp / visa pages if any)',
    isRequired: true,
  },
  {
    name: 'updatedCv',
    label: 'Updated C.V',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'digitalPhoto',
    label: 'Digital Photo',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'proofOfAddress',
    label: 'Proof of Address',
    secondLabel: '(example: water, gas, or electricity bill)',
    isRequired: true,
  },
]
export const studentVisaLetterClearanceMain = [
  {
    name: 'letterAcceptance',
    label: 'Letter of Acceptance',
    secondLabel: '(front page + stamp / visa pages if any)',
    isRequired: true,
  },
  {
    name: 'policeClearance',
    label: 'Police Clearance (see Guidelines)',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'educationalDiploma',
    label: 'Educational Diploma',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'canadianMedicalExamProof',
    label:
      'Proof of Medical Exam From A Canadian Approved Physician (see Guidelines)',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'langTestResult',
    label: 'Language Test Results',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'visaResident',
    label: 'Copy of Visa or Resident Visa ',
    secondLabel: '(if you are a resident of another country)',
    isRequired: false,
  },
  {
    name: 'letterOfMotive',
    label: 'Letter of Motives (see Guidelines)',
    secondLabel: '',
    isRequired: true,
  },
]
export const touristVisaLetterClearanceMain = [
  {
    name: 'educationalDiploma',
    label: 'Educational Diploma',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'visaResident',
    label: 'Copy of Visa or Resident Visa ',
    secondLabel: '(if you are a resident of another country)',
    isRequired: false,
  },
  {
    name: 'letterOfMotive',
    label: 'Letter of Motives (see Guidelines)',
    secondLabel: '',
    isRequired: true,
  },
]
export const pnpExpressEntryVisaClearanceMain = [
  {
    name: 'policeClearance',
    label: 'Police Clearance (see Guidelines)',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'canadianMedicalExamProof',
    label:
      'Proof of Medical Exam From A Canadian Approved Physician (see Guidelines)',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'langTestResult',
    label: 'Language Test Results',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'educationCredentialAssessment',
    label: 'Educational Credential Assessment (ECA)(WES)',
    secondLabel: '(if applicable)',
    isRequired: false,
  },

  {
    name: 'provincialNomination',
    label: 'Provincial Nomination (if any)',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'writtenJobOffer',
    label: 'Written Job Offer (if any)',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'visaResident',
    label: 'Copy of Visa or Resident Visa ',
    secondLabel: '(if you are a resident of another country)',
    isRequired: false,
  },
  {
    name: 'qualificationCertificate',
    label: 'Certificate of Qualification',
    secondLabel: '',
    isRequired: true,
  },
]
export const inVestorVisaClearanceMain = [
  {
    name: 'policeClearance',
    label: 'Police Clearance (see Guidelines)',
    secondLabel: '(if applicable)',
    isRequired: true,
  },
  {
    name: 'canadianMedicalExamProof',
    label:
      'Proof of Medical Exam From A Canadian Approved Physician (see Guidelines)',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'langTestResult',
    label: 'Language Test Results',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'scheduleABackgroundDecl',
    label: 'Schedule A: Background Declaration IMM 5669',
    secondLabel: '',
    isRequired: true,
  },

  {
    name: 'supplementaryInfo',
    label: 'Supplementary Information - Your Travels IMM 5562',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'schedule13Immigration',
    label:
      'Schedule 13: Business Immigration Programs - Start Up Business Class IMM 0008',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'useRepresentative',
    label: 'Use of a Representative IMM 5476',
    secondLabel: '(if you are a resident of another country)',
    isRequired: false,
  },
  {
    name: 'letterOfSupport',
    label: 'Letter of Support (see Guidelines)',
    secondLabel: '',
    isRequired: true,
  },
]
export const provincialNomineeLetterClearanceMain = [
  {
    name: 'policeClearance',
    label: 'Police Clearance (see Guidelines)',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'canadianMedicalExamProof',
    label:
      'Proof of Medical Exam From A Canadian Approved Physician (see Guidelines)',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'langTestResult',
    label: 'Language Test Results (CLB 5 and 4)',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'educationCredentialAssessment',
    label: 'Educational Credential Assessment (ECA)',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'provincialNomination',
    label: 'Provincial Nomination (if any)',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'writtenJobOffer',
    label: 'Written Job Offer (if any)',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'visaResident',
    label: 'Copy of Visa or Resident Visa ',
    secondLabel: '(if you are a resident of another country)',
    isRequired: false,
  },
  {
    name: 'qualificationCertificate',
    label: 'Certificate of Qualification',
    secondLabel: '',
    isRequired: true,
  },
]
export const selfEmpVisaClearanceMain = [
  {
    name: 'policeClearance',
    label: 'Police Clearance (see Guidelines)',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'canadianMedicalExamProof',
    label:
      'Proof of Medical Exam From A Canadian Approved Physician (see Guidelines)',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'langTestResult',
    label: 'Language Test Results',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'proofOfEducationCredential',
    label: 'Proof of Educational Credential ',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'proofEquivalentStudies',
    label: 'Proof of Full Time Equivalent Studies',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'visaResident',
    label: 'Copy of Visa or Resident Visa ',
    secondLabel: '(if you are a resident of another country)',
    isRequired: false,
  },
  {
    name: 'scheduleABackgroundDecl',
    label: 'Schedule A: Background Declaration IMM 5669',
    secondLabel: '',
    isRequired: true,
  },

  {
    name: 'supplementaryInfo',
    label: 'Supplementary Information - Your Travels IMM 5562',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'schedule13Immigration',
    label:
      'Schedule 13: Business Immigration Programs - Start Up Business Class IMM 0008',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'useRepresentative',
    label: 'Use of a Representative IMM 5476',
    secondLabel: '(if you are a resident of another country)',
    isRequired: false,
  },
  {
    name: 'letterOfSupport',
    label: 'Letter of Support (see Guidelines)',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'declarationOfIntentReside',
    label:
      'Schedule 5: Declaration of Intent to Reside in Quebec: Economic Classes',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'businessImmigrants',
    label: 'Schedule 6A: Business Immigrants - Self-Employed Persons',
    secondLabel: '',
    isRequired: true,
  },
]
