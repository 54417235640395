import React from "react";
const ApplicationFooter = () => {
  return (
    <div className={`application-footer`}>
      <div className="container">
        <div className={`application-footer-menu`}>
          <ul>
            <li>
              <a href="https://canadadistrict.com/privacypolicy" target="_blank" rel="noreferrer">
                Terms & Conditions
              </a>
            </li>
            <li>
              <a href="https://canadadistrict.com/privacypolicy" target="_blank" rel="noreferrer">
                Privacy Policy
              </a>
            </li>
            <li>
              <a href="mailto:support@canadadistrict.com">Support</a>
            </li>
          </ul>
          <p>
            Copyright © 2010-2023 All rights reserved to UIS Limited.
          </p>
          <p>50 Lonsdale Ave Vancouver, Canada<br/> 5 Secretary’s Lane Gibraltar, GX11 1AA</p>
        </div>
      </div>
    </div>
  );
};

export default ApplicationFooter;
