export const partnerSpouseVisaFinanceDocSponsored = [
  {
    name: 'sponsoredEmpReferenceLetter',
    label: 'Employment Reference Letter',
    secondLabel: ' (if applicable)',
    isRequired: false,
  },
]
export const superVisaFinanceDocSponsored = [
  {
    name: 'sponsoredPaymentMedicalInsurance',
    label: 'Proof of Payment for Medical Insurance (see Guidelines) ',
    secondLabel: '',
    isRequired: true,
  },
]
export const selfEmpWorkPermitFinanceDocSponsored = [
  {
    name: 'sponsoredLabourMarketImpactAssessment',
    label: 'Labour Market Impact Assessment (LMIA)',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'sponsoredEmpContract',
    label: 'Employment Contract',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'sponsoredEmpNumber',
    label: 'Offer of Employment Number',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
]
