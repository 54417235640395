/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {
  Form,
  Accordion,
  Button,
  useAccordionButton,
  Card,
} from "react-bootstrap";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast } from "react-toastify";
import { useContext } from "react";
import UserContext from "../../context/UserContext";
import { serverPath } from "../../config/key";
import approved from "../../assets/images/header/approved.svg";
import { yupResolver } from "@hookform/resolvers/yup";
import addIcon from "../../assets/images/visa/add-icon.svg";
import activeArrow from "../../assets/images/visa/orange-arrow.svg";
import closeArrow from "../../assets/images/visa/close-arrow.svg";
import {
  educationMainApplicant,
  addEducationSchema,
  addEducationSpouseSchema,
} from "../../utils/validationSchema";
import { useParams } from "react-router-dom";
import ErrorModal from "../modals/errorModal";
const EducationInfo = ({
  educationInfo,
  setEducationInfo,
  adminFormDisable,
  updFormType,
  productName,
}) => {
  const { formType } = useParams();
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("t");
  let intialStateEducation = {
    fieldStudy: "",
    institution: "",
    countryStudy: "",
    city: "",
    completed: "",
    studyCompletionDetail: "",
  };
  let intialStateSpouseEducation = {
    sFieldStudy: "",
    sInstitution: "",
    sCountryStudy: "",
    sCity: "",
    sCompleted: "",
    sStudyCompletionDetail: "",
  };
  const { countries, selectedVisa, setSelectedVisa } = useContext(UserContext);
  const [addEducation, setAddEducation] = useState([{ intialStateEducation }]);

  const [addSpouseEducation, setAddSpouseEducation] = useState([
    { intialStateSpouseEducation },
  ]);
  const [mainDis, setMainDis] = useState(false);
  const [spouseDis, setSpouseDis] = useState(false);
  const [spouseInfo, setSpouseInfo] = useState("plus");
  const [error, setErrors] = useState({});
  const [customAccordBtn, setCustomAccordBtn] = useState(true);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showDeleteIcon, setShowDeleteIcon] = useState(true);
  const [textSpouseMessage, setTextSpouseMessage] = useState("");
  const [mainApplicantMessage, setMainApplicantMessage] = useState("");
  const [spouseErrors, setSpouseErrors] = useState();
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(educationMainApplicant),
  });
  useEffect(() => {
    if (
      selectedVisa?.visaName === "Partner / Spouse Visa" ||
      productName === "Partner / Spouse Visa"
    ) {
      setValue("isEducationSpouseOpen", true);
      setSpouseInfo("open");
      setShowDeleteIcon(false);
      setTextSpouseMessage("Sponsor (partner/Spouse)");
    }
    if (
      selectedVisa?.visaName === "Parent Sponsorship Visa" ||
      productName === "Parent Sponsorship Visa"
    ) {
      setTextSpouseMessage("Add Applicant (Parent #2)");
      setMainApplicantMessage("Applicant (Parent)");
    }
  }, [selectedVisa, productName]);

  useEffect(() => {
    if (
      educationInfo &&
      educationInfo?.mainApplicantEducation?.length !== 0 &&
      educationInfo.isLocked
    ) {
      setMainDis(token ? adminFormDisable : true);
      setSpouseDis(token ? adminFormDisable : true);
    }
    if (
      educationInfo &&
      educationInfo?.sponsoredEducationInfo?.length !== 0 &&
      educationInfo.isLocked
    ) {
      setMainDis(token ? adminFormDisable : true);
      setSpouseDis(token ? adminFormDisable : true);
    }
    if (educationInfo && educationInfo?.mainApplicantEducation?.length !== 0) {
      setAddEducation(educationInfo?.mainApplicantEducation);
    }
    if (educationInfo && educationInfo?.spouseEducation?.length !== 0) {
      setAddSpouseEducation(educationInfo?.spouseEducation);
    }
    if (
      educationInfo &&
      educationInfo?.sponsoredEducationInfo?.length !== 0 &&
      educationInfo.isLocked
    ) {
      setMainDis(token ? adminFormDisable : true);
    }
    if (educationInfo && educationInfo?.sponsoredEducationInfo?.length !== 0) {
      setAddEducation(educationInfo?.sponsoredEducationInfo);
    }
    if (
      educationInfo &&
      educationInfo?.sponsoredSpouseEducation?.length !== 0
    ) {
      setAddSpouseEducation(educationInfo?.sponsoredSpouseEducation);
    }
    if (
      educationInfo &&
      educationInfo?.sponsoringEducationInfo?.length !== 0 &&
      educationInfo.isLocked
    ) {
      setMainDis(token ? adminFormDisable : true);
    }

    if (token) {
      setValue(
        "highestLevelEdu",
        educationInfo && educationInfo?.highestLevelEdu
          ? educationInfo?.highestLevelEdu
          : ""
      );
      setValue(
        "sHighestLevelEdu",
        educationInfo && educationInfo?.sHighestLevelEdu
          ? educationInfo?.sHighestLevelEdu
          : ""
      );
    }
  }, [educationInfo, token, adminFormDisable]);
  const handleAddEducation = () => {
    setAddEducation([...addEducation, { ...intialStateEducation }]);
  };
  //add spouse education form
  const handleAddSpouseEducation = () => {
    setAddSpouseEducation([
      ...addSpouseEducation,
      { ...intialStateSpouseEducation },
    ]);
  };
  const handleChange = (e, index) => {
    const { name, value } = e.target;
    let prevData = [...addEducation];
    prevData[index][name] = value;
    setAddEducation([...prevData]);
  };
  //handle input value of spouse information
  const handleSpouseChange = (e, index) => {
    const { name, value } = e.target;
    let prevData = [...addSpouseEducation];
    prevData[index][name] = value;
    setAddSpouseEducation([...prevData]);
  };
  //remove main education form with value
  const removeEducation = (index) => {
    const oldedu = [...addEducation];
    oldedu.splice(index, 1);
    setAddEducation(oldedu);
  };
  //remove spouse education form with value
  const removeSpouseEducation = (index) => {
    const oldedu = [...addSpouseEducation];
    oldedu.splice(index, 1);
    setAddSpouseEducation(oldedu);
  };
  const changeAccordionselect = (key) => {
    if (key === "1") {
      setValue("isEducationSpouseOpen", true);
      setSpouseInfo("open");
    }
  };

  const submitEducationForm = async (values) => {
    try {
      let spouseValid;
      let spouseValidate;
      let mainValid;
      let apiPath = token
        ? "support/updateVisaEducation"
        : "visa/visaEducation";
      if (spouseInfo === "plus") {
        const validate = await addEducationSchema.validate(addEducation, {
          abortEarly: false,
        });
        if (validate) {
          setErrors({});
        }
        mainValid = validate ? validate : true;
        spouseValidate = true;
      }

      if (spouseInfo === "open") {
        try {
          spouseValid = await addEducationSpouseSchema.validate(
            addSpouseEducation,
            {
              abortEarly: false,
            }
          );
          if (spouseValid) {
            setSpouseErrors({});
            // spouseValid = spouseValidate ? spouseValidate : true
          }
          spouseValidate = spouseValidate ? spouseValidate : true;
        } catch (errs) {
          let er = {};
          errs.inner.forEach((el) => {
            er[el.path] = el.message;
          });
          setSpouseErrors({ ...er });
        }
        const mainValidate = await addEducationSchema.validate(addEducation, {
          abortEarly: false,
        });
        if (mainValidate) {
          setErrors({});
        }
        mainValid = mainValidate ? mainValidate : true;
      }
      if (mainValid && spouseValidate) {
        const res = await axios.post(
          `${serverPath}/${apiPath}`,
          {
            ...values,
            mainApplicantEducation: addEducation,
            spouseEducation: addSpouseEducation,
            visaTypeId: selectedVisa.visaId,
            invoiceId: selectedVisa.invoiceId,
            formType: formType || updFormType,
          },
          {
            headers: {
              authorization: `Bearer ${
                token ? token : localStorage.getItem("token")
              }`,
            },
          }
        );
        const { code, create } = res.data;
        if (code === 200) {
          setEducationInfo(create);
          setSelectedVisa({
            invoiceId: create?.invoiceId,
            visaId: create?.visaTypeId,
            caseId: selectedVisa?.caseId,
            visaName: selectedVisa?.visaName,
            isEvaluation: selectedVisa?.isEvaluation,
          });
          toast.success("Your information is saved successfully", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          });
        }
      }
    } catch (err) {
      let er = {};

      err.inner.forEach((el) => {
        er[el.path] = el.message;
      });
      setErrors({ ...er });
      toast.success(err?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        toastId: "err",
      });
    }
  };

  const handleDelete = (value, id) => {
    if (value === "spouseInfo") {
      setSpouseInfo("plus");
      setValue("isEducationSpouseOpen", false);
    }
    document.getElementById(id).click();
  };
  const handleClose = (value, id) => {
    document.getElementById(id).click();
  };
  //custom accordian toggle
  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      if (customAccordBtn === eventKey) {
        setCustomAccordBtn("");
      } else setCustomAccordBtn(eventKey);
    });
    return (
      <Button id={`edu-${eventKey}`} onClick={decoratedOnClick}>
        {children}
      </Button>
    );
  }

  //For Error popup
  const handleShowErrorModal = () => {
    setShowErrorModal(false);
  };
  useEffect(() => {
    if (
      (errors && Object.keys(errors).length !== 0) ||
      (error && Object.keys(error).length !== 0) ||
      (spouseErrors && Object.keys(spouseErrors).length !== 0)
    ) {
      setShowErrorModal(true);
    } else {
      setShowErrorModal(false);
    }
  }, [errors, error, spouseErrors]);

  return (
    <div className="personal-info education-info-section">
      <Form
        onSubmit={handleSubmit(submitEducationForm)}
        // className={`${mainDis ? 'success-btn' : ''}`}
      >
        <Accordion
          defaultActiveKey="0"
          onSelect={(key) => changeAccordionselect(key)}
        >
          <div className={`accordion-list ${"change-accordion-icon"}`}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {mainApplicantMessage ? mainApplicantMessage : "Main Applicant"}
              </Accordion.Header>
              <span className="tab-subheading">
                Please provide details about your primary, secondary and
                post-secondary education (including apprenticeship training).
              </span>
              <Accordion.Body>
                <div className="child-info">
                  <div className="box-grid-two">
                    <Form.Group
                      className={`form-group mb-3 ${
                        errors?.yearsEducation ? "error-msg" : ""
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>
                        Total years of education (since Grade 1)*
                      </Form.Label>
                      <Form.Control
                        type="number"
                        name="yearsEducation"
                        disabled={mainDis ? mainDis : false}
                        defaultValue={
                          educationInfo?.yearsEducation
                            ? educationInfo?.yearsEducation
                            : educationInfo?.sponsoredYearsEducation
                            ? educationInfo?.sponsoredYearsEducation
                            : ""
                        }
                        {...register("yearsEducation")}
                      />
                      {errors?.yearsEducation ? (
                        <span className="error-msg">
                          {errors?.yearsEducation?.message}
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                    <Form.Group
                      className={`form-group mb-3 ${
                        errors?.highestLevelEdu ? "error-msg" : ""
                      }`}
                      controlId="formBasicEmail"
                    >
                      <Form.Label>Your Highest Level of Education*</Form.Label>
                      <Form.Select
                        aria-label="Default select example"
                        name="highestLevelEdu"
                        defaultValue={
                          educationInfo?.highestLevelEdu
                            ? educationInfo?.highestLevelEdu
                            : educationInfo?.sponsoredHighestLevelEdu
                            ? educationInfo?.sponsoredHighestLevelEdu
                            : ""
                        }
                        disabled={mainDis ? mainDis : false}
                        {...register("highestLevelEdu")}
                      >
                        <option value="">Select</option>
                        <option value="None">None</option>
                        <option value="High School">High School</option>
                        <option value="Diploma / Certificate">
                          Diploma / Certificate
                        </option>
                        <option value="Trade Certificate">
                          Trade Certificate
                        </option>
                        <option value="Bachelor's Degree">
                          Bachelor's Degree
                        </option>
                        <option value="Master's Degree">Master's Degree</option>
                        <option value="PhD">PhD</option>
                      </Form.Select>
                      {errors?.highestLevelEdu ? (
                        <span className="error-msg">
                          {errors?.highestLevelEdu?.message}
                        </span>
                      ) : (
                        ""
                      )}
                    </Form.Group>
                  </div>
                  {addEducation?.length === 0 && (
                    <div className="add-btn">
                      <Button onClick={handleAddEducation}>
                        Add Education <i className="las la-plus"></i>
                      </Button>
                    </div>
                  )}
                </div>
                {addEducation &&
                  addEducation.length > 0 &&
                  addEducation.map((child, i) => (
                    <>
                      {/* <h6 className="child-heading education-heading">
                        Education #{i + 1}
                      </h6> */}
                      <div className="education-info">
                        <div className="box-grid-two">
                          <Form.Group
                            className={`form-group mb-3 ${
                              error && error[`[${i}].fieldStudy`]
                                ? "error-msg"
                                : ""
                            }`}
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Field of Study*</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Example: dentistry, law, optometry..."
                              name="fieldStudy"
                              disabled={mainDis ? mainDis : false}
                              defaultValue={child.fieldStudy}
                              onChange={(e) => handleChange(e, i)}
                            />
                            {error && error[`[${i}].fieldStudy`] ? (
                              <span className="error-msg">
                                {error[`[${i}].fieldStudy`]}
                              </span>
                            ) : (
                              ""
                            )}
                          </Form.Group>
                          <Form.Group
                            className={`form-group mb-3 ${
                              error && error[`[${i}].institution`]
                                ? "error-msg"
                                : ""
                            }`}
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Institution*</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="institution"
                              disabled={mainDis ? mainDis : false}
                              defaultValue={
                                child.institution ? child.institution : ""
                              }
                              onChange={(e) => handleChange(e, i)}
                            >
                              <option
                                value={
                                  child.institution ? child.institution : ""
                                }
                              >
                                {child.institution
                                  ? child.institution
                                  : "Select"}
                              </option>
                              <option value="None">None</option>
                              <option value="College">College</option>
                              <option value="University">University</option>
                              <option value="Trade School">Trade School</option>
                              <option value="Technical School">
                                Technical School
                              </option>
                              <option value="Online Course">
                                Online Course
                              </option>
                            </Form.Select>
                            {error && error[`[${i}].institution`] ? (
                              <span className="error-msg">
                                {error[`[${i}].institution`]}
                              </span>
                            ) : (
                              ""
                            )}
                          </Form.Group>
                        </div>
                        <div className="box-grid-two">
                          <Form.Group
                            className={`form-group mb-3 ${
                              error && error[`[${i}].countryStudy`]
                                ? "error-msg"
                                : ""
                            }`}
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Country of Study*</Form.Label>
                            <Form.Select
                              name="countryStudy"
                              disabled={spouseDis ? spouseDis : false}
                              defaultValue={
                                child.countryStudy ? child.countryStudy : ""
                              }
                              onChange={(e) => handleChange(e, i)}
                            >
                              <option
                                value={
                                  child.countryStudy ? child.countryStudy : ""
                                }
                              >
                                {child.countryStudy
                                  ? child.countryStudy
                                  : "Select"}
                              </option>
                              {countries &&
                                countries?.length > 0 &&
                                countries.map((el) => (
                                  <option value={el?.name}>{el?.name}</option>
                                ))}
                            </Form.Select>
                            {error && error[`[${i}].countryStudy`] ? (
                              <span className="error-msg">
                                {error[`[${i}].countryStudy`]}
                              </span>
                            ) : (
                              ""
                            )}
                          </Form.Group>
                          <Form.Group
                            className={`form-group mb-3 ${
                              error && error[`[${i}].city`] ? "error-msg" : ""
                            }`}
                            controlId="formBasicEmail"
                          >
                            <Form.Label>City</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Example: dentistry, law, optometry..."
                              name="city"
                              disabled={spouseDis ? spouseDis : false}
                              defaultValue={child.city}
                              onChange={(e) => handleChange(e, i)}
                            />
                            {error && error[`[${i}].city`] ? (
                              <span className="error-msg">
                                {error[`[${i}].city`]}
                              </span>
                            ) : (
                              ""
                            )}
                          </Form.Group>
                        </div>
                        <div className="box-grid-three">
                          <Form.Group
                            className={`form-group mb-3 ${
                              error && error[`[${i}].studyDuration`]
                                ? "error-msg"
                                : ""
                            }`}
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Study Duration*</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="studyDuration"
                              disabled={mainDis ? mainDis : false}
                              defaultValue={
                                child?.studyDuration ? child?.studyDuration : ""
                              }
                              onChange={(e) => handleChange(e, i)}
                            >
                              <option
                                value={
                                  child?.studyDuration
                                    ? child?.studyDuration
                                    : ""
                                }
                              >
                                {child?.studyDuration
                                  ? child?.studyDuration
                                  : "Select"}
                              </option>
                              <option value="None">None</option>
                              <option value="Less than 1 Academic Year">
                                Less than 1 Academic Year
                              </option>
                              <option value="1 Academic Year">
                                1 Academic Year
                              </option>
                              <option value="2 Academic Years">
                                2 Academic Years
                              </option>
                              <option value="3 Academic Years">
                                3 Academic Years
                              </option>
                              <option value="4 Academic Years">
                                4 Academic Years
                              </option>
                              <option value="5 Academic Years and above">
                                5 Academic Years and above
                              </option>
                            </Form.Select>
                            {error && error[`[${i}].studyDuration`] ? (
                              <span className="error-msg">
                                {error[`[${i}].studyDuration`]}
                              </span>
                            ) : (
                              ""
                            )}
                          </Form.Group>

                          <Form.Group
                            className={`form-group mb-3 ${
                              error && error[`[${i}].completed`]
                                ? "error-msg"
                                : ""
                            }`}
                            controlId="formBasicEmail"
                          >
                            <Form.Label>Completed?*</Form.Label>
                            <Form.Select
                              aria-label="Default select example"
                              name="completed"
                              disabled={mainDis ? mainDis : false}
                              defaultValue={child?.completed || ""}
                              onChange={(e) => handleChange(e, i)}
                            >
                              <option value={child?.completed || "select"}>
                                {child?.completed || "select"}
                              </option>
                              <option value="No">No</option>
                              <option value="Yes">Yes</option>
                            </Form.Select>
                            {error && error[`[${i}].completed`] ? (
                              <span className="error-msg">
                                {error[`[${i}].completed`]}
                              </span>
                            ) : (
                              ""
                            )}
                          </Form.Group>
                          {child && child.completed === "No" ? (
                            <Form.Group
                              className={`form-group mb-3 ${
                                error && error[`[${i}].studyCompletionDetail`]
                                  ? "error-msg"
                                  : ""
                              }`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>
                                how long until studies are completed?
                              </Form.Label>
                              <Form.Control
                                type="text"
                                name="studyCompletionDetail"
                                disabled={mainDis ? mainDis : false}
                                defaultValue={child.studyCompletionDetail}
                                onChange={(e) => handleChange(e, i)}
                              />
                              {error &&
                              error[`[${i}].studyCompletionDetail`] ? (
                                <span className="error-msg">
                                  {error[`[${i}].studyCompletionDetail`]}
                                </span>
                              ) : (
                                ""
                              )}
                            </Form.Group>
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="add-btn">
                          {i >= 1 &&
                            (!mainDis ? (
                              <Button
                                className="delete-btn"
                                disabled={mainDis ? mainDis : false}
                                onClick={() => removeEducation(i)}
                              >
                                Delete Education #{i + 1}{" "}
                                <i class="las la-minus"></i>
                              </Button>
                            ) : (
                              ""
                            ))}

                          <Button
                            onClick={handleAddEducation}
                            disabled={mainDis ? mainDis : false}
                          >
                            Add Education <i className="las la-plus"></i>
                          </Button>
                        </div>
                      </div>
                    </>
                  ))}
              </Accordion.Body>
            </Accordion.Item>
          </div>
          <div
            className={`accordion-list ${
              spouseInfo === "open" ? "change-accordion-icon" : ""
            }`}
          >
            <Card>
              <Card.Header
                className={customAccordBtn === "1" ? "accord-open" : ""}
                onClick={() =>
                  spouseInfo === "open" || mainDis
                    ? handleClose("spouseInfo", "edu-1")
                    : ""
                }
              >
                <div className="accordian-title">
                  {textSpouseMessage
                    ? textSpouseMessage
                    : spouseInfo === "open" || mainDis
                    ? "Spouse Information"
                    : "Add Spouse Information"}
                  {spouseInfo === "open" && showDeleteIcon && !mainDis && (
                    <div className="delete-btn">
                      <Button
                        onClick={() => handleDelete("spouseInfo", "edu-1")}
                      >
                        Delete <i class="las la-minus"></i>
                      </Button>
                    </div>
                  )}
                </div>

                <CustomToggle eventKey="1">
                  {spouseInfo === "plus" && !mainDis ? (
                    <img src={addIcon} alt="Add Icon" />
                  ) : (spouseInfo === "open" && mainDis) ||
                    (spouseInfo === "open" && !mainDis) ||
                    (spouseInfo === "plus" && !mainDis) ||
                    (spouseInfo === "plus" && mainDis) ? (
                    <img
                      className="arrow-icon"
                      src={customAccordBtn !== "1" ? closeArrow : activeArrow}
                      alt="Icon"
                    />
                  ) : (
                    ""
                  )}
                </CustomToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <div className="child-info">
                    <div className="box-grid-two">
                      <Form.Group
                        className={`form-group mb-3 ${
                          errors?.educationSpouseInformation?.sYearsEducation
                            ? "error-msg"
                            : ""
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>
                          Total years of education (since Grade 1)*
                        </Form.Label>
                        <Form.Control
                          type="number"
                          name="sYearsEducation"
                          {...register(
                            "educationSpouseInformation.sYearsEducation"
                          )}
                          disabled={mainDis ? mainDis : false}
                          defaultValue={
                            educationInfo?.sYearsEducation
                              ? educationInfo?.sYearsEducation
                              : educationInfo?.sponsoredSpouseYearsEducation
                              ? educationInfo?.sponsoredSpouseYearsEducation
                              : ""
                          }
                        />
                        {errors?.educationSpouseInformation?.sYearsEducation ? (
                          <span className="error-msg">
                            {
                              errors?.educationSpouseInformation
                                ?.sYearsEducation?.message
                            }
                          </span>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                      <Form.Group
                        className={`form-group mb-3 ${
                          errors?.educationSpouseInformation?.sHighestLevelEdu
                            ? "error-msg"
                            : ""
                        }`}
                        controlId="formBasicEmail"
                      >
                        <Form.Label>
                          Your Highest Level of Education*
                        </Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="sHighestLevelEdu"
                          disabled={spouseDis ? spouseDis : false}
                          defaultValue={
                            educationInfo?.sHighestLevelEdu
                              ? educationInfo?.sHighestLevelEdu
                              : educationInfo?.sponsoredSpouseHighestLevelEdu
                              ? educationInfo?.sponsoredSpouseHighestLevelEdu
                              : ""
                          }
                          {...register(
                            "educationSpouseInformation.sHighestLevelEdu"
                          )}
                        >
                          <option
                            value={
                              educationInfo?.sHighestLevelEdu
                                ? educationInfo?.sHighestLevelEdu
                                : educationInfo?.sponsoredSpouseHighestLevelEdu
                                ? educationInfo?.sponsoredSpouseHighestLevelEdu
                                : ""
                            }
                          >
                            {educationInfo?.sHighestLevelEdu
                              ? educationInfo?.sHighestLevelEdu
                              : educationInfo?.sponsoredSpouseHighestLevelEdu
                              ? educationInfo?.sponsoredSpouseHighestLevelEdu
                              : "Select"}
                          </option>
                          <option value="None">None</option>
                          <option value="High School">High School</option>
                          <option value="Diploma / Certificate">
                            Diploma / Certificate
                          </option>
                          <option value="Trade Certificate">
                            Trade Certificate
                          </option>
                          <option value="Bachelor's Degree">
                            Bachelor's Degree
                          </option>
                          <option value="Master's Degree">
                            Master's Degree
                          </option>
                          <option value="PhD">PhD</option>
                        </Form.Select>
                        {errors?.educationSpouseInformation
                          ?.sHighestLevelEdu ? (
                          <span className="error-msg">
                            {
                              errors?.educationSpouseInformation
                                ?.sHighestLevelEdu?.message
                            }
                          </span>
                        ) : (
                          ""
                        )}
                      </Form.Group>
                    </div>
                  </div>
                  {addSpouseEducation &&
                    addSpouseEducation.length > 0 &&
                    addSpouseEducation.map((child, i) => (
                      <>
                        {/* <h6 className="child-heading education-heading">
                          Education #{i + 1}
                        </h6> */}
                        <div className="education-info">
                          <div className="box-grid-two">
                            <Form.Group
                              className={`form-group mb-3 ${
                                spouseErrors &&
                                spouseErrors[`[${i}].sFieldStudy`]
                                  ? "error-msg"
                                  : ""
                              }`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Field of Study*</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Example: dentistry, law, optometry..."
                                name="sFieldStudy"
                                disabled={spouseDis ? spouseDis : false}
                                defaultValue={child.sFieldStudy}
                                onChange={(e) => handleSpouseChange(e, i)}
                              />
                              {spouseErrors &&
                              spouseErrors[`[${i}].sFieldStudy`] ? (
                                <span className="error-msg">
                                  {spouseErrors[`[${i}].sFieldStudy`]}
                                </span>
                              ) : (
                                ""
                              )}
                            </Form.Group>
                            <Form.Group
                              className={`form-group mb-3 ${
                                spouseErrors &&
                                spouseErrors[`[${i}].sInstitution`]
                                  ? "error-msg"
                                  : ""
                              }`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Institution*</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="sInstitution"
                                disabled={spouseDis ? spouseDis : false}
                                defaultValue={
                                  child.sInstitution ? child.sInstitution : ""
                                }
                                onChange={(e) => handleSpouseChange(e, i)}
                              >
                                <option
                                  value={
                                    child.sInstitution ? child.sInstitution : ""
                                  }
                                >
                                  {child.sInstitution
                                    ? child.sInstitution
                                    : "Select"}
                                </option>
                                <option value="None">None</option>
                                <option value="College">College</option>
                                <option value="University">University</option>
                                <option value="Trade School">
                                  Trade School
                                </option>
                                <option value="Technical School">
                                  Technical School
                                </option>
                                <option value="Online Course">
                                  Online Course
                                </option>
                              </Form.Select>
                              {spouseErrors &&
                              spouseErrors[`[${i}].sInstitution`] ? (
                                <span className="error-msg">
                                  {spouseErrors[`[${i}].sInstitution`]}
                                </span>
                              ) : (
                                ""
                              )}
                            </Form.Group>
                          </div>
                          <div className="box-grid-two">
                            <Form.Group
                              className={`form-group mb-3 ${
                                spouseErrors &&
                                spouseErrors[`[${i}].sCountryStudy`]
                                  ? "error-msg"
                                  : ""
                              }`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Country of Study*</Form.Label>
                              <Form.Select
                                name="sCountryStudy"
                                disabled={spouseDis ? spouseDis : false}
                                defaultValue={
                                  child.sCountryStudy ? child.sCountryStudy : ""
                                }
                                onChange={(e) => handleSpouseChange(e, i)}
                              >
                                <option value="">Select</option>
                                {countries &&
                                  countries?.length > 0 &&
                                  countries.map((el) => (
                                    <option value={el?.name}>{el?.name}</option>
                                  ))}
                              </Form.Select>
                              {spouseErrors &&
                              spouseErrors[`[${i}].sCountryStudy`] ? (
                                <span className="error-msg">
                                  {spouseErrors[`[${i}].sCountryStudy`]}
                                </span>
                              ) : (
                                ""
                              )}
                            </Form.Group>
                            <Form.Group
                              className={`form-group mb-3 ${
                                spouseErrors && spouseErrors[`[${i}].sCity`]
                                  ? "error-msg"
                                  : ""
                              }`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>City</Form.Label>
                              <Form.Control
                                type="text"
                                placeholder="Example: dentistry, law, optometry..."
                                name="sCity"
                                disabled={spouseDis ? spouseDis : false}
                                defaultValue={child.sCity}
                                onChange={(e) => handleSpouseChange(e, i)}
                              />
                              {spouseErrors && spouseErrors[`[${i}].sCity`] ? (
                                <span className="error-msg">
                                  {spouseErrors[`[${i}].sCity`]}
                                </span>
                              ) : (
                                ""
                              )}
                            </Form.Group>
                          </div>
                          <div className="box-grid-three">
                            <Form.Group
                              className={`form-group mb-3 ${
                                spouseErrors &&
                                spouseErrors[`[${i}].sStudyDuration`]
                                  ? "error-msg"
                                  : ""
                              }`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Study Duration*</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="sStudyDuration"
                                disabled={spouseDis ? spouseDis : false}
                                defaultValue={
                                  child?.sStudyDuration
                                    ? child?.sStudyDuration
                                    : ""
                                }
                                onChange={(e) => handleSpouseChange(e, i)}
                              >
                                <option value="">Select</option>
                                <option value="None">None</option>
                                <option value="Less than 1 Academic Year">
                                  Less than 1 Academic Year
                                </option>
                                <option value="1 Academic Year">
                                  1 Academic Year
                                </option>
                                <option value="2 Academic Years">
                                  2 Academic Years
                                </option>
                                <option value="3 Academic Years">
                                  3 Academic Years
                                </option>
                                <option value="4 Academic Years">
                                  4 Academic Years
                                </option>
                                <option value="5 Academic Years and above">
                                  5 Academic Years and above
                                </option>
                              </Form.Select>
                              {spouseErrors &&
                              spouseErrors[`[${i}].sStudyDuration`] ? (
                                <span className="error-msg">
                                  {spouseErrors[`[${i}].sStudyDuration`]}
                                </span>
                              ) : (
                                ""
                              )}
                            </Form.Group>

                            <Form.Group
                              className={`form-group mb-3 ${
                                spouseErrors &&
                                spouseErrors[`[${i}].sCompleted`]
                                  ? "error-msg"
                                  : ""
                              }`}
                              controlId="formBasicEmail"
                            >
                              <Form.Label>Completed?*</Form.Label>
                              <Form.Select
                                aria-label="Default select example"
                                name="sCompleted"
                                disabled={spouseDis ? spouseDis : false}
                                defaultValue={
                                  child.sCompleted ? child.sCompleted : ""
                                }
                                onChange={(e) => handleSpouseChange(e, i)}
                              >
                                <option value="">Select</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </Form.Select>
                              {spouseErrors &&
                              spouseErrors[`[${i}].sCompleted`] ? (
                                <span className="error-msg">
                                  {spouseErrors[`[${i}].sCompleted`]}
                                </span>
                              ) : (
                                ""
                              )}
                            </Form.Group>
                            {child && child.sCompleted === "No" ? (
                              <Form.Group
                                className={`form-group mb-3 ${
                                  spouseErrors &&
                                  spouseErrors[`[${i}].sStudyCompletionDetail`]
                                    ? "error-msg"
                                    : ""
                                }`}
                                controlId="formBasicEmail"
                              >
                                <Form.Label>
                                  how long until studies are completed?
                                </Form.Label>
                                <Form.Control
                                  type="text"
                                  name="sStudyCompletionDetail"
                                  disabled={spouseDis ? spouseDis : false}
                                  defaultValue={child.sStudyCompletionDetail}
                                  onChange={(e) => handleSpouseChange(e, i)}
                                />
                                {spouseErrors &&
                                spouseErrors[
                                  `[${i}].sStudyCompletionDetail`
                                ] ? (
                                  <span className="error-msg">
                                    {
                                      spouseErrors[
                                        `[${i}].sStudyCompletionDetail`
                                      ]
                                    }
                                  </span>
                                ) : (
                                  ""
                                )}
                              </Form.Group>
                            ) : (
                              ""
                            )}
                          </div>
                          <div className="add-btn">
                            {i >= 1 && !spouseDis && (
                              <Button
                                className="delete-btn"
                                disabled={spouseDis ? spouseDis : false}
                                onClick={() => removeSpouseEducation(i)}
                              >
                                Delete Education
                                <i class="las la-minus"></i>
                              </Button>
                            )}
                            <Button
                              onClick={handleAddSpouseEducation}
                              disabled={spouseDis ? spouseDis : false}
                            >
                              Add Education <i className="las la-plus"></i>
                            </Button>
                          </div>
                        </div>
                      </>
                    ))}
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </div>
        </Accordion>
        <div className="visa-btn">
          {!mainDis ? (
            <>
              <Button
                type="submit"
                disabled={mainDis ? mainDis : false}
                className={`${mainDis ? "success-btn" : ""}`}
              >
                Save {mainDis && <img src={approved} alt="approved" />}
              </Button>
              <div className="visa-desc-text">
                <p>
                  Once your form is completed, you will be able to make new
                  changes by contacting our Support team.
                </p>
                <p>When you're ready, click "Submit"!</p>
              </div>
            </>
          ) : (
            <div className="for-new-changes">
              <p>
                <span>Important! </span> Please contact our Support team if you
                would like to make new changes .
              </p>
            </div>
          )}
        </div>
      </Form>
      <ErrorModal show={showErrorModal} handleClose={handleShowErrorModal} />
    </div>
  );
};
export default EducationInfo;
