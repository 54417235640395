export const parentSponsorSpouseVisaSponsoringGen = [
  {
    name: 'sponsoringCanadianFrontPassportCopy',
    label: 'Canadian Passport Copy',
    secondLabel: ' (front page + stamp / visa pages if any)',
    isRequired: true,
  },
  {
    name: 'sponsoringPermanentResidenceCard',
    label: 'Copy of Permanent Residence Card',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'sponsoringBirthCertificate',
    label: 'Copy of Birth Certificate',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'sponsoringMarriageCertificate',
    label: 'Marriage Certificate',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'sponsoringCommonLawDeclaration',
    label: 'Common-Law Declaration',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'sponsoringDivorceCertificate',
    label: 'Divorce certificate',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
]
export const partnerSpouseVisaGenSponsoring = [
  {
    name: 'sponsoringCanadianFrontPassportCopy',
    label: 'Canadian Passport Copy',
    secondLabel: ' (front page + stamp / visa pages if any)',
    isRequired: true,
  },
  {
    name: 'sponsoringPermanentResidenceCard',
    label: 'Copy of Permanent Residence Card',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'sponsoringBirthCertificate',
    label: 'Copy of Birth Certificate',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'sponsoringMarriageCertificate',
    label: 'Marriage Certificate',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'sponsoringCommonLawDeclaration',
    label: 'Common-Law Declaration',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
]
