export const workingHolydayVisaMainFinanceDoc = [
  {
    name: 'bankStatement',
    label: 'Recent Bank Statement',
    secondLabel:
      '(account balance must include at least $2,500 CAD + 1st year tuition fees)',
    isRequired: true,
  },
]
export const studentVisaEmpFinanceMain = [
  {
    name: 'bankStatement',
    label: 'Recent Bank Statement',
    secondLabel:
      '(account balance must include at least $2,500 CAD + 1st year tuition fees)',
    isRequired: true,
  },
  {
    name: 'paymentReceipts',
    label: 'Payment Receipt',
    secondLabel: '(to establish that the 1st semester has been paid)',
    isRequired: true,
  },
]
export const touristVisaEmpFinanceMain = [
  {
    name: 'empReferenceLetter',
    label: 'Employment Reference Letter',
    secondLabel: ' (if applicable)',
    isRequired: false,
  },
  {
    name: 'bankStatement',
    label: 'Recent Bank Statement',
    secondLabel:
      '(account balance must include at least $2,500 CAD + 1st year tuition fees)',
    isRequired: true,
  },
]
export const pnpExpressEntryVisaFinanceDocMain = [
  {
    name: 'empReferenceLetter',
    label: 'Employment Reference Letter',
    secondLabel: ' (if applicable)',
    isRequired: false,
  },
  {
    name: 'bankStatement',
    label: 'Recent Bank Statement',
    secondLabel:
      '(account balance must include CAD$13,310 + CAD$3,586 for each family member that is joining you)',
    isRequired: true,
  },
]
export const inVestorVisaFinancialDocMain = [
  {
    name: 'bankStatement',
    label: 'Recent Bank Statement',
    secondLabel:
      '(account balance must include CAD$13,310 + CAD$3,586 for each family member that is joining you)',
    isRequired: true,
  },
  {
    name: 'currentBankCertificate',
    label: 'Current Bank Certification Letter',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'evidenceSavingBalance',
    label: 'Evidence of Savings Balance',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'fixedDepositStatement',
    label: 'Fixed or Time Deposit Statements',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'businessPlan',
    label: 'Business plan',
    secondLabel: '',
    isRequired: true,
  },
]
export const provincialNomineeVisaFinanceDocMain = [
  {
    name: 'empReferenceLetter',
    label: 'Employment Reference Letter',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'bankStatement',
    label: 'Recent Bank Statement',
    secondLabel:
      ' (account balance must include CAD$13,310 + CAD$3,586 for each family member that is joining you) ',
    isRequired: true,
  },
]
export const selfEmpVisaFinancialDocMain = [
  {
    name: 'bankStatement',
    label: 'Recent Bank Statement',
    secondLabel:
      '(account balance must include CAD$13,310 + CAD$3,586 for each family member that is joining you)',
    isRequired: true,
  },
  {
    name: 'currentBankCertificate',
    label: 'Current Bank Certification Letter',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'evidenceSavingBalance',
    label: 'Evidence of Savings Balance',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'fixedDepositStatement',
    label: 'Fixed or Time Deposit Statements',
    secondLabel: '',
    isRequired: true,
  },
]
