/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react'
import { useState } from 'react'
import { Accordion, Card, useAccordionButton } from 'react-bootstrap'
import DocumentPopup from './ducumentPopup'
import GeneralInfo from './generalInfo'
import LetterClearanceInfo from './letterClearance'
import { Button } from 'react-bootstrap'
import EmployeementFinancialDoc from './employementFinancialDoc'
import { toast } from 'react-toastify'
import GeneralSpouseInfo from './generalSpouseDoc'
import axios from 'axios'
import { serverPath } from '../../config/key'
import { useContext } from 'react'
import UserContext from '../../context/UserContext'
import { useParams } from 'react-router-dom'
import SponsoringGeneralInfo from './sponsoringGeneralInfo'
import SponsoringLetterClearanceInfo from './sponsoringLetterClearnace'
import SponsoringEmpFinancialDocument from './sponsoringEmpFinancialDocument'
import SponsoringRelationshipEvidence from './sponsoringRelationshipEvidence'
import addIcon from '../../assets/images/visa/add-icon.svg'
import activeArrow from '../../assets/images/visa/active-arrow.svg'
import closeArrow from '../../assets/images/visa/close-arrow.svg'
import {
  partnerSpouseVisaSponsoringGen,
  partnerSpouseVisaSponsoringFinancialDoc,
  partnerSpouseVisaSponsoringDependents,
  partner_parentSpouseVisaRelationship,
} from '../../utils/allVisasDocuments'
import approved from '../../assets/images/header/approved.svg'
import {
  investorVisaGenMain,
  pnpExpressEntryVisaGenMain,
  provincialNomineeVisaGenMain,
  selfEmpVisaGenMain,
  studentVisaGenMain,
  touristVisaGenMain,
  workingHolydayVisaGenMain,
} from '../../utils/visaDocuments/main/generalInfoDoc'
import {
  inVestorVisaClearanceMain,
  pnpExpressEntryVisaClearanceMain,
  provincialNomineeLetterClearanceMain,
  selfEmpVisaClearanceMain,
  studentVisaLetterClearanceMain,
  touristVisaLetterClearanceMain,
  workingHolydayVisaLetterClearanceMain,
} from '../../utils/visaDocuments/main/letterClearanceDoc'
import {
  inVestorVisaFinancialDocMain,
  pnpExpressEntryVisaFinanceDocMain,
  provincialNomineeVisaFinanceDocMain,
  selfEmpVisaFinancialDocMain,
  studentVisaEmpFinanceMain,
  touristVisaEmpFinanceMain,
  workingHolydayVisaMainFinanceDoc,
} from '../../utils/visaDocuments/main/empFinanceDoc'
import LetterClearanceSpouseInfo from './letterCleranceSpouseInfo'
import {
  spousePnpExpressEntryVisaGenMain,
  spouseStudentVisaGenMain,
  spouseTouristVisaGenMain,
} from '../../utils/visaDocuments/main/spouseGenralInfo'
import {
  spousePnpExpressEntryVisaLetterClearanceMain,
  spouseTouristVisaLetterClearance,
} from '../../utils/visaDocuments/main/spouseLetterClearanceInfo'
import {
  parentsSpouseSponsorGen,
  partnerSpouseVisaSponsoredGen,
  selfEmpWorkPermitVisaSponsoredGen,
  superVisaSponsoredGen,
} from '../../utils/visaDocuments/sponsoredDoc/genralSponsoredDoc'
import {
  parentsSpouseSponsorLetterClearance,
  partnerSpouseLetterClearanceSponsored,
  selfEmpWorkPermitLetterClearanceSponsored,
  superVisaLetterClearanceSponsored,
} from '../../utils/visaDocuments/sponsoredDoc/sponsoredLetterClearance'
import {
  partnerSpouseVisaFinanceDocSponsored,
  selfEmpWorkPermitFinanceDocSponsored,
} from '../../utils/visaDocuments/sponsoredDoc/sponsoredFinancialDoc'
import {
  dependentInvestorVisaMain,
  dependentPnpExpressEntryVisaVisaMain,
  dependentSelfEmpVisaMain,
  dependentSelfEmpWorkPermitVisaMain,
  dependentStudentVisaMain,
  dependentTouristVisaMain,
} from '../../utils/visaDocuments/main/dependentsDoc'
import DependentInfo from './dependentInfo'
import SponsoringDependentInfo from './sponsoringDependentInfo'
import { parentSponsorSpouseVisaSponsoringGen } from '../../utils/visaDocuments/sponsoringDoc/generalSponsoringDoc'
import { parentSponsorSpouseVisaSponsoringFinance } from '../../utils/visaDocuments/sponsoringDoc/sponsoringFinancialDoc'
import { parentSponsorSpouseVisaSponsoringLetter } from '../../utils/visaDocuments/sponsoringDoc/sponsoringLetterClearance'
//import { parentSponsorSpouseDependentSponsoring } from '../../utils/visaDocuments/sponsoringDoc/sponsoringDependentInfo'
import { superVisaFinanceDocSponsoredSpouse } from '../../utils/visaDocuments/sponsoredDoc/spouseSponsoredFinanceDoc'
import {
  selfEmpWorkPermitLetterClearSponsoredSpouse,
  superVisaLetterClearanceSponsoredSpouse,
} from '../../utils/visaDocuments/sponsoredDoc/spouseSponsoredLetterClearance'
import {
  selfEmpWorkPermitGenSponsoredSpouse,
  superVisaGenSponsoredSpouse,
} from '../../utils/visaDocuments/sponsoredDoc/spouseSponsoredGenralInfo'
import FinancialDocSpouseInfo from './spouseFinancialDoc'
import { spousePnpExpressEntryFinanceDocMain } from '../../utils/visaDocuments/main/spouseEmpFinancialDoc'
import RelationshipEvidence from './RelationshipEvidence'
import {
  investorVisaRelation,
  selfEmpVisaRelation,
} from '../../utils/visaDocuments/main/relationShipAvidence'
import { selEmpVisaAdaptabilityMain } from '../../utils/visaDocuments/main/adaptabilityDoc'
import AdaptabilityDocumentInfo from './adaptabilityDocumentInfo'
import ErrorModal from '../modals/errorModal'
const Documents = ({
  documentInfo,
  setDocumentInfo,
  updFormType,
  adminFormDisable,
}) => {
  const { formType } = useParams()
  const urlParams = new URLSearchParams(window.location.search)
  const token = urlParams.get('t')
  const { selectedVisa, setSelectedVisa } = useContext(UserContext)
  const [documentPopup, setDocumentPopup] = useState(true)
  const [documentDis, setDocumentDis] = useState(false)
  const [sponsoringShow, setSponsoringShow] = useState(false)
  const [spouseDocuments, setSpouseDocuments] = useState('plus')
  const [childrenDocuments, setChildrenDocuments] = useState('plus')
  const [sponsoringDis, setSponsoringDis] = useState(false)
  const [generalDocumentMainInfo, setGeneralDocumentMainInfo] = useState([])
  const [letterClearanceMainInfo, setLetterClearanceMainInfo] = useState([])
  const [empFinancialMainInfo, setEmpFinancialMainInfo] = useState([])
  const [spouseGeneralInfo, setSpouseGeneralInfo] = useState([])
  const [spouseLetterClearanceInfo, setSpouseLetterClearanceInfo] = useState([])
  const [spouseFinacialDocInfo, setSpouseFinancialDocInfo] = useState([])
  const [dependentsInfo, setDependentsInfo] = useState([])
  const [generalSponsoringInfo, setGeneralSponsoringInfo] = useState([])
  const [sponsoringFinancialDoc, setSponsoringFinancialDoc] = useState([])
  const [sponsoringDependentInfo, setSponsoringDependentInfo] = useState([])
  const [sponsoringLetterClearance, setSponsoringLetterClearance] = useState([])
  const [relationShipDocInfo, setRelationshipDocInfo] = useState([])
  const [customAccordBtn, setCustomAccordBtn] = useState('')
  const [adaptabilityDocInfo, setAdaptabilityDocInfo] = useState([])
  const [sponsoringText, setSponsoringText] = useState('')
  const [sponsoringRelationship, setSponsoringRelationship] = useState([])
  const [requiredDocument, setRequiredDocument] = useState([])
  const [errors, setErrors] = useState([])
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [mainApplicantMsg, setMainApplicantMsg] = useState('')
  useEffect(() => {
    if (selectedVisa && selectedVisa?.visaName === 'Partner / Spouse Visa') {
      setGeneralSponsoringInfo(partnerSpouseVisaSponsoringGen)
      setSponsoringFinancialDoc(partnerSpouseVisaSponsoringFinancialDoc)
      setGeneralDocumentMainInfo(partnerSpouseVisaSponsoredGen)
      setLetterClearanceMainInfo(partnerSpouseLetterClearanceSponsored)
      setEmpFinancialMainInfo(partnerSpouseVisaFinanceDocSponsored)
      setSponsoringRelationship(partner_parentSpouseVisaRelationship)
      setSponsoringShow(true)
      setSponsoringText('Sponsor (Partner/Spouse)')
      setRequiredDocument([
        ...partnerSpouseVisaSponsoringGen,
        ...partnerSpouseVisaSponsoringFinancialDoc,
        ...partnerSpouseVisaSponsoredGen,
        ...partnerSpouseLetterClearanceSponsored,
        ...partner_parentSpouseVisaRelationship,
      ])
      setSponsoringDependentInfo(partnerSpouseVisaSponsoringDependents)
    }
    if (selectedVisa && selectedVisa?.visaName === 'Working Holiday Visa') {
      setGeneralDocumentMainInfo(workingHolydayVisaGenMain)
      setLetterClearanceMainInfo(workingHolydayVisaLetterClearanceMain)
      setEmpFinancialMainInfo(workingHolydayVisaMainFinanceDoc)
      setRequiredDocument([
        ...workingHolydayVisaGenMain,
        ...workingHolydayVisaLetterClearanceMain,
        ...workingHolydayVisaMainFinanceDoc,
      ])
    }
    if (selectedVisa && selectedVisa?.visaName === 'Student Visa') {
      setGeneralDocumentMainInfo(studentVisaGenMain)
      setLetterClearanceMainInfo(studentVisaLetterClearanceMain)
      setEmpFinancialMainInfo(studentVisaEmpFinanceMain)
      setSpouseGeneralInfo(spouseStudentVisaGenMain)
      setSpouseLetterClearanceInfo([])
      setDependentsInfo(dependentStudentVisaMain)
      setRequiredDocument([
        ...studentVisaGenMain,
        ...studentVisaLetterClearanceMain,
        ...studentVisaEmpFinanceMain,
      ])
    }
    if (selectedVisa && selectedVisa?.visaName === 'Tourist Visa') {
      setGeneralDocumentMainInfo(touristVisaGenMain)
      setLetterClearanceMainInfo(touristVisaLetterClearanceMain)
      setEmpFinancialMainInfo(touristVisaEmpFinanceMain)
      setSpouseGeneralInfo(spouseTouristVisaGenMain)
      setSpouseLetterClearanceInfo(spouseTouristVisaLetterClearance)
      setDependentsInfo(dependentTouristVisaMain)
      setRequiredDocument([
        ...touristVisaGenMain,
        ...touristVisaLetterClearanceMain,
        ...touristVisaEmpFinanceMain,
      ])
    }
    if (selectedVisa && selectedVisa?.visaName === 'Start-Up Visa') {
      setGeneralDocumentMainInfo(investorVisaGenMain)
      setLetterClearanceMainInfo(inVestorVisaClearanceMain)
      setEmpFinancialMainInfo(inVestorVisaFinancialDocMain)
      setSpouseGeneralInfo([])
      setSpouseLetterClearanceInfo([])
      setRelationshipDocInfo(investorVisaRelation)
      setDependentsInfo(dependentInvestorVisaMain)
      setRequiredDocument([
        ...investorVisaGenMain,
        ...inVestorVisaClearanceMain,
        ...inVestorVisaFinancialDocMain,
        ...investorVisaRelation,
      ])
    }
    if (
      selectedVisa &&
      selectedVisa?.visaName === 'Provincial Nominee Program'
    ) {
      setGeneralDocumentMainInfo(provincialNomineeVisaGenMain)
      setLetterClearanceMainInfo(provincialNomineeLetterClearanceMain)
      setEmpFinancialMainInfo(provincialNomineeVisaFinanceDocMain)
      setRequiredDocument([
        ...provincialNomineeVisaGenMain,
        ...provincialNomineeLetterClearanceMain,
        ...provincialNomineeVisaFinanceDocMain,
      ])
    }
    if (selectedVisa && selectedVisa?.visaName === 'Parent Sponsorship Visa') {
      setGeneralDocumentMainInfo(parentsSpouseSponsorGen)
      setLetterClearanceMainInfo(parentsSpouseSponsorLetterClearance)
      setGeneralSponsoringInfo(parentSponsorSpouseVisaSponsoringGen)
      setSponsoringFinancialDoc(parentSponsorSpouseVisaSponsoringFinance)
      setSponsoringLetterClearance(parentSponsorSpouseVisaSponsoringLetter)
      //setSponsoringDependentInfo(parentSponsorSpouseDependentSponsoring)
      setSponsoringRelationship(partner_parentSpouseVisaRelationship)
      setMainApplicantMsg('Main applicant  (spouse/partner)')
      setEmpFinancialMainInfo([])
      setSponsoringShow(true)
      setSponsoringText('Sponsoring Applicant')
      setRequiredDocument([
        ...parentsSpouseSponsorGen,
        ...parentsSpouseSponsorLetterClearance,
        ...parentSponsorSpouseVisaSponsoringGen,
        ...parentSponsorSpouseVisaSponsoringFinance,
        ...parentSponsorSpouseVisaSponsoringLetter,
        ...partner_parentSpouseVisaRelationship,
      ])
    }
    if (selectedVisa && selectedVisa?.visaName === 'Super Visa') {
      setGeneralDocumentMainInfo(superVisaSponsoredGen)
      setSponsoringFinancialDoc(superVisaFinanceDocSponsoredSpouse)
      setLetterClearanceMainInfo(superVisaLetterClearanceSponsored)
      setSpouseGeneralInfo(superVisaGenSponsoredSpouse)
      setSpouseFinancialDocInfo(superVisaFinanceDocSponsoredSpouse)
      setSpouseLetterClearanceInfo(superVisaLetterClearanceSponsoredSpouse)
      setRequiredDocument([
        ...superVisaSponsoredGen,
        ...superVisaFinanceDocSponsoredSpouse,
        ...superVisaLetterClearanceSponsored,
      ])
    }
    if (selectedVisa && selectedVisa?.visaName === 'CRS Submission') {
      setGeneralDocumentMainInfo(pnpExpressEntryVisaGenMain)
      setLetterClearanceMainInfo(pnpExpressEntryVisaClearanceMain)
      setEmpFinancialMainInfo(pnpExpressEntryVisaFinanceDocMain)
      setSpouseGeneralInfo(spousePnpExpressEntryVisaGenMain)
      setSpouseLetterClearanceInfo(spousePnpExpressEntryVisaLetterClearanceMain)
      setSpouseFinancialDocInfo(spousePnpExpressEntryFinanceDocMain)
      setDependentsInfo(dependentPnpExpressEntryVisaVisaMain)
      setRequiredDocument([
        ...pnpExpressEntryVisaGenMain,
        ...pnpExpressEntryVisaClearanceMain,
        ...pnpExpressEntryVisaFinanceDocMain,
      ])
    }
    if (selectedVisa && selectedVisa?.visaName === 'Self Employed Visa') {
      setGeneralDocumentMainInfo(selfEmpVisaGenMain)
      setLetterClearanceMainInfo(selfEmpVisaClearanceMain)
      setEmpFinancialMainInfo(selfEmpVisaFinancialDocMain)
      setRelationshipDocInfo(selfEmpVisaRelation)
      setDependentsInfo(dependentSelfEmpVisaMain)
      setAdaptabilityDocInfo(selEmpVisaAdaptabilityMain)
      setRequiredDocument([
        ...selfEmpVisaGenMain,
        ...selfEmpVisaClearanceMain,
        ...selfEmpVisaFinancialDocMain,
        ...selfEmpVisaRelation,
      ])
    }
    if (
      selectedVisa &&
      selectedVisa?.visaName === 'Employer-Specific Work Permit'
    ) {
      setGeneralDocumentMainInfo(selfEmpWorkPermitVisaSponsoredGen)
      setLetterClearanceMainInfo(selfEmpWorkPermitLetterClearanceSponsored)
      setEmpFinancialMainInfo(selfEmpWorkPermitFinanceDocSponsored)
      setSpouseGeneralInfo(selfEmpWorkPermitGenSponsoredSpouse)
      setSpouseLetterClearanceInfo(selfEmpWorkPermitLetterClearSponsoredSpouse)
      setDependentsInfo(dependentSelfEmpWorkPermitVisaMain)
      setRequiredDocument([
        ...selfEmpWorkPermitVisaSponsoredGen,
        ...selfEmpWorkPermitLetterClearanceSponsored,
        ...selfEmpWorkPermitFinanceDocSponsored,
      ])
    }
  }, [selectedVisa])
  useEffect(() => {
    if (documentInfo?.isLocked) {
      
      setDocumentDis(token ? adminFormDisable: true)
    }
    if (documentInfo?.formsType === 'sponsored' && documentInfo?.isLocked) {
      setDocumentDis(token ? adminFormDisable : true)
    }
    if (documentInfo?.formsType === 'sponsoring' && documentInfo?.isLocked) {
      setSponsoringDis(token ? adminFormDisable : true)
    }
  }, [documentInfo, adminFormDisable])
  const handleShowErrorModal = () => {
    setShowErrorModal(false)
  }
  useEffect(() => {
    if (errors.length > 0) {
      setShowErrorModal(true)
    } else {
      setShowErrorModal(false)
    }
  }, [errors])

  const updateDocumentStatus = async () => {
    try {
      let apiPath = token ? 'support/supportUpdateDocumentStatus' : 'visa/updateDocumentStatus'
      let error = []
      if (!documentInfo && requiredDocument?.length > 0) {
        requiredDocument.forEach((el) => {
          if (el.isRequired) {
            error.push({ name: el.name, message: 'This document is required' })
          }
        })
        if (error?.length) {
          return setErrors(error)
        }
      }
      if (documentInfo && requiredDocument?.length > 0) {
        requiredDocument?.forEach((el) => {
          if (!documentInfo[`${el?.name?.toString()}`] && el?.isRequired) {
            error.push({ name: el.name, message: 'This document is required' })
          }
        })
        if (error?.length > 0) {
          return setErrors(error)
        }
      }
      if (error?.length === 0) {
        const res = await axios.post(
          `${serverPath}/${apiPath}`,
          {
            visaTypeId: selectedVisa?.visaId,
            invoiceId: selectedVisa?.invoiceId,
            formsType: formType,
          },
          {
            headers: {
              authorization: `Bearer ${token?token:localStorage.getItem('token')}`,
            },
          },
        )
        const {code,updatedDoc}=res.data || {}
        
        if (code ===200) {
           //const data = res?.data?.updatedDoc
          setDocumentInfo(updatedDoc)
          // setRenderForm(!renderForm)
          adminFormDisable=true
          setSelectedVisa({
            invoiceId: updatedDoc?.invoiceId,
            visaId: updatedDoc?.visaTypeId,
            caseId: selectedVisa?.caseId,
            visaName: selectedVisa?.visaName,
            isEvaluation: selectedVisa?.isEvaluation,
          })
          toast.success('Document Save successfully', {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
          })
        }
      }
    } catch (err) {
      if (err?.response?.data) {
        toast.error(err.response.data.msg, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
        })
      }
    }
  }
  const changeAccordionselect = (key) => {
    if (key === '1') {
      setSpouseDocuments('open')
    }
    if (key === '2') {
      setChildrenDocuments('open')
    }
  }
  const handleDelete = (value, id) => {
    if (value === 'spouseDocuments') {
      setSpouseDocuments('plus')
    }
    if (value === 'childrenDocuments') {
      setChildrenDocuments('plus')
    }
    document.getElementById(id).click()
  }
  const handleClose = (value, id) => {
    document.getElementById(id).click()
  }
  //custom accordian toggle
  function CustomToggle({ children, eventKey }) {
    const decoratedOnClick = useAccordionButton(eventKey, () => {
      if (customAccordBtn === eventKey) {
        setCustomAccordBtn('')
      } else setCustomAccordBtn(eventKey)
    })
    return (
      <Button id={`doc-${eventKey}`} onClick={decoratedOnClick}>
        {children}
      </Button>
    )
  }
  return (
    <div className="personal-info document-info">
      <Accordion
        defaultActiveKey="0"
        onSelect={(key) => changeAccordionselect(key)}
      >
        <div className={`accordion-list ${'change-accordion-icon'}`}>
          <Accordion.Item eventKey="0">
            <Accordion.Header>
              {mainApplicantMsg ? mainApplicantMsg : 'Main Applicant'}
            </Accordion.Header>
            <Accordion.Body>
              {generalDocumentMainInfo && generalDocumentMainInfo?.length ? (
                <Accordion className="inner-accordion">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>General</Accordion.Header>

                    <Accordion.Body>
                      <GeneralInfo
                        documentDis={documentDis}
                        documentInfo={documentInfo}
                        setDocumentInfo={setDocumentInfo}
                        updFormType={updFormType}
                        generalDocumentMainInfo={generalDocumentMainInfo}
                        errors={errors}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ) : (
                ''
              )}

              {letterClearanceMainInfo && letterClearanceMainInfo?.length ? (
                <Accordion className="inner-accordion" defaultActiveKey="2">
                  <Accordion.Item eventKey="">
                    <Accordion.Header>Letters & Clearance</Accordion.Header>

                    <Accordion.Body>
                      <LetterClearanceInfo
                        documentInfo={documentInfo}
                        setDocumentInfo={setDocumentInfo}
                        documentDis={documentDis}
                        updFormType={updFormType}
                        letterClearanceMainInfo={letterClearanceMainInfo}
                        errors={errors}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ) : (
                ''
              )}

              {empFinancialMainInfo && empFinancialMainInfo?.length > 0 ? (
                <Accordion className="inner-accordion" defaultActiveKey="3">
                  <Accordion.Item eventKey="">
                    <Accordion.Header>
                      Employment & Financial Documents
                    </Accordion.Header>

                    <Accordion.Body>
                      <EmployeementFinancialDoc
                        documentInfo={documentInfo}
                        setDocumentInfo={setDocumentInfo}
                        documentDis={documentDis}
                        updFormType={updFormType}
                        empFinancialMainInfo={empFinancialMainInfo}
                        errors={errors}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ) : (
                ''
              )}
              {relationShipDocInfo && relationShipDocInfo?.length > 0 ? (
                <Accordion className="inner-accordion" defaultActiveKey="4">
                  <Accordion.Item eventKey="">
                    <Accordion.Header>Relationship Evidence</Accordion.Header>

                    <Accordion.Body>
                      <RelationshipEvidence
                        documentInfo={documentInfo}
                        setDocumentInfo={setDocumentInfo}
                        documentDis={documentDis}
                        updFormType={updFormType}
                        relationShipDocInfo={relationShipDocInfo}
                        errors={errors}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              ) : (
                ''
              )}

              <div className="disclaimer-text">
                <p>
                  <span>Disclaimer:</span> This checklist contains the basic,
                  mandatory documentation based on the type of visa you're
                  applying for.Nevertheless, it's common for the Canadian
                  immigration authorities to request supplementary information /
                  documentation during advanced stages of the application
                  process. You will be informed about these requirements if
                  needed, as soon as we are notified by the immigration
                  authorities.
                </p>
              </div>
            </Accordion.Body>
          </Accordion.Item>
        </div>
        {(selectedVisa && selectedVisa?.visaName === 'Tourist Visa') ||
        (selectedVisa && selectedVisa?.visaName === 'Student Visa') ||
        (selectedVisa && selectedVisa?.visaName === 'Super Visa') ? (
          <div
            className={`accordion-list ${
              spouseDocuments === 'open' ? 'change-accordion-icon' : ''
            }`}
          >
            <Card>
              <Card.Header
                className={customAccordBtn === '1' ? 'accord-open' : ''}
                onClick={() =>
                  spouseDocuments === 'open' || documentDis
                    ? handleClose('spouseDocuments', 'doc-1')
                    : ''
                }
              >
                <div className="accordian-title">
                  Add Spouse Documents
                  {spouseDocuments === 'open' && !documentDis && (
                    <div className="delete-btn">
                      <Button
                        onClick={() => handleDelete('spouseDocuments', 'doc-1')}
                      >
                        Delete <i class="las la-minus"></i>
                      </Button>
                    </div>
                  )}
                </div>
                <CustomToggle eventKey="1">
                  {spouseDocuments === 'plus' ? (
                    <img src={addIcon} alt="Add Icon" />
                  ) : spouseDocuments === 'open' ? (
                    <img
                      className="arrow-icon"
                      src={customAccordBtn !== '1' ? closeArrow : activeArrow}
                      alt="Icon"
                    />
                  ) : (
                    ''
                  )}
                </CustomToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  {spouseGeneralInfo && spouseGeneralInfo?.length > 0 ? (
                    <Accordion className="inner-accordion" defaultActiveKey="2">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>General</Accordion.Header>

                        <Accordion.Body>
                          <GeneralSpouseInfo
                            documentInfo={documentInfo}
                            setDocumentInfo={setDocumentInfo}
                            documentDis={documentDis}
                            updFormType={updFormType}
                            spouseGeneralInfo={spouseGeneralInfo}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ) : (
                    ''
                  )}
                  {spouseLetterClearanceInfo &&
                  spouseLetterClearanceInfo?.length > 0 ? (
                    <Accordion className="inner-accordion" defaultActiveKey="3">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          Letters & Clearances
                        </Accordion.Header>

                        <Accordion.Body>
                          <LetterClearanceSpouseInfo
                            documentInfo={documentInfo}
                            setDocumentInfo={setDocumentInfo}
                            documentDis={documentDis}
                            updFormType={updFormType}
                            spouseLetterClearanceInfo={
                              spouseLetterClearanceInfo
                            }
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ) : (
                    ''
                  )}
                  {spouseFinacialDocInfo &&
                  spouseFinacialDocInfo?.length > 0 ? (
                    <Accordion className="inner-accordion" defaultActiveKey="3">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          Employment & Financial Documents
                        </Accordion.Header>

                        <Accordion.Body>
                          <FinancialDocSpouseInfo
                            documentInfo={documentInfo}
                            setDocumentInfo={setDocumentInfo}
                            documentDis={documentDis}
                            updFormType={updFormType}
                            spouseFinacialDocInfo={spouseFinacialDocInfo}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ) : (
                    ''
                  )}

                  <div className="disclaimer-text">
                    <p>
                      <span>Disclaimer:</span> This checklist contains the
                      basic, mandatory documentation based on the type of visa
                      you're applying for.Nevertheless, it's common for the
                      Canadian immigration authorities to request supplementary
                      information / documentation during advanced stages of the
                      application process. You will be informed about these
                      requirements if needed, as soon as we are notified by the
                      immigration authorities.
                    </p>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </div>
        ) : (
          ''
        )}
        {dependentsInfo && dependentsInfo?.length > 0 ? (
          <div
            className={`accordion-list ${
              childrenDocuments === 'open' ? 'change-accordion-icon' : ''
            }`}
          >
            <Card>
              <Card.Header
                className={customAccordBtn === '2' ? 'accord-open' : ''}
                onClick={() =>
                  childrenDocuments === 'open' || documentDis
                    ? handleClose('childrenDocuments', 'doc-2')
                    : ''
                }
              >
                <div className="accordian-title">
                  Add Children Documents
                  {childrenDocuments === 'open' && !documentDis && (
                    <div className="delete-btn">
                      <Button
                        onClick={() =>
                          handleDelete('childrenDocuments', 'doc-2')
                        }
                      >
                        Delete <i class="las la-minus"></i>
                      </Button>
                    </div>
                  )}
                </div>
                <CustomToggle eventKey="2">
                  {childrenDocuments === 'plus' ? (
                    <img src={addIcon} alt="Add Icon" />
                  ) : childrenDocuments === 'open' ? (
                    <img
                      className="arrow-icon"
                      src={customAccordBtn !== '2' ? closeArrow : activeArrow}
                      alt="Icon"
                    />
                  ) : (
                    ''
                  )}
                </CustomToggle>
              </Card.Header>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <Accordion className="inner-accordion" defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Dependents</Accordion.Header>
                      <Accordion.Body>
                        <DependentInfo
                          documentInfo={documentInfo}
                          setDocumentInfo={setDocumentInfo}
                          documentDis={documentDis}
                          updFormType={updFormType}
                          dependentsInfo={dependentsInfo}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  {adaptabilityDocInfo && adaptabilityDocInfo?.length > 0 ? (
                    <Accordion className="inner-accordion" defaultActiveKey="5">
                      <Accordion.Item eventKey="">
                        <Accordion.Header>
                          Adaptability (if Applicable)
                        </Accordion.Header>

                        <Accordion.Body>
                          <AdaptabilityDocumentInfo
                            documentInfo={documentInfo}
                            setDocumentInfo={setDocumentInfo}
                            documentDis={documentDis}
                            updFormType={updFormType}
                            adaptabilityDocInfo={adaptabilityDocInfo}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  ) : (
                    ''
                  )}
                  <div className="disclaimer-text">
                    <p>
                      <span>Disclaimer:</span> This checklist contains the
                      basic, mandatory documentation based on the type of visa
                      you're applying for.Nevertheless, it's common for the
                      Canadian immigration authorities to request supplementary
                      information / documentation during advanced stages of the
                      application process. You will be informed about these
                      requirements if needed, as soon as we are notified by the
                      immigration authorities.
                    </p>
                  </div>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </div>
        ) : (
          ''
        )}
      </Accordion>
      {sponsoringShow ? (
        <Accordion
          defaultActiveKey="0"
          onSelect={(key) => changeAccordionselect(key)}
        >
          <div className={`accordion-list ${'change-accordion-icon'}`}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>
                {sponsoringText ? sponsoringText : 'Sponsoring Applicant'}
              </Accordion.Header>
              <Accordion.Body>
                <Accordion className="inner-accordion" defaultActiveKey="0">
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>General</Accordion.Header>

                    <Accordion.Body>
                      <SponsoringGeneralInfo
                        documentDis={documentDis}
                        documentInfo={documentInfo}
                        setDocumentInfo={setDocumentInfo}
                        updFormType={updFormType}
                        adminFormDisable={adminFormDisable}
                        generalSponsoringInfo={generalSponsoringInfo}
                        errors={errors}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
                {sponsoringLetterClearance &&
                sponsoringLetterClearance?.length > 0 ? (
                  <Accordion className="inner-accordion" defaultActiveKey="2">
                    <Accordion.Item eventKey="">
                      <Accordion.Header>Letters & Clearance</Accordion.Header>

                      <Accordion.Body>
                        <SponsoringLetterClearanceInfo
                          documentInfo={documentInfo}
                          setDocumentInfo={setDocumentInfo}
                          documentDis={documentDis}
                          updFormType={updFormType}
                          sponsoringLetterClearance={sponsoringLetterClearance}
                          errors={errors}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ) : (
                  ''
                )}

                <Accordion className="inner-accordion" defaultActiveKey="3">
                  <Accordion.Item eventKey="">
                    <Accordion.Header>
                      Employment & Financial Documents
                    </Accordion.Header>

                    <Accordion.Body>
                      <SponsoringEmpFinancialDocument
                        documentInfo={documentInfo}
                        setDocumentInfo={setDocumentInfo}
                        documentDis={documentDis}
                        updFormType={updFormType}
                        sponsoringFinancialDoc={sponsoringFinancialDoc}
                        errors={errors}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
               

                {sponsoringRelationship &&
                sponsoringRelationship?.length > 0 ? (
                  <Accordion className="inner-accordion" defaultActiveKey="5">
                    <Accordion.Item eventKey="">
                      <Accordion.Header>Relationship Evidence</Accordion.Header>

                      <Accordion.Body>
                        <SponsoringRelationshipEvidence
                          documentInfo={documentInfo}
                          setDocumentInfo={setDocumentInfo}
                          documentDis={documentDis}
                          updFormType={updFormType}
                          sponsoringRelationship={sponsoringRelationship}
                          errors={errors}
                        />
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                ) : (
                  ''
                )}

                <div className="disclaimer-text">
                  <p>
                    <span>Disclaimer:</span> This checklist contains the basic,
                    mandatory documentation based on the type of visa you're
                    applying for.Nevertheless, it's common for the Canadian
                    immigration authorities to request supplementary information
                    / documentation during advanced stages of the application
                    process. You will be informed about these requirements if
                    needed, as soon as we are notified by the immigration
                    authorities.
                  </p>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </div>
          <div
            className={`accordion-list ${
              spouseDocuments === 'open' ? 'change-accordion-icon' : ''
            }`}
          ></div>

          {sponsoringDependentInfo && sponsoringDependentInfo?.length > 0 ? (
            <div
              className={`accordion-list ${
                childrenDocuments === 'open' ? 'change-accordion-icon' : ''
              }`}
            >
              <Card>
                <Card.Header
                  className={customAccordBtn === '1' ? 'accord-open' : ''}
                  onClick={() =>
                    childrenDocuments === 'open' || documentDis
                      ? handleClose('childrenDocuments', 'doc-2')
                      : ''
                  }
                >
                  <div className="accordian-title">
                    Add Children Documents
                    {childrenDocuments === 'open' && !documentDis && (
                      <div className="delete-btn">
                        <Button
                          onClick={() =>
                            handleDelete('childrenDocuments', 'doc-2')
                          }
                        >
                          Delete <i class="las la-minus"></i>
                        </Button>
                      </div>
                    )}
                  </div>
                  <CustomToggle eventKey="2">
                    {childrenDocuments === 'plus' ? (
                      <img src={addIcon} alt="Add Icon" />
                    ) : childrenDocuments === 'open' ? (
                      <img
                        className="arrow-icon"
                        src={customAccordBtn !== '1' ? closeArrow : activeArrow}
                        alt="Icon"
                      />
                    ) : (
                      ''
                    )}
                  </CustomToggle>
                </Card.Header>
                <Accordion.Collapse eventKey="2">
                  <Card.Body>
                    <Accordion className="inner-accordion" defaultActiveKey="2">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>Dependents</Accordion.Header>
                        <Accordion.Body>
                          <SponsoringDependentInfo
                            documentInfo={documentInfo}
                            setDocumentInfo={setDocumentInfo}
                            documentDis={documentDis}
                            updFormType={updFormType}
                            sponsoringDependentInfo={sponsoringDependentInfo}
                          />
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>

                    <div className="disclaimer-text">
                      <p>
                        <span>Disclaimer:</span> This checklist contains the
                        basic, mandatory documentation based on the type of visa
                        you're applying for.Nevertheless, it's common for the
                        Canadian immigration authorities to request
                        supplementary information / documentation during
                        advanced stages of the application process. You will be
                        informed about these requirements if needed, as soon as
                        we are notified by the immigration authorities.
                      </p>
                    </div>
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </div>
          ) : (
            ''
          )}
        </Accordion>
      ) : (
        ''
      )}

      {documentPopup && (
        <>
          <div className="bg-shadow" />
          <DocumentPopup setDocumentPopup={setDocumentPopup} />
        </>
      )}
      <div className="visa-btn">
        {sponsoringShow ? (
          !sponsoringDis ? (
            <>
              <Button
                type="submit"
                disabled={sponsoringDis ? sponsoringDis : false}
                onClick={updateDocumentStatus}
                className={`${sponsoringDis ? 'success-btn' : ''}`}
              >
                Save {sponsoringDis && <img src={approved} alt="approved" />}
              </Button>
              <div className="visa-desc-text">
                <p>
                  Once your form is completed, you will be able to make new
                  changes by contacting our Support team.
                </p>
                <p>When you're ready, click "Submit"!</p>
              </div>
            </>
          ) : (
            <div className="for-new-changes">
              <p>
                <span>Important! </span> Please contact our Support team if you
                would like to make new changes .
              </p>
            </div>
          )
        ) : !documentDis ? (
          <>
            <Button
              type="submit"
              disabled={documentDis ? documentDis : false}
              onClick={updateDocumentStatus}
              className={`${documentDis ? 'success-btn' : ''}`}
            >
              Save {documentDis && <img src={approved} alt="approved" />}
            </Button>
            <div className="visa-desc-text">
              <p>
                Once your form is completed, you will be able to make new
                changes by contacting our Support team.
              </p>
              <p>When you're ready, click "Submit"!</p>
            </div>
          </>
        ) : (
          <div className="for-new-changes">
            <p>
              <span>Important! </span> Please contact our Support team if you
              would like to make new changes .
            </p>
          </div>
        )}
      </div>
      <ErrorModal show={showErrorModal} handleClose={handleShowErrorModal} />
    </div>
  )
}
export default Documents
