export const parentSponsorSpouseVisaSponsoringLetter = [
  {
    name: 'sponsoringNoticeOfAssessment',
    label: 'Notice of Assessment (this year)',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'sponsoringCohabitationLetter1',
    label: 'Cohabitation – Recommendation Letter from Family and Friends',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'sponsoringCohabitationLetter2',
    label: 'Cohabitation – Recommendation Letter from Family and Friends 2',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'sponsoringLetterBySponsor',
    label: 'Letter by Sponsor (see Guidelines)',
    secondLabel: '',
    isRequired: true,
  },
]
export const partnerSpouseVisaLetterClearanceSponsoring = [
  {
    name: 'sponsoringNoticeOfAssessment',
    label: 'Notice of Assessment (this year)',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'sponsoringCohabitationLetter1',
    label: 'Cohabitation – Recommendation Letter from Family and Friends',
    secondLabel: '(if applicable)',
    isRequired: false,
  },
  {
    name: 'sponsoringCohabitationLetter2',
    label: 'Cohabitation – Recommendation Letter from Family and Friends 2',
    secondLabel: '',
    isRequired: true,
  },
  {
    name: 'sponsoringLetterBySponsor',
    label: 'Letter by Sponsor (see Guidelines)',
    secondLabel: '',
    isRequired: true,
  },
]
